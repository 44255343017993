import React, { useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import { BaseUrl } from '../BaseUrl';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (oldPassword === newPassword) {
      setErrorMessage('Old password and new password should not be the same.');
    } else if (newPassword !== confirmPassword) {
      setErrorMessage('New password and confirm password do not match.');
    } else {
      try {
        const userId = localStorage.getItem('userId');
        if (!userId) {
          throw new Error('User ID not found in localStorage');
        }

        const response = await axios.post(`${BaseUrl}/setnewpassword`, {
          oldPassword,
          newPassword,
          userId
        });

        setSuccessMessage(response.data); // Set success message
        setErrorMessage(''); // Clear error message
        // Reset form fields
        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        navigate("/logout");
      } catch (error) {
        console.error('Error resetting password:', error.response.data);
        setErrorMessage(error.response.data); // Set error message
        setSuccessMessage(''); // Clear success message
      }
    }
  };

  return (
    <>
      <Header />
      <main className="flex-shrink-0" style={{ paddingBottom: '10rem' }}>
        <Container className="mt-5">
          <Row>
            <Col lg={3}></Col>
            <Col lg={6} data-aos="fade-up" data-aos-delay="200">
              <div className="text-center">
                <h4 className="font-weight-bold heading mb-2">
                  Update Your Password?
                </h4>
                <p className="mb-5">
                "A strong password is the first line of defense in protecting your digital identity. Regularly updating it is the second." - NoBuddy
                </p>
              </div>
              <div className="mdlcnt">
                {errorMessage && (
                  <Alert variant="danger" className="mb-3">
                    {errorMessage}
                  </Alert>
                )}
                {successMessage && (
                  <Alert variant="success" className="mb-3">
                    {successMessage}
                  </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                  <Row className="mt-2">
                    <Col xs={12} className="mb-3">
                      <h6 className="hd">Old Password</h6>
                      <Form.Control
                        type="password"
                        placeholder="Enter Your Old Password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} className="mb-3">
                      <h6 className="hd">New Password</h6>
                      <Form.Control
                        type="password"
                        placeholder="Enter New Password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                      />
                    </Col>
                    <Col xs={12} className="mb-3">
                      <h6 className="hd">Confirm Password</h6>
                      <Form.Control
                        type="password"
                        placeholder="Enter Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </Col>
                    <Col xs={12}>
                      <Button type="submit" variant="primary" className="col-12" style={{marginBottom:'15px'}}>
                        Continue
                      </Button>
                    </Col>
                    <Col xs={12} className="mt-3 text-center">
                      {/* <small>Back to <strong>Login</strong></small> */}
                    </Col>
                  </Row>
                </Form>
              </div>
            </Col>
            <Col lg={3}></Col>
          </Row>
        </Container>
      </main>
      <Footer />
    </>
  );
};

export default ResetPassword;
