import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserDetails } from "../../redux/actions/userActions";
import { BaseUrl } from "../BaseUrl";
import {
  isValidMobile,
  isValidPrice,
  isValidOccupation,
} from "../../utils/validations";
import Footer from "../Footer";
import Header from "../Header";
import { Country, State, City } from "country-state-city";
import Select from "react-select";
import occupations from "./occupations";

// const occupationOptions = occupations.map((occupation) => ({
//   value: occupation,
//   label: occupation,
// }));

const Signup = ({ setShowSignupModal }) => {
  const [signupInfo, setSignupInfo] = useState({
    mobile: "",
    name: "",
    email: "",
    password: "",
    country: "",
    state: "",
    city: "",
    occupation: "",
    // budget: "",
    gender: "",
    mobile1: "",
    referral: "",
    usertype: [],
  });

  const [ipAddress, setIpAddress] = useState("");
  const [logoUrl, setLogoUrl] = useState("");
  const [logoError, setLogoError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedProfession, setSelectedProfession] = useState(null);

  const fetchLogo = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/getlogodetails`);
      if (response.data.success) {
        setLogoUrl(response.data.results.logo);
      }
    } catch (error) {
      console.error("Error fetching logo:", error);
    }
  };

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
    fetchLogo();
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "usertype") {
      const usertype = checked
        ? [...signupInfo.usertype, value]
        : signupInfo.usertype.filter((type) => type !== value);

      setSignupInfo({
        ...signupInfo,
        usertype,
        ipAddress,
      });
    } else {
      setSignupInfo({
        ...signupInfo,
        [name]: value,
        ipAddress,
      });
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(email);
  };

  const handleProfessionChange = (event) => {
    setSignupInfo({
      ...signupInfo,
      occupation: event.target.value,
    });
  };

  const isValidOccupation = (occupation) => occupation !== "";

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    const country = Country.getAllCountries().find(
      (c) => c.isoCode === countryCode
    );
    setSelectedCountry(country);
    setSelectedState(null); // Reset state selection
    setSelectedCity(null); // Reset city selection

    setSignupInfo({
      ...signupInfo,
      country: country.name,
      state: "",
      city: "",
    });
  };

  const handleStateChange = (event) => {
    const stateCode = event.target.value;
    const state = State.getStatesOfCountry(selectedCountry.isoCode).find(
      (s) => s.isoCode === stateCode
    );
    setSelectedState(state);
    setSelectedCity(null); // Reset city selection

    setSignupInfo({
      ...signupInfo,
      state: state.name,
      city: "",
    });
  };

  const handleCityChange = (event) => {
    const cityName = event.target.value;
    const city = City.getCitiesOfState(
      selectedCountry.isoCode,
      selectedState.isoCode
    ).find((c) => c.name === cityName);
    setSelectedCity(city);

    setSignupInfo({
      ...signupInfo,
      city: city.name,
    });
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    // console.log(signupInfo);
    try {
      if (!isValidMobile(signupInfo.mobile)) {
        alert("Please enter a valid 10-digit mobile number");
        return;
      }
      if (!isValidMobile(signupInfo.mobile1)) {
        alert("Please enter a valid 10-digit mobile number");
        return;
      }

      if (!isValidEmail(signupInfo.email)) {
        alert("Please enter a valid email address");
        return;
      }

      // if (!isValidPrice(signupInfo.budget)) {
      //   alert("Please enter a valid budget greater than 5000");
      //   return;
      // }

      if (!isValidOccupation(signupInfo.occupation)) {
        alert("Please select an occupation");
        return;
      }

      const response = await axios.post(`${BaseUrl}/usersignup`, signupInfo);
      alert(response.data.message);
      if (
        response.status === 201 &&
        response.data.message ===
          "User registered successfully and welcome email sent"
      ) {
        setSignupInfo({
          mobile: "",
          name: "",
          email: "",
          password: "",
          country: "",
          state: "",
          city: "",
          occupation: "",
          budget: "",
          gender: "",
          referral: "",
          mobile1: "",
          usertype: [],
        });

        navigate("/");
      }
    } catch (error) {
      console.error("Error during signup:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Header />

      <main className="section">
        <div className="container mt120">
          <div className="row">
            <div className="col-lg-12">
              <div className="mdlcnt">
                <div className="row">
                  <div className="col-lg-4 mb-5 mb-lg-0">
                    <div className="text-center mt-4">
                      {logoUrl && !logoError ? (
                        <img
                          src={logoUrl}
                          alt="Property Icon"
                          onLoad={() => console.log("Logo loaded successfully")}
                          onError={(e) => {
                            console.error("Error loading logo:", e);
                            setLogoError(true);
                          }}
                          style={{
                            maxWidth: "40%",
                            maxHeight: "50%",
                            objectFit: "contain",
                          }}
                        />
                      ) : (
                        <div>HSAS</div>
                      )}
                    </div>
                    <div className="mt-4">
                      <h6 className="hd">Sign up For Users</h6>
                      <ul className="checklist">
                        <li title="Zero Brokerage.">Zero Brokerage.</li>
                        <li title="Thousands of new listings daily.">
                          Thousands of new listings daily.
                        </li>
                        <li title="100 Cr+ Brokerage saved monthly.">
                          100 Cr+ Brokerage saved monthly.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="mdlcnt valign">
                      <div className="row mb-1">
                        <div className="" style={{ textAlign: "center" }}>
                          <h2 className="font-weight-bold heading mb-2">
                            Signup
                          </h2>
                          <p className="mb-3">Create An Account</p>
                        </div>
                      </div>
                      <form onSubmit={handleSignup}>
                        <div className="row mt-2">
                          <div className="col-6 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="mobile"
                              placeholder="Enter Your Mobile Number"
                              onChange={handleChange}
                              value={signupInfo.mobile}
                            />
                          </div>
                          <div className="col-6 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Enter Name"
                              onChange={handleChange}
                              value={signupInfo.name}
                            />
                          </div>
                          <div className="col-6 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="email"
                              placeholder="Enter Your Email Like xyz@gmail.com"
                              onChange={handleChange}
                              value={signupInfo.email}
                            />
                          </div>
                          <div className="col-6 mb-3">
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              placeholder="Enter Your Password"
                              onChange={handleChange}
                              value={signupInfo.password}
                            />
                          </div>

                          <div className="col-4 mb-3">
                            <select
                              onChange={handleCountryChange}
                              value={
                                selectedCountry ? selectedCountry.isoCode : ""
                              }
                              // value={signupInfo.country}
                              className="form-select form-control"
                              name="country"
                            >
                              <option value="">Select Country</option>
                              {Country.getAllCountries().map((country) => (
                                <option
                                  key={country.isoCode}
                                  value={country.isoCode}
                                >
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          {selectedCountry && (
                            <>
                              <div className="col-4 mb-3">
                                <select
                                  onChange={handleStateChange}
                                  // value={signupInfo.state}
                                  className="form-select form-control"
                                  name="state"
                                >
                                  <option value="">Select State</option>
                                  {State.getStatesOfCountry(
                                    selectedCountry.isoCode
                                  ).map((state) => (
                                    <option
                                      key={state.isoCode}
                                      value={state.isoCode}
                                    >
                                      {state.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </>
                          )}

                          {selectedState && (
                            <>
                              <div className="col-4 mb-3">
                                <select
                                  onChange={handleCityChange}
                                  // value={signupInfo.city}
                                  className="form-select form-control"
                                  name="city"
                                >
                                  <option value="">Select City</option>
                                  {City.getCitiesOfState(
                                    selectedCountry.isoCode,
                                    selectedState.isoCode
                                  ).map((city) => (
                                    <option key={city.name} value={city.name}>
                                      {city.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </>
                          )}
                          {/* <div className="col-4 mb-3" style={{backgroundColor:'black', color:'#000'}}>
                            <Select
                            // style={{backgroundColor:'black'}}
                             className="custom-select"
                              options={occupationOptions} 
                              onChange={setSelectedProfession}
                              value={selectedProfession}
                              placeholder="Select Occupation"
                              isClearable
                            />
                          </div> */}

                          <div className="col-4 mb-3">
                            {/* <label>Profession</label> */}
                            <select
                              className="form-control form-select "
                              id="occupation"
                              name="occupation"
                              // placeholder={userDetails?.occupation}
                              onChange={handleProfessionChange}
                              value={signupInfo.occupation}
                            >
                              <option value="" disabled>
                                Profession
                              </option>
                              {occupations.map((occupation, index) => (
                                <option key={index} value={occupation}>
                                  {occupation}
                                </option>
                              ))}
                            </select>
                          </div>
                          {/* <div className="col-4 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="budget"
                              placeholder="Enter Your Budget"
                              onChange={handleChange}
                              value={signupInfo.budget}
                            />
                          </div> */}
                          <div className="col-4 mb-3">
                            <select
                              className="form-select form-control"
                              name="gender"
                              onChange={handleChange}
                              value={signupInfo.gender}
                            >
                              <option value="">Select Gender</option>
                              <option value="Female">Female</option>
                              <option value="Male">Male</option>
                              <option value="Other">Other</option>
                            </select>
                          </div>
                          <div className="col-6 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="mobile1"
                              placeholder="Enter Alternate Mobile number"
                              onChange={handleChange}
                              value={signupInfo.mobile1}
                            />
                          </div>
                          <div className="col-6 mb-3">
                            <input
                              type="text"
                              className="form-control"
                              name="referral"
                              placeholder="Enter Referral Code"
                              onChange={handleChange}
                              value={signupInfo.referral}
                            />
                          </div>
                          <div className="col-12 mb-3">
                            {/* <h6 className="hd">Are You?</h6> */}
                            <h6 className="hd">
                              Are You Posting Property as :{" "}
                            </h6>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="usertype"
                                value="1"
                                onChange={handleChange}
                                checked={signupInfo.usertype.includes("1")}
                              />
                              <label className="form-check-label">Agent</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="usertype"
                                value="2"
                                onChange={handleChange}
                                checked={signupInfo.usertype.includes("2")}
                              />
                              <label className="form-check-label">Owner</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="usertype"
                                value="3"
                                onChange={handleChange}
                                checked={signupInfo.usertype.includes("3")}
                              />
                              <label className="form-check-label">
                                Builders or Developers
                              </label>
                            </div>
                            {/* <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="usertype"
                                value="1"
                                onChange={handleChange}
                                checked={signupInfo.usertype.includes("1")}
                              />
                              <label className="form-check-label">Seller</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="usertype"
                                value="2"
                                onChange={handleChange}
                                checked={signupInfo.usertype.includes("2")}
                              />
                              <label className="form-check-label">Buyer</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="usertype"
                                value="3"
                                onChange={handleChange}
                                checked={signupInfo.usertype.includes("3")}
                              />
                              <label className="form-check-label">
                                Owner To Rent
                              </label>
                            </div> */}
                            {/* <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="usertype"
                                value="4"
                                onChange={handleChange}
                                checked={signupInfo.usertype.includes("4")}
                              />
                              <label className="form-check-label">
                                Owner To Lease
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="usertype"
                                value="5"
                                onChange={handleChange}
                                checked={signupInfo.usertype.includes("5")}
                              />
                              <label className="form-check-label">
                                Looking For Rental
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="usertype"
                                value="6"
                                onChange={handleChange}
                                checked={signupInfo.usertype.includes("6")}
                              />
                              <label className="form-check-label">
                                Looking For Lease
                              </label>
                            </div> */}
                            {/* <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="usertype"
                                value="7"
                                onChange={handleChange}
                                checked={signupInfo.usertype.includes("7")}
                              />
                              <label className="form-check-label">Others</label>
                            </div> */}
                          </div>
                          <div className="">
                            <input
                              type="submit"
                              value="Sign Up"
                              className="btn btn-primary col-4"
                              disabled={isSubmitting}
                            />
                          </div>
                          <div className="mt-3">
                            <small>
                              By continuing, you agree to our{" "}
                              <Link to="/terms-condition">
                                {" "}
                                <strong>Terms & Conditions</strong>
                              </Link>
                            </small>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default Signup;
