import React, { useRef, useState, useEffect } from "react";
import Footer from "../Footer";
import Header from "../Header";
import Agents from "../Agents";
import RealEstateAgent from "../RealEstateAgent";
import FindHome from "../FindHome";
import Carousel from "../Carousel";
import Services from "../Services";
import Testimonials from "../Testimonials";
import { BaseUrl } from "../BaseUrl";

function ViewAllProperties() {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    fetch(`${BaseUrl}/viewallproperties`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data.properties)) {
          setProperties(data.properties);
        } else {
          console.error("API response is not an array:", data);
        }
      })
      .catch((error) => console.error("Error fetching properties:", error));
  }, []);

  return (
    <div>
      <Header />
      <Carousel />
      <div className="section">
        <div className="container">
          <div className="row mb-5 align-items-center">
            <div className="col-lg-6">
              <h2 className="font-weight-bold text-primary heading">
                Popular Properties
              </h2>
            </div>
            <div className="col-lg-6 text-lg-end">
              <p>
                <a
                  href="#"
                  target="_blank"
                  className="btn btn-primary text-white py-3 px-4"
                >
                  View all properties
                </a>
              </p>
            </div>
          </div>
          <div className="row">
            {properties.length > 0 ? (
              properties.map((property) => (
                <div key={property.id} className="col-lg-4 col-md-6 mb-4">
                  <div className="property-item">
                    <a href="property-single.html" className="img">
                      <img
                        src={property.bannerimage_name}
                        alt="Image"
                        className="img-fluid"
                      />
                    </a>
                    <div className="property-content">
                      <div className="price mb-2">
                        <span>${property.price}</span>
                      </div>
                      <div>
                        <span className="d-block mb-2">
                          {property.address}
                        </span>
                        <span className="city d-block mb-3">
                          {property.city}, {property.state}, {property.country}
                        </span>
                        <div className="specs d-flex mb-4">
                          <span className="d-block d-flex align-items-center me-3">
                            <span className="icon-bed me-2" />
                            <span className="caption">beds</span>
                          </span>
                          <span className="d-block d-flex align-items-center">
                            <span className="icon-bath me-2" />
                            <span className="caption">baths</span>
                          </span>
                        </div>
                        <a
                          href="property-single.html"
                          className="btn btn-primary py-2 px-3"
                        >
                          See details
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="col-12">No properties available</p>
            )}
          </div>
        </div>
      </div>
      <Services />
      <Testimonials />
      <FindHome />
      <RealEstateAgent />
      <Agents />
      <Footer />
    </div>
  );
}

export default ViewAllProperties;
