import React, { useState } from "react";
import { Country, State, City } from "country-state-city";

const CountryStateCitySelector = () => {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const handleCountryChange = (event) => {
    const countryCode = event.target.value;
    const country = Country.getAllCountries().find(
      (c) => c.isoCode === countryCode
    );
    setSelectedCountry(country);
    setSelectedState(null); // Reset state selection
    setSelectedCity(null); // Reset city selection
  };

  const handleStateChange = (event) => {
    const stateCode = event.target.value;
    const state = State.getStatesOfCountry(selectedCountry.isoCode).find(
      (s) => s.isoCode === stateCode
    );
    setSelectedState(state);
    setSelectedCity(null); // Reset city selection
  };

  const handleCityChange = (event) => {
    const cityName = event.target.value;
    const city = City.getCitiesOfState(
      selectedCountry.isoCode,
      selectedState.isoCode
    ).find((c) => c.name === cityName);
    setSelectedCity(city);
  };

  // console.log(Country.getAllCountries());
  // console.log(State.getStatesOfCountry("IN")); 
  // console.log(City.getCitiesOfState("IN", "KA")); 

  return (
    <div>
      <label>Country:</label>
      <select onChange={handleCountryChange}>
        <option value="">Select Country</option>
        {Country.getAllCountries().map((country) => (
          <option key={country.isoCode} value={country.isoCode}>
            {country.name}
          </option>
        ))}
      </select>

      {selectedCountry && (
        <>
          <label>State:</label>
          <select onChange={handleStateChange}>
            <option value="">Select State</option>
            {State.getStatesOfCountry(selectedCountry.isoCode).map((state) => (
              <option key={state.isoCode} value={state.isoCode}>
                {state.name}
              </option>
            ))}
          </select>
        </>
      )}

      {selectedState && (
        <>
          <label>City:</label>
          <select onChange={handleCityChange}>
            <option value="">Select City</option>
            {City.getCitiesOfState(
              selectedCountry.isoCode,
              selectedState.isoCode
            ).map((city) => (
              <option key={city.name} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
        </>
      )}
    </div>
  );
};

export default CountryStateCitySelector;
