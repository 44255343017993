import React from "react";
import { Link } from "react-router-dom";

function RealEstateAgent() {
  return (
    <div>
      <div className="">
        <div
          className="row justify-content-center footer-cta"
          // data-aos="fade-up"
        >
          <div className="col-lg-7 mx-auto text-center">
            <h2 className="mb-4 text-white">
              Discover Comprehensive Real Estate Solutions
            </h2>
            <p>
              <Link
                to="/legalservices"
                // target="_blank"
                className="btn btn-primary text-white py-3 px-4"
              >
                Explore Our Services
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RealEstateAgent;
