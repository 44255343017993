import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "./BaseUrl";

const MobileSearch = ({ isModalOpen, closeModal }) => {
  const [activeTab, setActiveTab] = useState("buy");
  const [selectedCity, setSelectedCity] = useState("");
  const [propertyType, setPropertyType] = useState("");
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [categoryType, setCategoryType] = useState("");
  const [cities, setCities] = useState([]);

  const navigate = useNavigate();

  const step = 5000;
  const rangeValues = Array.from({ length: 2000 }, (_, i) => step * (i + 1));

  const [minPrice, setMinPrice] = useState(rangeValues[0]);
  const [maxPrice, setMaxPrice] = useState(rangeValues[rangeValues.length - 1]);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      content.classList.add('slide-in-right');
    }
  }, []);

  useEffect(() => {
    // Fetch cities from the API
    fetch(`${BaseUrl}/listcities`)
      .then((response) => response.json())
      .then((data) => setCities(data.results))
      .catch((error) => console.error("Error fetching cities:", error));
  }, []);

  const handlePropertyTypeChange = (event) => {
    setPropertyType(event.target.value);
  };

  const handleCategoryTypeChange = (e) => {
    setCategoryType(e.target.value);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleRangeChange = (e) => {
    const value = parseInt(e.target.value, 10);
    setMaxPrice(rangeValues[value]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let queryParams = new URLSearchParams();
    queryParams.append("tab", activeTab);

    const formData = new FormData(e.target);
    const bhkTypes = [];
    const residential_type = [];
    const commertial_type = [];
    const commercialPropertyTypes = [];
    const residentialPropertyTypes = [];

    formData.forEach((value, key) => {
      if (value) {
        if (key === "bhk_type") {
          bhkTypes.push(value);
        } else if (key === "commertial_type") {
          commercialPropertyTypes.push(value);
        } else if (key === "residential_type") {
          residentialPropertyTypes.push(value);
        } else {
          queryParams.append(key, value);
        }
      }
    });

    if (bhkTypes.length > 0) {
      const validBhkTypes = bhkTypes.filter((value) => value !== " ");
      if (validBhkTypes.length > 0) {
        queryParams.append("bhk_type", validBhkTypes.join(","));
      }
    }

    if (commercialPropertyTypes.length > 0) {
      queryParams.append("commertial_type", commercialPropertyTypes.join(","));
    }

    if (residentialPropertyTypes.length > 0) {
      queryParams.append(
        "residential_type",
        residentialPropertyTypes.join(",")
      );
    }
    queryParams.append("min_price", minPrice);
    queryParams.append("max_price", maxPrice);

    let finalQueryString = queryParams.toString().replace(/%2C/g, ",");

    navigate(
      `/resultsaywqpbetyaqbdlsujodbjxbiwtbafewytohmcvbsdwrruhyovbsanuranjanczswswrwexzcxdbdfshgtouor67evsdajsnxczs3swgdhjdvxsfs?${finalQueryString}`
    );
    closeModal();
  };


  


  
  return (
    isModalOpen && (
      <div
        className="modal fade show content"
        id="mobileSearch"
        tabIndex="-1"
        style={{ display: "block" }}
      >
        <div className="modal-dialog-scrollable modal-lg modal-dialog-centered loginmdl prtycss content">
                <div className="modal-content content " style={{ height: "105vh" }}>
                  <div className="modal-header content">
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={closeModal}
                      style={{paddingTop:"75px"}}
                    ></button>

                    
                  </div>
                  <h2 style={{textAlign:'center'}} className="theme_yellow ">Search Property</h2>
                  <div className="modal-body">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mdlcnt">
                          <ul className="nav nav-tabs hmtab" id="myTab">
                            <li className="nav-item">
                              <a
                                href="#buy"
                                className={`nav-link ${
                                  activeTab === "buy" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("buy")}
                              >
                                Buy
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="#rent"
                                className={`nav-link ${
                                  activeTab === "rent" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("rent")}
                              >
                                Rent
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                href="#lease"
                                className={`nav-link ${
                                  activeTab === "lease" ? "active" : ""
                                }`}
                                onClick={() => handleTabClick("lease")}
                              >
                                Lease
                              </a>
                            </li>
                          </ul>

                          <div
                            className="tab-content mt-1 "
                            style={{ textAlign: "left" }}
                          >
                            <form
                              onSubmit={handleSubmit}
                              // className="narrow-w form-search mb-3"
                            >
                              <div className="col-12 mt-4">
                                <select
                                  className="form-select form-control "
                                  name="city"
                                  value={selectedCity}
                                  onChange={(e) =>
                                    setSelectedCity(e.target.value)
                                  }
                                  required
                                >
                                  <option value="" className="dropdown-menu">
                                    Select City
                                  </option>
                                  {cities.map((city, index) => (
                                    <option key={index} value={city.city}>
                                      {city.city}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {activeTab === "buy" && (
                                <div
                                  className="tab-pane fade show active"
                                  id="buy"
                                >
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="row mt-2">
                                        <div className="col-12 mt-4">
                                          <div className="dropdown">
                                            <button
                                              className="dropdown-toggle slthm col-12"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                            >
                                              {capitalizeFirstLetter(
                                                propertyType
                                              ) || "Property Type"}
                                            </button>
                                            <ul className="dropdown-menu">
                                              <li>
                                                <button
                                                  className="dropdown-item"
                                                  name="property_type"
                                                  type="button"
                                                  value="residential"
                                                  onClick={
                                                    handlePropertyTypeChange
                                                  }
                                                >
                                                  Residential
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  className="dropdown-item"
                                                  name="property_type"
                                                  type="button"
                                                  value="commercial"
                                                  onClick={
                                                    handlePropertyTypeChange
                                                  }
                                                >
                                                  Commercial
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <input
                                          type="hidden"
                                          name="property_type"
                                          value={propertyType}
                                        />

                                        {propertyType === "residential" && (
                                          <div className="col-12 mt-4">
                                            <div className="dropdown">
                                              <button
                                                className="dropdown-toggle slthm"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                              >
                                                Residential Property Category
                                              </button>
                                              <ul className="dropdown-menu">
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    type="button"
                                                  >
                                                    <div className="mt-1">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="residential_type"
                                                        value="apartment_flat"
                                                      />
                                                      Apartment/Flat
                                                    </div>
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    type="button"
                                                  >
                                                    <div className="mt-1">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="residential_type"
                                                        value="independent_house_villa"
                                                      />
                                                      Independent House/Villa
                                                    </div>
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    type="button"
                                                  >
                                                    <div className="mt-1">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="residential_type"
                                                        value="gated_community_villa"
                                                      />
                                                      Gated Community Villa
                                                    </div>
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    type="button"
                                                  >
                                                    <div className="mt-1">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="residential_type"
                                                        value="land_plot"
                                                      />
                                                      Plot/Land
                                                    </div>
                                                  </button>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        )}

                                        {propertyType === "commercial" && (
                                          <div className="col-12 mt-4">
                                            <div className="dropdown">
                                              <button
                                                className="dropdown-toggle slthm"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                              >
                                                Commercial Property Category
                                              </button>
                                              <ul className="dropdown-menu">
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    type="button"
                                                  >
                                                    <div className="mt-1">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="commertial_type"
                                                        value="office_space"
                                                      />
                                                      OfficeSpace
                                                    </div>
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    type="button"
                                                  >
                                                    <div className="mt-1">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="commertial_type"
                                                        value="shop_showroom"
                                                      />
                                                      Shop/Showroom
                                                    </div>
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    type="button"
                                                  >
                                                    <div className="mt-1">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="commertial_type"
                                                        value="warehouse_godown"
                                                      />
                                                      Warehouse/Godown
                                                    </div>
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    type="button"
                                                  >
                                                    <div className="mt-1">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="commertial_type"
                                                        value="industrial_building"
                                                      />
                                                      Industrial Building
                                                    </div>
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    type="button"
                                                  >
                                                    <div className="mt-1">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="commertial_type"
                                                        value="mall"
                                                      />
                                                      Mall
                                                    </div>
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    type="button"
                                                  >
                                                    <div className="mt-1">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="commertial_type"
                                                        value="theatre"
                                                      />
                                                      Theatre
                                                    </div>
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    type="button"
                                                  >
                                                    <div className="mt-1">
                                                      <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="commertial_type"
                                                        value="land_plot"
                                                      />
                                                      Plot/Land
                                                    </div>
                                                  </button>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        )}

                                        {propertyType !== "commercial" && (
                                          <>
                                            <div className="col-12 mt-4">
                                              <div className="dropdown">
                                                <button
                                                  className="dropdown-toggle slthm"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  BHK Type
                                                </button>
                                                <ul className="dropdown-menu">
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="bhk_type"
                                                          value="0"
                                                        />
                                                        1 BHK
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="bhk_type"
                                                          value="1"
                                                        />
                                                        2 BHK
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="bhk_type"
                                                          value="2"
                                                        />
                                                        3 BHK
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="bhk_type"
                                                          value="3"
                                                        />
                                                        4 BHK
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="bhk_type"
                                                          value="4"
                                                        />
                                                        4+ BHK
                                                      </div>
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          </>
                                        )}

                                        <div className="col-12 mb-4 mt-4">
                                          <div className="dropdown">
                                            <button
                                              className="dropdown-toggle slthm"
                                              type="button"
                                              data-bs-toggle="dropdown"
                                            >
                                              Property Status
                                            </button>
                                            <ul className="dropdown-menu">
                                              <li>
                                                <button
                                                  className="dropdown-item"
                                                  type="button"
                                                >
                                                  <div className="mt-1">
                                                    <input
                                                      type="radio"
                                                      className="form-check-input"
                                                      name="property_status"
                                                      value="under_construction"
                                                    />
                                                    Under Construction
                                                  </div>
                                                </button>
                                              </li>
                                              <li>
                                                <button
                                                  className="dropdown-item"
                                                  type="button"
                                                >
                                                  <div className="mt-1">
                                                    <input
                                                      type="radio"
                                                      className="form-check-input"
                                                      name="property_status"
                                                      value="ready"
                                                    />
                                                    Ready
                                                  </div>
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>

                                        <div></div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* </form> */}
                                </div>
                              )}

                              {activeTab === "rent" && (
                                <div
                                  className="tab-pane fade show active"
                                  id="rent"
                                >
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="row mt-2">
                                        <div className="col-12 mb-4 mt-4">
                                          <div className="me-2">
                                            <div className="dropdown">
                                              <button
                                                className="dropdown-toggle slthm"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                              >
                                                {capitalizeFirstLetter(
                                                  propertyType
                                                ) || "Property Type"}
                                              </button>
                                              <ul className="dropdown-menu">
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    name="property_type"
                                                    type="button"
                                                    value="residential"
                                                    onClick={
                                                      handlePropertyTypeChange
                                                    }
                                                  >
                                                    Residential
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    name="property_type"
                                                    type="button"
                                                    value="commercial"
                                                    onClick={
                                                      handlePropertyTypeChange
                                                    }
                                                  >
                                                    Commercial
                                                  </button>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <input
                                            type="hidden"
                                            name="property_type"
                                            value={propertyType}
                                          />
                                          {propertyType === "residential" && (
                                            <div className="me-2 mb-4 mt-4">
                                              <div className="dropdown">
                                                <button
                                                  className="dropdown-toggle slthm"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  Residential Property Category
                                                </button>
                                                <ul className="dropdown-menu">
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="residential_type"
                                                          value="apartment_flat"
                                                        />
                                                        Apartment/Flat
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="residential_type"
                                                          value="independent_house_villa"
                                                        />
                                                        Independent House/Villa
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="residential_type"
                                                          value="gated_community_villa"
                                                        />
                                                        Gated Community Villa
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="residential_type"
                                                          value="land_plot"
                                                        />
                                                        Plot/Land
                                                      </div>
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          )}

                                          {propertyType === "commercial" && (
                                            <div className="me-2 mb-4 mt-4">
                                              <div className="dropdown">
                                                <button
                                                  className="dropdown-toggle slthm"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  Commercial Property Category
                                                </button>
                                                <ul className="dropdown-menu">
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="office_space"
                                                        />
                                                        OfficeSpace
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="show_showroom"
                                                        />
                                                        Shop/Showroom
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="warehouse_godown"
                                                        />
                                                        Warehouse/Godown
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="industrial_building"
                                                        />
                                                        Industrial Building
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="mall"
                                                        />
                                                        Mall
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="theatre"
                                                        />
                                                        Theatre
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="land_plot"
                                                        />
                                                        Plot/Land
                                                      </div>
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          )}

                                          {propertyType === "residential" && (
                                            <div className="me-2 mb-4 mt-4">
                                              <div className="dropdown">
                                                <button
                                                  className="dropdown-toggle slthm"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  Tenant Type
                                                </button>
                                                <ul className="dropdown-menu">
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="radio"
                                                          className="form-check-input"
                                                          name="tenant_type"
                                                          value="male"
                                                        />
                                                        Male
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="radio"
                                                          className="form-check-input"
                                                          name="tenant_type"
                                                          value="female"
                                                        />
                                                        Female
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="radio"
                                                          className="form-check-input"
                                                          name="tenant_type"
                                                          value="family"
                                                        />
                                                        Family
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="radio"
                                                          className="form-check-input"
                                                          name="tenant_type"
                                                          value="anyone"
                                                        />
                                                        Anyone
                                                      </div>
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          )}
                                          {propertyType === "residential" && (
                                            <div className="me-2 mt-4">
                                              <div className="dropdown">
                                                <button
                                                  className="dropdown-toggle slthm"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  BHK Type
                                                </button>
                                                <ul className="dropdown-menu">
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="bhk_type"
                                                          value="0"
                                                        />
                                                        1 BHK
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="bhk_type"
                                                          value="1"
                                                        />
                                                        2 BHK
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="bhk_type"
                                                          value="2"
                                                        />
                                                        3 BHK
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="bhk_type"
                                                          value="3"
                                                        />
                                                        4 BHK
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="bhk_type"
                                                          value="4"
                                                        />
                                                        4+ BHK
                                                      </div>
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              {activeTab === "lease" && (
                                <div
                                  className="tab-pane fade show active"
                                  id="lease"
                                >
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="row mt-2">
                                        <div className="col-12 mb-4 mt-4">
                                          <div className="me-2">
                                            <div className="dropdown">
                                              <button
                                                className="dropdown-toggle slthm"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                              >
                                                {capitalizeFirstLetter(
                                                  propertyType
                                                ) || "Property Type"}
                                              </button>
                                              <ul className="dropdown-menu">
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    name="property_type"
                                                    type="button"
                                                    value="residential"
                                                    onClick={
                                                      handlePropertyTypeChange
                                                    }
                                                  >
                                                    Residential
                                                  </button>
                                                </li>
                                                <li>
                                                  <button
                                                    className="dropdown-item"
                                                    name="property_type"
                                                    type="button"
                                                    value="commercial"
                                                    onClick={
                                                      handlePropertyTypeChange
                                                    }
                                                  >
                                                    Commercial
                                                  </button>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <input
                                            type="hidden"
                                            name="property_type"
                                            value={propertyType}
                                          />
                                          {propertyType === "residential" && (
                                            <div className="me-2 mt-4">
                                              <div className="dropdown">
                                                <button
                                                  className="dropdown-toggle slthm"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  Residential Property Category
                                                </button>
                                                <ul className="dropdown-menu">
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="residential_type"
                                                          value="apartment_flat"
                                                        />
                                                        Apartment/Flat
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="residential_type"
                                                          value="independent_house_villa"
                                                        />
                                                        Independent House/Villa
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="residential_type"
                                                          value="gated_community_villa"
                                                        />
                                                        Gated Community Villa
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="residential_type"
                                                          value="land_plot"
                                                        />
                                                        Plot/Land
                                                      </div>
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          )}

                                          {propertyType === "commercial" && (
                                            <div className="me-2 mt-4">
                                              <div className="dropdown">
                                                <button
                                                  className="dropdown-toggle slthm"
                                                  type="button"
                                                  data-bs-toggle="dropdown"
                                                >
                                                  Commercial Property Category
                                                </button>
                                                <ul className="dropdown-menu">
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="office_space"
                                                        />
                                                        OfficeSpace
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="show_showroom"
                                                        />
                                                        Shop/Showroom
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="warehouse_godown"
                                                        />
                                                        Warehouse/Godown
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="industrial_building"
                                                        />
                                                        Industrial Building
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="mall"
                                                        />
                                                        Mall
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="theatre"
                                                        />
                                                        Theatre
                                                      </div>
                                                    </button>
                                                  </li>
                                                  <li>
                                                    <button
                                                      className="dropdown-item"
                                                      type="button"
                                                    >
                                                      <div className="mt-1">
                                                        <input
                                                          type="checkbox"
                                                          className="form-check-input"
                                                          name="commertial_type"
                                                          value="land_plot"
                                                        />
                                                        Plot/Land
                                                      </div>
                                                    </button>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}

                              <div className="col-12 mb-4">
                                <h6 className="hd text-white">
                                  Price Range: {minPrice} To {maxPrice}
                                </h6>
                                <input
                                  type="range"
                                  className="form-range"
                                  min="0"
                                  max={rangeValues.length - 1}
                                  id="customRange2"
                                  onChange={handleRangeChange}
                                />
                              </div>
                              <div className="">
                                <input
                                  type="submit"
                                  value="Click To Continue"
                                  className=" col-8"
                                  style={{
                                    position: "fixed",
                                    left: "50%",
                                    // top: "93.7%",
                                    transform: "translateX(-50%)",
                                    backgroundColor: "#feb406",
                                    borderColor: "rgb(157 157 157)",
                                    color: "#000",
                                    padding: "10px 20px",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    borderRadius: "40px",
                                    textAlign: "center",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    width: "50%",
                                  }}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
      </div>
    )
  );
};

export default MobileSearch;
