import React, { useState, useEffect } from "react";
import Search from "./Search";
import SearchBox from "./Pages/SearchBox";
import { BaseUrl } from "./BaseUrl";

function Carousel() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch(`${BaseUrl}/getherosectionphotos`);
        const data = await response.json();
        if (data.success) {
          setImages(data.results.map((result) => result.image));
        } else {
          console.error("Failed to fetch images");
        }
      } catch (error) {
        console.error("Error fetching images:", error);
      }
    };

    fetchImages();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000); // 2000 ms = 2 seconds

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div>
      <div className="hero">
        {/* <div className="hero-slide">
          {images.map((image, index) => (
            <div
              key={index}
              className={`img overlay ${index === currentImageIndex ? 'active' : ''}`}
              style={{
                backgroundImage: `url(${image})`,
                display: index === currentImageIndex ? 'block' : 'none'
              }}
            />
          ))}
        </div> */}
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-lg-8 text-center">
              <h1 className="heading">
                Easiest way to find <span class="theme_yellow">your dream home</span>
              </h1>
              {/* <Search /> */}
              <SearchBox />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carousel;
