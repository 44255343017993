import React from 'react';

function PopIndex() {
  return (
    <div className="section">
      <div className="container">
        <div className="row mb-5 align-items-center">
          <div className="col-lg-6">
            <h2 className="font-weight-bold text-primary heading">
              Popular Properties
            </h2>
          </div>
          <div className="col-lg-6 text-lg-end">
            <p>
              <a
                href="#"
                target="_blank"
                className="btn btn-primary text-white py-3 px-4"
              >
                View all properties
              </a>
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="property-slider-wrap">
              <div className="property-slider">
                <PropertyItem imgSrc="images/img_1.jpg" price="$1,291,000" address="5232 California Fake, Ave. 21BC" city="California, USA" beds="2" baths="2" />
                <PropertyItem imgSrc="images/img_2.jpg" price="$1,291,000" address="5232 California Fake, Ave. 21BC" city="California, USA" beds="2" baths="2" />
                <PropertyItem imgSrc="images/img_3.jpg" price="$1,291,000" address="5232 California Fake, Ave. 21BC" city="California, USA" beds="2" baths="2" />
                {/* Add more PropertyItem components for each property */}
              </div>

              <div
                id="property-nav"
                className="controls"
                tabIndex="0"
                aria-label="Carousel Navigation"
              >
                <span
                  className="prev"
                  data-controls="prev"
                  aria-controls="property"
                  tabIndex="-1"
                >
                  Prev
                </span>
                <span
                  className="next"
                  data-controls="next"
                  aria-controls="property"
                  tabIndex="-1"
                >
                  Next
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PropertyItem({ imgSrc, price, address, city, beds, baths }) {
  return (
    <div className="property-item">
      <a href="property-single.html" className="img">
        <img src={imgSrc} alt="Image" className="img-fluid" />
      </a>

      <div className="property-content">
        <div className="price mb-2"><span>{price}</span></div>
        <div>
          <span className="d-block mb-2">{address}</span>
          <span className="city d-block mb-3">{city}</span>

          <div className="specs d-flex mb-4">
            <span className="d-block d-flex align-items-center me-3">
              <span className="icon-bed me-2"></span>
              <span className="caption">{beds} beds</span>
            </span>
            <span className="d-block d-flex align-items-center">
              <span className="icon-bath me-2"></span>
              <span className="caption">{baths} baths</span>
            </span>
          </div>

          <a href="property-single.html" className="btn btn-primary py-2 px-3">
            See details
          </a>
        </div>
      </div>
    </div>
  );
}

export default PopIndex;
