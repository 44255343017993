import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "./BaseUrl";
import ChatWidget from "./ChatWidget";
import QRCode from "qrcode.react"; // Import the QRCode component

function ContactUsContent() {
  const [contactDetails, setContactDetails] = useState({});
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    query: "",
    ip: "",
  });
  const [errors, setErrors] = useState({
    phone: "",
  });

  useEffect(() => {
    // Fetch contact details and IP address on component mount
    axios
      .get(`${BaseUrl}/getallsettingdetailsinclient`)
      .then((response) => {
        if (response.data.success) {
          setContactDetails(response.data.results);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the contact details!", error);
      });

    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        setFormData((prevData) => ({ ...prevData, ip: response.data.ip }));
      })
      .catch((error) => {
        console.error("There was an error fetching the IP address!", error);
      });

    // Event listener for window popstate
    const handlePopState = () => {
      if (window.Tawk_API) {
        // Ensure the chat widget is maximized and remains open
        window.Tawk_API.maximize();
      }
    };

    // Add event listener for window popstate
    window.addEventListener("popstate", handlePopState);

    // Cleanup: Remove event listener for window popstate on component unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Validate phone number
    if (name === "phone") {
      const phonePattern = /^\d{10}$/;
      if (!phonePattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phone: "Invalid phone number. It should be 10 digits.",
        }));
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if there are any validation errors
    if (errors.phone) {
      alert("Please correct the errors in the form before submitting.");
      return;
    }

    // Submit form data
    axios
      .post(`${BaseUrl}/submitquery`, formData)
      .then((response) => {
        if (response.data.success) {
          alert("Query sent successfully!");
          // Clear the form data after successful submission
          setFormData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            query: "",
            ip: formData.ip, // Keep the IP as it is
          });
        } else {
          alert("Failed to send Query.");
        }
      })
      .catch((error) => {
        console.error("There was an error sending the Query!", error);
      });
  };

  return (
    <div>
      {/* <ChatWidget /> */}
      <div className="section">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 mb-5 mb-lg-0">
              <div className="contact-info">
                <div className="address mt-2">
                  <i className="icon-room" />
                  <h4 className="mb-2">Location:</h4>
                  <p>{contactDetails.address}</p>
                  {contactDetails.location_link && (
                    <div>
                      <QRCode value={contactDetails.location_link} size={128} style={{marginLeft:'62px', marginTop:'8px', border:'1px solid white'}}/>
                      <p>
                        <a href={contactDetails.location_link} target="_blank" rel="noopener noreferrer">
                          View on Map
                        </a>
                      </p>
                    </div>
                  )}
                </div>
                <div className="open-hours mt-4">
                  <i className="icon-clock-o" />
                  <h4 className="mb-2">Open Hours:</h4>
                  <p>
                    Sunday-Friday:
                    <br />
                    11:00 AM - 11:00 PM
                  </p>
                </div>
                <div className="email mt-4">
                  <i className="icon-envelope" />
                  <h4 className="mb-2">Email:</h4>
                  <p>{contactDetails.official_email}</p>
                </div>
                <div className="phone mt-4">
                  <i className="icon-phone" />
                  <h4 className="mb-2">Call:</h4>
                  <p>{contactDetails.official_phone}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-6 mb-3">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Enter Your Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter Your Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-6 mb-3">
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      placeholder="Enter Your Phone"
                      value={formData.phone}
                      onChange={handleChange}
                      required
                    />
                    {errors.phone && (
                      <span style={{ color: "red", fontSize: "12px" }}>
                        {errors.phone}
                      </span>
                    )}
                  </div>
                  <div className="col-6 mb-3">
                    <input
                      type="text"
                      name="subject"
                      className="form-control"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <textarea
                      name="query"
                      cols={30}
                      rows={7}
                      className="form-control"
                      placeholder="Describe Your Query"
                      value={formData.query}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="submit"
                      value="Send Message"
                      className="btn btn-primary"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsContent;
