import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../BaseUrl";

const LogVisit = ({ propertyId }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('detailstoken');
    const userId = localStorage.getItem('userId');
    const email = localStorage.getItem('email');
    const timestamp = new Date().toISOString();
    const propertyUrl = `${window.location.origin}/property-details/${propertyId}`;

    // Function to fetch user IP address
    const fetchUserIP = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        return data.ip;
      } catch (error) {
        console.error('Error fetching IP address:', error);
        return '0.0.0.0'; // Fallback IP address
      }
    };

    const logVisit = async () => {
      const userIP = await fetchUserIP();

      const requestData = {
        token,
        userId,
        email,
        timestamp,
        propertyId,
        propertyUrl,
        userIP,
      };

      fetch(`${BaseUrl}/logvisits`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {
          // console.log('Visit logged:', data);
          navigate(`/property-details/${propertyId}`);
        })
        .catch(error => {
          console.error('Error logging visit:', error);
          navigate(`/property-details/${propertyId}`);
        });
    };

    logVisit();
  }, [propertyId, navigate]);

  return null;
};

export default LogVisit;
