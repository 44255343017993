import React, { useEffect, useState } from "react";
import Features from "./Features";
import { isPlatform } from "@ionic/react";  

const LegalServicesContent = () => {

  const [isIOS, setIsIOS] = useState(false); 

  useEffect(() => {
  if (isPlatform("ios")) {
    setIsIOS(true);
  }
}, []);

  // console.log(isIOS,"plaeform")

  return (
    <>
      {/* <div className="section " style={{ padding: "3px", margin: "5px" }}>
        <div className="container mt120">
          <div className="row text-left">
            <div className="col-12">
              <h2 className="font-weight-bold heading text-primary mb-4">
                Services
              </h2>
            </div>
            <div className="col-lg-6">
              <p>
                B. V. Bellad Law College is a renowned institution offering
                legal education and training rather than providing legal
                services directly. However, law colleges like B. V. Bellad Law
                College typically offer various legal clinics or centers where
                students, under the supervision of faculty members or practicing
                attorneys, may provide legal services to the community.
              </p>
              <p>
                Our legal clinics cover a wide range of services, including but
                not limited to:
              </p>
              <ul>
                <li>
                  <strong>Legal Advice and Consultation:</strong> Providing
                  general legal advice and consultations on various legal
                  matters.
                </li>
                <li>
                  <strong>Document Preparation:</strong> Assisting in the
                  preparation of legal documents, such as contracts, wills, and
                  legal forms.
                </li>
                <li>
                  <strong>Representation:</strong> Representing clients in
                  certain legal matters under the supervision of licensed
                  attorneys.
                </li>
              </ul>
            </div>
            <div className="col-lg-6 desktop-view">
              <h3 className="font-weight-bold heading text-secondary mb-4">
                Package and Movers Services
              </h3>
              <p>
                In addition to our legal services, we also provide comprehensive
                package and movers services to ensure a smooth and stress-free
                moving experience. Our services include:
              </p>
              <ul>
                <li>
                  <strong>Packing and Unpacking:</strong> Professional packing
                  and unpacking services to safeguard your belongings.
                </li>
                <li>
                  <strong>Loading and Unloading:</strong> Efficient loading and
                  unloading services to handle your items with care.
                </li>
                <li>
                  <strong>Transportation:</strong> Secure transportation of your
                  possessions to your new location.
                </li>
                <li>
                  <strong>Storage Solutions:</strong> Short-term and long-term
                  storage options to suit your needs.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}

      <div className={`mt120 mb-5" ${isIOS ? "ios-nav" : ""}`}>
        <div >
          <Features />
        </div>
      </div>
    </>
  );
};

export default LegalServicesContent;
