import React from 'react';

const LoadingModal = ({ message }) => {
  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9999,
  };

  const spinnerStyle = {
    border: '10px solid #f3f3f3', /* Light grey */
    borderTop: '10px solid #feb406', /* Blue */
    borderRadius: '50%',
    width: '100px',
    height: '100px',
    animation: 'spin 2s linear infinite',
  };

  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: 'white',
    fontSize: '1.5em',
  };

  const animationStyle = `
    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  `;

  React.useEffect(() => {
    const styleSheet = document.createElement('style');
    styleSheet.type = 'text/css';
    styleSheet.innerText = animationStyle;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);

  return (
    <div style={modalStyle}>
      <div style={contentStyle}>
        <div style={spinnerStyle}></div>
        <p style={{textAlign:'center'}}>{message}</p>
      </div>
    </div>
  );
};

export default LoadingModal;
