import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { BaseUrl } from './BaseUrl';
import { jsPDF } from 'jspdf';
import html2pdf from 'html2pdf.js';

const InvoiceGenerator = () => {
  const [data, setData] = useState(null);
  const { payment_id } = useParams();

  useEffect(() => {
    axios.get(`${BaseUrl}/downloadinvoice/${payment_id}`)
      .then(response => {
        setData(response.data);
      })
      .catch(error => {
        console.error('Error fetching invoice data:', error);
      });
  }, [payment_id]);

  const downloadPDF = () => {
    const element = document.querySelector('#invoice');
    const options = {
      margin: 0,
      filename: 'invoice.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
    };
    html2pdf().set(options).from(element).save();
  };

  if (!data) {
    return <div>Loading...</div>;
  }

  const {
    companyDetails: {
      logo,
      company_name,
      gstin,
      address,
      companyState,
      email,
    },
    userDetails: {
      name,
      city,
      state,
      country,
      mobile,
    },
    paymentDetails: {
      payment_amount,
      payment_method,
      invoice_number,
      basicValueTotal,
      gstTotal,
      totalDiscount,
      finalPayableAmount,
      amountInWords,
      invoiceItems,
    },
  } = data;

  const date = new Date().toLocaleDateString();

  return (
    <div className="content" style={{ padding: '20px', fontFamily: 'Arial, sans-serif', width:'100%', maxWidth:'50vh' }}>
      {/* <button 
        onClick={downloadPDF} 
        style={{ marginBottom: '20px', padding: '10px 20px', background: '#007bff', color: '#fff', border: 'none', borderRadius: '5px', cursor: 'pointer' }}
      >
        Download PDF
      </button> */}
      <div id="invoice" style={{width:'width: 100%;', padding: '30px', backgroundColor: '#f8f9fa', border:'1px solid #000', maxWidth:'40vh', overflowY:'auto', overflowX:'auto' }}>
        <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '12px', color:'#000', border:'1px solid #000', overflowY:'auto' }}>
          <tbody>
            <tr>
              <td>
                <table style={{ width: '100%', borderCollapse: 'collapse'}}>
                  <tbody style={{width:'100%'}}>
                    <tr>
                      <td align="center" style={{ background: '#f8f9fa', borderBottom:'1px solid #000' }}>
                        <img 
                          src={logo} 
                          alt="Company Logo" 
                          style={{ width: '150px' }} 
                        />
                        <h4 style={{ margin: '10px 0' }}>{company_name}</h4>
                        <p style={{margin:'0px'}}>{address}</p>
                        <p style={{margin:'0px'}}>GSTIN: {gstin}</p>
                        <p style={{margin:'0px'}}>Invoice Number: {invoice_number}</p>
                        <p style={{margin:'0px'}}>Date: {date}</p>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ padding: '10px 0' }}>
                        <b><u>Details of Receiver (Billed to)</u></b>
                        <p style={{margin:'0px'}}>Name: {name}</p>
                        <p style={{margin:'0px'}}>Address: {city}, {state}, {country}</p>
                        <p style={{margin:'0px'}}>Phone Number: {mobile}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ width: '100%', borderLeft:'none',  borderCollapse: 'collapse', fontSize: '12px', border: '1px solid #000' }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>S.No.</th>
                      <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Description of Goods</th>
                      <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Qty</th>
                      <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Price ₹</th>
                      <th style={{ border: '1px solid #000', padding: '8px', textAlign: 'left' }}>Taxable Amount ₹</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceItems.map((item, index) => (
                      <tr key={index}>
                        <td style={{ border: '1px solid #000', padding: '8px' }}>{item.sNo}</td>
                        <td style={{ border: '1px solid #000', padding: '8px' }}>{item.description}</td>
                        <td style={{ border: '1px solid #000', padding: '8px' }}>{item.qty}</td>
                        <td style={{ border: '1px solid #000', padding: '8px' }}>{item.price}</td>
                        <td style={{ border: '1px solid #000', padding: '8px' }}>{item.taxableAmount}</td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="4" style={{ border: '1px solid #000', padding: '8px', textAlign: 'right', fontWeight: 'bold' }}>Sub Total ₹</td>
                      <td style={{ border: '1px solid #000', padding: '8px' }}>{basicValueTotal.toFixed(2)}</td>
                    </tr>
                    {state === companyState ? (
                      <>
                        <tr>
                          <td colSpan="4" style={{ border: '1px solid #000', padding: '8px', textAlign: 'right', fontWeight: 'bold' }}>CGST Amount (9.00%) in ₹</td>
                          <td style={{ border: '1px solid #000', padding: '8px' }}>{(gstTotal / 2).toFixed(2)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4" style={{ border: '1px solid #000', padding: '8px', textAlign: 'right', fontWeight: 'bold' }}>SGST Amount (9.00%) in ₹</td>
                          <td style={{ border: '1px solid #000', padding: '8px' }}>{(gstTotal / 2).toFixed(2)}</td>
                        </tr>
                      </>
                    ) : (
                      <tr>
                        <td colSpan="4" style={{ border: '1px solid #000', padding: '8px', textAlign: 'right', fontWeight: 'bold' }}>IGST Amount (18.00%) in ₹</td>
                        <td style={{ border: '1px solid #000', padding: '8px' }}>{gstTotal.toFixed(2)}</td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan="4" style={{ border: '1px solid #000', padding: '8px', textAlign: 'right', fontWeight: 'bold' }}>Discount Amount ₹</td>
                      <td style={{ border: '1px solid #000', padding: '8px' }}>{totalDiscount.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td colSpan="4" style={{ border: '1px solid #000', padding: '8px', textAlign: 'right', fontWeight: 'bold' }}>Total Amount ₹</td>
                      <td style={{ border: '1px solid #000', padding: '8px' }}>{finalPayableAmount.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td colSpan="5" style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Total Amount (in words): <span style={{ textTransform: 'capitalize' }}>{amountInWords}</span></td>
                    </tr>
                    <tr>
                      <td colSpan="5" style={{ border: '1px solid #000', padding: '8px', textAlign: 'left', fontWeight: 'bold' }}>Payment Method: <span style={{ textTransform: 'capitalize' }}>{payment_method}</span></td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'center', fontSize: '12px' }}>
                  <tbody>
                    <tr>
                      <td colSpan="3" style={{ border: '1px solid #000', padding: '8px', fontWeight: 'bold' }}>HSAS CREATIVE SOLUTIONS</td>
                    </tr>
                    <tr>
                      <td colSpan="3" style={{ border: '1px solid #000', padding: '8px', fontWeight: 'bold' }}>HSAS CREATIVE SOLUTIONS</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid #000', padding: '8px' }}>Signature</td>
                      <td style={{ border: '1px solid #000', padding: '8px' }}>Authorized Signatory</td>
                      <td style={{ border: '1px solid #000', padding: '8px' }}>Date</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid #000', padding: '8px' }}>_____________</td>
                      <td style={{ border: '1px solid #000', padding: '8px' }}>_____________</td>
                      <td style={{ border: '1px solid #000', padding: '8px' }}>_____________</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <div style={{ fontSize: '10px', textAlign: 'center' }}>
                  <p>Thank you for your business!</p>
                  <p>For any queries related to this invoice, please contact us at: <a href={`mailto:${data.companyDetails.email}`}>{data.companyDetails.email}</a></p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InvoiceGenerator;

                      
