import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

function SearchProperties({ properties = [] }) {
    return (
        <div className="properties">
            {properties.length > 0 ? (
                properties.map((property, index) => (
                    <div key={index} className="property">
                        <h3>{property.name}</h3>
                        <p>{property.address}</p>
                        <p>{property.price}</p>
                    </div>
                ))
            ) : (
                <p>No properties found</p>
            )}
        </div>
    );
}

export default SearchProperties;
