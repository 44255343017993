import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {jwtDecode} from "jwt-decode"; 
import { BaseUrl } from "./BaseUrl";
import { setUserDetails } from "../redux/actions/userActions";
import { browserName, osName } from "react-device-detect";

const LoginModal = ({ show, handleClose }) => {
  const [userInfo, setUserInfo] = useState({
    mobile: "",
    password: "",
  });
  const [ipAddress, setIpAddress] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [logoUrl, setLogoUrl] = useState("");
  const [logoError, setLogoError] = useState(false);

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        setIpAddress(response.data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/getlogodetails`);
        if (response.data.success) {
          setLogoUrl(response.data.results.logo);
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };

    fetchLogo();
  }, []);

  const handleChangeForLogin = (e) => {
    const { name, value } = e.target;
    setUserInfo({
      ...userInfo,
      [name]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const loginData = {
        ...userInfo,
        ip: ipAddress,
        browser: browserName,
        os: osName,
      };
      const response = await axios.post(`${BaseUrl}/userlogin`, loginData);

      if (response.status === 201) {
        const { usertoken, id, email } = response.data;
        if (!localStorage.getItem("email")) {
          localStorage.setItem("email", email);
        }
        localStorage.setItem("usertoken", usertoken);
        localStorage.setItem("userId", id);
        const decodedToken = jwtDecode(usertoken);
        dispatch(
          setUserDetails({
            id: decodedToken._id,
            name: decodedToken.name,
            email: decodedToken.email,
            mobile: decodedToken.mobile,
            usertype: decodedToken.usertype,
            occupation: decodedToken.occupation,
            budget: decodedToken.budget,
            gender: decodedToken.gender,
            city: decodedToken.city,
            state: decodedToken.state,
            country: decodedToken.country,
          })
        );
        setUserInfo({
          mobile: "",
          password: "",
        });
        handleClose();
      } else {
        alert(response.data.message);
        setUserInfo({
          mobile: "",
          password: "",
        });
      }
    } catch (error) {
      console.error(error);
      alert("An error occurred during login. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal show={show} onHide={handleClose} style={{ marginTop: '0vh' }}>
      <Modal.Header closeButton>
        <Modal.Title>Login</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center mt-4">
          {logoUrl && !logoError ? (
            <img
              src={logoUrl}
              alt="Property Icon"
              onLoad={() => console.log("Logo loaded successfully")}
              onError={(e) => {
                console.error("Error loading logo:", e);
                setLogoError(true);
              }}
              style={{
                maxWidth: "40%",
                maxHeight: "50%",
                objectFit: "contain",
              }}
            />
          ) : null}
        </div>
        <div className="mt-4">
          <h6 className="hd">Login For Users</h6>
        </div>
        <form onSubmit={handleLogin} autoComplete="off">
          <div className="row mt-2">
            <div className="col-12 mb-3">
              <label>Enter Mobile Number / Email</label>
              <input
                type="text"
                name="mobile"
                className="form-control"
                placeholder="Enter Your Mobile Number / Email"
                onChange={handleChangeForLogin}
                value={userInfo.mobile}
                required
              />
            </div>
            <div className="col-12 mb-3">
              <label>Enter Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Enter Password"
                onChange={handleChangeForLogin}
                value={userInfo.password}
                autoComplete="new-password"
                required
              />
            </div>
            <div className="text-center">
              <input
                type="submit"
                value="Login"
                className="btn btn-primary col-6"
                disabled={isSubmitting}
              />
            </div>
            <div className="mt-3">
              <small>
                By continuing, you agree to our{" "}
                <strong>Terms &amp; Conditions</strong>
              </small>
            </div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LoginModal;
