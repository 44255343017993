import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { BaseUrl } from "../BaseUrl";

function FeatureProperties() {
  const outerSliderRef = useRef(null);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    axios.get(`${BaseUrl}/featuredproperties`)
      .then(response => {
        setProperties(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the properties!", error);
      });
  }, []);

  const outerSliderSettings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  const imageSliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleNext = () => {
    outerSliderRef.current.slickNext();
  };

  const handlePrev = () => {
    outerSliderRef.current.slickPrev();
  };

  return (
    <div>
      <div className="section" style={{ marginTop: '0px', paddingTop: '0px' }}>
        <div className="container">
          <div className="col-lg-6">
            <h2 className="font-weight-bold text-primary">
              Featured Properties
            </h2>
          </div>
          <div className="row">
            <div className="col-12">
              <Slider ref={outerSliderRef} {...outerSliderSettings}>
                {properties.map((property, index) => (
                  <div key={index} className="property-item">
                    <div className="img-slider" style={{width:'90%'}}>
                      {Array.isArray(property.image_names) && property.image_names.length > 0 ? (
                        property.image_names.length > 1 ? (
                          <Slider {...imageSliderSettings}>
                            {property.image_names.map((image, i) => (
                              <div key={i}>
                                <img
                                  src={image}
                                  alt={`Property ${i}`}
                                  className="img-fluid"
                                  style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                                />
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          <div>
                            <img
                              src={property.image_names[0]}
                              alt="Property"
                              className="img-fluid"
                              style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                            />
                          </div>
                        )
                      ) : (
                        <div>
                          <img
                            src="/path/to/default-image.jpg"
                            alt="Default Property"
                            className="img-fluid"
                            style={{ width: '100%', height: '250px', objectFit: 'cover' }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="property-content" style={{width:"90%"}}>
                      <div className="price mb-2">
                        <span>&#8377; {property.price}</span>
                      </div>
                      <div>
                        <span className="d-block mb-2">
                          {property.address}
                        </span>
                        <span className="city d-block mb-3">
                          {property.city}, {property.state}
                        </span>
                        <a
                          href={`/property-details/${property.id}`}
                          className="btn btn-primary py-2 px-3"
                        >
                          See details
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <div className="slider-controls mt-4 text-center" id="testimonial-nav">
                <span onClick={handlePrev} className="prev" style={{ float: 'left', marginLeft: '40px' }}>
                  Prev
                </span>
                <span onClick={handleNext} className="next" style={{ float: 'right', marginRight: '40px' }}>
                  Next
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureProperties;
