import React, { useEffect, useState } from "react";
import Header from "../Header";
import LegalServicesBanner from "../LegalServicesBanner";
import LegalServicesContent from "../LegalServicesContent";
import Clinics from "../Clinics";
import Testimonials from "../Testimonials";
import Footer from "../Footer";
import FaqsLegal from "../FaqsLegal";
import RightImage from "../RightImage";
import CompanyCopyright from "../CompanyCopyright";

function LegalServices() {
  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      content.classList.add('slide-in-right');
    }
  }, []);

  return (
    <div className="content">
      <Header />
      {/* <LegalServicesBanner /> */}
      <LegalServicesContent />
      <RightImage/>
      <Clinics />
      <Testimonials />
      <FaqsLegal />
      {/* <div className="mobile-view">
      <CompanyCopyright/>
      </div> */}
      <Footer />
    </div>
  );
}

export default LegalServices;
