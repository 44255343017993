import React, { useEffect, useState } from "react";
import axios from "axios";
import Slider from "react-slick";
import { BaseUrl } from "./BaseUrl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ExpertiseImages() {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch expertise photos from the API
    axios.get(`${BaseUrl}/getexpertisephotos`)
      .then(response => {
        if (response.data.success) {
          setImages(response.data.results);
        }
      })
      .catch(error => {
        console.error("There was an error fetching the expertise photos!", error);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <div>
      <div className="section section-5">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-lg-6 mb-5">
              <h2 className="font-weight-bold heading text-primary">
                Expertise Photos
              </h2>
              <p className="">
                A showcase of our expertise and professional moments.
              </p>
            </div>
          </div>
          <Slider {...settings}>
            {images.map(image => (
              <div key={image.id} className="px-3">
                <div className="">
                  <div className="d-flex justify-content-center">
                    <img
                      src={image.images_array}
                      alt="Expertise Photo"
                      className="img-fluid"
                      style={{ width: "100%", height: "auto", objectFit: "cover" }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default ExpertiseImages;
