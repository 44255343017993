import React from "react";

function LeftImage() {
  return (
    <div>
      <div className="section pt-0">
        <div className="container">
          <div className="row justify-content-between mb-5">
            <div className="col-lg-7 mb-5 mb-lg-0">
              <div className="img-about dots">
                <img
                  src="images/hero_bg_2.jpg"
                  alt="Image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex feature-h">
                <span className="wrap-icon me-3">
                  <span className="icon-home2" />
                </span>
                <div className="feature-text">
                  <h3 className="heading">Quality Properties</h3>
                  <p className="">
                    We offer a diverse range of high-quality properties,
                    ensuring you find the perfect home or investment
                    opportunity.
                  </p>
                </div>
              </div>
              <div className="d-flex feature-h">
                <span className="wrap-icon me-3">
                  <span className="icon-person" />
                </span>
                <div className="feature-text">
                  <h3 className="heading">Top-Rated Agents</h3>
                  <p className="">
                    Our experienced agents are dedicated to providing top-notch
                    service, guiding you through every step of the process.
                  </p>
                </div>
              </div>
              <div className="d-flex feature-h">
                <span className="wrap-icon me-3">
                  <span className="icon-security" />
                </span>
                <div className="feature-text">
                  <h3 className="heading">Easy and Safe</h3>
                  <p className="">
                    We prioritize your safety and convenience, ensuring a smooth
                    and secure experience throughout your real estate journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftImage;
