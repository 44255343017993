import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { BaseUrl } from '../BaseUrl';

const Terms = () => {
  const [termsContent, setTermsContent] = useState('');

  useEffect(() => {
    const fetchTermsDetails = async () => {
      try {
        const response = await fetch(`${BaseUrl}/gettermsandconditions`);
        const data = await response.json();
        if (data.success && data.results.length > 0) {
          setTermsContent(data.results[0].terms_and_conditions);
        } else {
          console.error('No terms policy data found');
        }
      } catch (error) {
        console.error('Error fetching terms policy data:', error);
      }
    };

    fetchTermsDetails();
  }, []);

  return (
    <>
      <Header />
      <div className="section">
        <div className="container mt120">
          <div className="text-center mb70">
            <h1 className="font-weight-bold text-primary heading mb-5">
              Terms and Conditions
            </h1>
          </div>
          <div
            className="modal-body"
            dangerouslySetInnerHTML={{ __html: termsContent || 'Loading...' }}
          ></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
