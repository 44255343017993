import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { BaseUrl } from "./BaseUrl";
import { useSelector } from "react-redux";
import ChatWidget from "./ChatWidget";
import Loader from "./Loader";
import LoadingModal from "./LoadingModal";
import { Browser } from '@capacitor/browser';


const SelectedItemsSummary = () => {
  const userDetails = useSelector((state) => state.user.userDetails);
  const email = useSelector((state) => state.user.userDetails.email);
  const city = useSelector((state) => state.user.userDetails.city);

  const location = useLocation();
  const navigate = useNavigate();
  const [totalCoins, setTotalCoins] = useState(0);
  const [appliedRewards, setAppliedRewards] = useState(0);
  const [rewardsToApply, setRewardsToApply] = useState(0);
  const [selectedPackages, setSelectedPackages] = useState(
    location.state?.selectedPackages || []
  );
  const [selectedServices, setSelectedServices] = useState(
    location.state?.selectedServices || []
  );
  const [totalAmount, setTotalAmount] = useState(
    location.state?.totalAmount || 0
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [couponDiscount, setCouponDiscount] = useState(0);
  const [couponMessage, setCouponMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [isCouponApplied, setIsCouponApplied] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    if (!userId) {
      alert("User not logged in.");
      return;
    }

    axios
      .get(`${BaseUrl}/getrewards/${userId}`)
      .then((response) => {
        if (response.data && response.data.totalCoins) {
          setTotalCoins(response.data.totalCoins);
        } else {
          console.error("No rewards found in response:", response.data);
          setTotalCoins(0);
        }
      })
      .catch((error) => {
        console.error("Error fetching rewards:", error);
        setTotalCoins(0);
      });
  }, []);

  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      content.classList.add('slide-in-right');
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getServiceAndPackageIds = (packages, services) => {
    const serviceIds = services.map((service) => service.id);
    const packageIds = packages.map((pkg) => pkg.id);
    return { serviceIds, packageIds };
  };

  const htmlToText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  const handlePopState = () => {
    if (window.Tawk_API) {
      window.Tawk_API.maximize();
    }
  };

  window.addEventListener("popstate", handlePopState);

  useEffect(() => {
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const getIpAddress = async () => {
    try {
      const response = await axios.get("https://api64.ipify.org?format=json");
      return response.data.ip;
    } catch (error) {
      console.error("Error fetching IP address", error);
      return null;
    }
  };

  const handleProceedToPayment = async () => {
    setIsSubmitting(true);
    const { serviceIds, packageIds } = getServiceAndPackageIds(
      selectedPackages,
      selectedServices
    );
    const ip = await getIpAddress();

    if (!ip) {
      alert("Unable to fetch IP address.");
      return;
    }

    const userId = localStorage.getItem("userId");

    if (!userId) {
      alert("User not logged in.");
      return;
    }

    if (!city) {
      alert("Please Enter Address [ City, State, Country ]");
      navigate("/profile");
      return;
    }

    const couponApplied = await handleApplyCoupon();
    if (!couponApplied) {
      setIsSubmitting(false);
      return;
    }

    
      const settingsResponse = await axios.get(`${BaseUrl}/getallsettingdetailsinclient`);
      const razorKey = settingsResponse.data?.results?.razor_key;
  
      if (!razorKey) {
        alert("Unable to fetch payment gateway key.");
        setIsSubmitting(false);
        return;
      }
   
  
  

    const orderData = {
      serviceIds,
      packageIds,
      userId,
      ip,
      appliedRewards,
      couponCode,
    };

    const initPayment = (data) => {
      const options = {
        key: razorKey,
        amount: data.finalAmount,
        currency: data.currency,
        description: "Test transaction",
        order_id: data.id,
        handler: async (response) => {
          setIsLoading(true);
          setLoadingMessage("Verifying payment...");

          try {
            const verifyUrl = `${BaseUrl}/verify`;
            const { data } = await axios.post(verifyUrl, {
              ...response,
              email,
            });

            if (data.success) {
              setLoadingMessage("Payment in process, please wait...");

              await axios.post(`${BaseUrl}/reducecoins`, {
                userId,
                coins: appliedRewards,
                email,
              });

              await createTicket(
                "Payment successful",
                "What document should we upload?",
                email
              );

              // Check for webhook event
              await checkWebhookEvent(response.razorpay_payment_id);
            } else {
              setLoadingMessage("Payment verification failed");
              setIsLoading(false);
            }
          } catch (error) {
            setLoadingMessage(`Error: ${error.message}`);
            setIsLoading(false);
          }
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    };

    const checkWebhookEvent = async (razorpayPaymentId) => {
      const checkWebhookEventUrl = `${BaseUrl}/checkwebhookeventindb/${razorpayPaymentId}`;
      const startTime = Date.now();
      const timeoutDuration = 2 * 60 * 1000; // 2 minutes

      const checkEvent = async () => {
        const elapsedTime = Date.now() - startTime;
        if (elapsedTime >= timeoutDuration) {
          setLoadingMessage(
            "Payment processing timeout. Please check your order status later."
          );
          setIsLoading(false);
          return;
        }

        try {
          const { data } = await axios.get(checkWebhookEventUrl);
          if (data.success) {
            setLoadingMessage("Payment verified successfully! Thank you.");
            
            // Use a timeout to wait before navigating
            setTimeout(async () => {
              // Attempt to close the in-app browser if it's open
              try {
                await Browser.close(); // Note: Browser.close() is hypothetical as Capacitor Browser does not support close directly
              } catch (e) {
                console.error('Error closing browser:', e);
              }
      
              // Navigate to the desired route
              navigate("/profile/tickets");
            }, 3000); // Wait for 3 seconds before navigating
          } else {
            setTimeout(checkEvent, 5000); // Check every 5 seconds
          }
        } catch (error) {
          setLoadingMessage(`Error checking webhook event: ${error.message}`);
          setIsLoading(false);
        }
      };

      checkEvent();
    };

    const createTicket = async (subject, message) => {
      try {
        const userId = localStorage.getItem("userId");
        await axios.post(`${BaseUrl}/createticket`, {
          userId,
          subject: subject,
          client_ip: ip,
          message: message,
        });
        // alert("Ticket Raised Successfully");
      } catch (error) {
        console.error("Error creating ticket:", error);
      }
    };

    try {
      const orderResponse = await axios.post(`${BaseUrl}/orders`, orderData);
      initPayment(orderResponse.data.data);
      // console.log(orderResponse.message, "dfghjkl");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        alert(error.response.data.message);
        setIsSubmitting(false);
      } else {
        console.error("Error creating order", error);
        alert("There was an error processing your order. Please try again.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleApplyRewards = () => {
    if (rewardsToApply > totalCoins) {
      alert("You cannot apply more rewards than you have.");
    } else {
      setAppliedRewards(rewardsToApply);
    }
  };

  const handleRemovePackage = (index) => {
    const updatedPackages = [...selectedPackages];
    const removedPackage = updatedPackages.splice(index, 1)[0];
    setSelectedPackages(updatedPackages);
    setTotalAmount(totalAmount - removedPackage.discounted_price);
    resetCoupon();
  };

  const handleRemoveService = (index) => {
    const updatedServices = [...selectedServices];
    const removedService = updatedServices.splice(index, 1)[0];
    setSelectedServices(updatedServices);
    setTotalAmount(totalAmount - removedService.discounted_price);
    resetCoupon();
  };

  const resetCoupon = () => {
    setCouponCode("");
    setCouponDiscount(0);
    setCouponMessage("");
  };

  const handleApplyCoupon = async () => {
    if (!couponCode.trim()) {
      setCouponMessage("");
      setCouponDiscount(0);
      return true;
    }
    try {
      const response = await axios.get(
        `${BaseUrl}/validatecoupon/${couponCode}`
      );
      if (response.data.valid) {
        if (response.data.discountType === "percentage") {
          const discount = (totalAmount * response.data.discountValue) / 100;
          setCouponDiscount(discount);
          setCouponMessage(
            `Coupon applied successfully! You get ${response.data.discountValue}% off.`
          );
          return true;
        } else {
          setCouponDiscount(response.data.discountValue);
          setCouponMessage(
            `Coupon applied successfully! You get ₹${response.data.discountValue} off.`
          );
        }
        return true;
      } else {
        setCouponMessage(response.data.message);
        setCouponDiscount(0);
      }
      return false;
    } catch (error) {
      console.error("Error applying coupon:", error);
      setCouponMessage(
        "There was an error applying the coupon. Please try again."
      );
      setCouponDiscount(0);
      return false;
    }
  };

  const finalAmount = (totalAmount - appliedRewards - couponDiscount).toFixed(
    2
  );

  React.useEffect(() => {
    if (!isCouponApplied) {
      setCouponCode("");
    }
  }, [isCouponApplied]);

  return (
    <>
      <Header />
      {!isMobile && (
        <div className="mobile-view">
          <ChatWidget />
        </div>
      )}
      <div className="section content">
        <div className="container mt80">
        {isLoading && (
        <div className="overlay">
          <div className="overlay-content"><LoadingModal message={loadingMessage} /></div>
        </div>
      )}
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h4 className="font-weight-bold text-primary heading">Summary</h4>
            </div>
            <div className="col-lg-6 text-lg-end desktop-view">
              <button
                className="btn btn-primary text-white py-3 px-4"
                onClick={() => navigate(-1)}
              >
                Go Back
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-7">
              <h5 className="font-weight-bold" style={{color:'#feb406'}}>
                Selected Options
              </h5>
              <table className="table cardtbl mt-2">
                <tbody>
                  {selectedPackages.map((pkg, index) => (
                    <tr key={index}>
                      <td>
                        <span className="fw500">{pkg.name}</span>
                      </td>
                      <td className="text-danger">
                        <div style={{ textAlign: "right" }}>
                          <b
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemovePackage(index)}
                          >
                            Remove
                          </b>
                        </div>
                      </td>
                    </tr>
                  ))}
                  {selectedServices.map((service, index) => (
                    <tr key={index}>
                      <td>
                        <span className="fw500">{service.name}</span>
                      </td>
                      <td className="text-danger">
                        <div style={{ textAlign: "right" }}>
                          <b
                            style={{ cursor: "pointer" }}
                            onClick={() => handleRemoveService(index)}
                          >
                            Remove
                          </b>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4">
              <div
                className="card mt-3"
                style={{ background: "transparent", border: "1px solid white" }}
              >
                <div className="card-body">
                  <h5 className="font-weight-bold text-primary heading valign">
                    &nbsp;&nbsp;HSAS Services
                  </h5>
                  <hr />
                  <div>
                    <h6 className="text-white">Prices Details</h6>
                    <table className="table table-borderless cardtbl">
                      <tbody>
                        {selectedPackages.map((pkg, index) => (
                          <tr key={index}>
                            <td>{pkg.name}</td>
                            <td className="float-end">
                              <b>₹ {pkg.discounted_price}</b>
                            </td>
                          </tr>
                        ))}
                        {selectedServices.map((service, index) => (
                          <tr key={index}>
                            <td>{service.name}</td>
                            <td className="float-end">
                              <b>₹ {service.discounted_price}</b>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <hr className="mrb3" />
                  <div>
                    <h6 className="text-white">Apply Rewards</h6>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        gap: "10px",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                      }}
                    >
                      <span
                        className="text-white"
                        style={{
                          fontFamily: "Arial, sans-serif",
                          fontSize: "16px",
                          color: "#333",
                        }}
                      >
                        Available Rewards:
                        <span className="theme_yellow"> {totalCoins}</span>
                      </span>
                      <input
                        type="text"
                        value={rewardsToApply === 0 ? "" : rewardsToApply}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          setRewardsToApply(value === "" ? 0 : parseInt(value));
                        }}
                        style={{
                          width: "40%",
                          padding: "10px",
                          fontSize: "16px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          fontFamily: "Arial, sans-serif",
                          color: "#333",
                        }}
                      />

                      <button
                        onClick={handleApplyRewards}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#2a2e33",
                          color: "white",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontFamily: "Arial, sans-serif",
                          fontSize: "16px",
                          textDecoration: "none",
                          transition: "background-color 0.3s",
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.backgroundColor = "#2a2e33")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.backgroundColor = "#2a2e33")
                        }
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                  <hr className="mrb3" />
                  <div>
                    <h6 className="text-white">Apply Coupon</h6>
                    <div
                      className="d-flex justify-content-between align-items-center"
                      style={{
                        gap: "10px",
                        padding: "10px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                      }}
                    >
                      <input
                        type="text"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        placeholder="Enter coupon code"
                        style={{
                          width: "60%",
                          padding: "10px",
                          fontSize: "16px",
                          border: "1px solid #ccc",
                          borderRadius: "5px",
                          fontFamily: "Arial, sans-serif",
                          color: "#333",
                        }}
                      />
                      <button
                        onClick={handleApplyCoupon}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#2a2e33",
                          color: "white",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontFamily: "Arial, sans-serif",
                          fontSize: "16px",
                          textDecoration: "none",
                          transition: "background-color 0.3s",
                        }}
                        onMouseOver={(e) =>
                          (e.currentTarget.style.backgroundColor = "#2a2e33")
                        }
                        onMouseOut={(e) =>
                          (e.currentTarget.style.backgroundColor = "#2a2e33")
                        }
                      >
                        Apply
                      </button>
                    </div>
                    {couponMessage && (
                      <div style={{ marginTop: "10px", color: "#feb406" }}>
                        {couponMessage}
                      </div>
                    )}
                  </div>
                  <hr className="mrb3" />
                  <table className="table table-borderless cardtbl">
                    <tbody>
                      <tr>
                        <td>
                          <h5>Total amount</h5>
                        </td>
                        <td className="float-end">
                          <h5>₹ {totalAmount}</h5>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h5>Applied Rewards</h5>
                        </td>
                        <td className="float-end">
                          <h5>- ₹ {appliedRewards}</h5>
                        </td>
                      </tr>
                      {couponDiscount > 0 && (
                        <tr>
                          <td>
                            <h5>Coupon Discount</h5>
                          </td>
                          <td className="float-end">
                            <h5>- ₹ {couponDiscount}</h5>
                          </td>
                        </tr>
                      )}
                      <tr>
                        <td>
                          <h5>Final amount</h5>
                        </td>
                        <td className="float-end">
                          <h5>₹ {finalAmount}</h5>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="col-lg-12">
                    <button
                      className="btn btn-primary btn1 mr_remove col-lg-12"
                      onClick={handleProceedToPayment}
                      disabled={finalAmount <= 0 || isSubmitting}
                    >
                      {isSubmitting ? "Processing..." : `Pay ₹ ${finalAmount}`}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SelectedItemsSummary;
