import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { BaseUrl } from "./BaseUrl";
import backgroundImage from "./bg1.jpg";
import PostPPT from "./postppt.png";

const Features = () => {
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch data from the API
    axios
      .get(`${BaseUrl}/getallcategoriesinclientside`)
      .then((response) => {
        if (response.data.success) {
          setCategories(response.data.results);
        } else {
          console.error("Failed to fetch categories:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleShowClick = (id) => {
    navigate(`/legalservicepage/${id}`);
  };

  const handlePostClick = (path) => {
    const userId = localStorage.getItem("userId");
    const isMobile = window.innerWidth < 600;

    if (isMobile) {
      if (userId) {
        navigate(path);
      } else {
        navigate(`/mobilelogin?redirect=${path}`);
      }
    } else {
      // For larger screens, check if userId is present
      if (userId) {
        navigate(path);
      } else {
        navigate(`/userlogin`);
      }
    }
  };

  return (
    <section className="features-1" style={{ textAlign: "left!important" }}>
      <div className="container ">
        <div className="col-12 text-left">
          <h3 className="font-weight-bold heading text-left mb-4">
            Services We Offer
          </h3>
        </div>
        {/* <div>
          <Link to="/addproperties" className="btn mb-3" style={{color:'white', background:'#feb406', width:'99%', }}> 
            Post Your Property
          </Link>
        </div> */}
        <div className="row mt-2">
          <div className="col-4 col-lg-3" style={{ margin: "0px" }}>
            <a
              onClick={() => handlePostClick("/addproperties")}
              style={{ cursor: "pointer" }}
            >
              <div
                className="box-feature"
                style={{
                  background: "#000",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  marginBottom: "5px",
                  width: "100%",
                  padding: "5px",
                  marginBottom: "15px",
                }}
              >
                <img
                  src={PostPPT}
                  alt="Post Property"
                  className="category-image mb-3"
                  style={{
                    width: "70px",
                    height: "70px",
                    borderRadius: "50%",
                    objectFit: "cover",
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                />
                <p className="mb-3" style={{fontSize:'12px'}}>POST PROPERTY</p>
              </div>
            </a>
          </div>
          {categories.map((category) => (
            <div
              className="col-4 col-lg-3"
              key={category.id}
              style={{ margin: "0px" }}
            >
              <a
                onClick={() => handleShowClick(category.id)}
                style={{ cursor: "pointer" }}
              >
                <div
                  className="box-feature"
                  style={{
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    marginBottom: "5px",
                    width: "100%",
                    padding: "5px",
                    marginTop: "5px",
                  }}
                >
                  <img
                    src={category.category_image}
                    alt={category.name}
                    className="category-image mb-3"
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  />
                  <p
                    className="mb-3"
                    style={{
                      textTransform: "uppercase",
                      // width: '200px',
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "normal",
                      fontSize: "12px",
                    }}
                  >
                    {category.name}
                  </p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
