import React, { useState, useEffect } from "react";
import axios from "axios";
import { BaseUrl } from "./BaseUrl";
import Footer from "./Footer";
import Header from "./Header";
import { useNavigate } from "react-router-dom";

const SelectPackage = () => {
  const [packages, setPackages] = useState([]);
  const [services, setServices] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);
  const [selectedServices, setSelectedServices] = useState([]);

  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();

  const htmlToText = (html) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  useEffect(() => {
    // Fetch packages from the API
    axios
      .get(`${BaseUrl}/getallpackages`)
      .then((response) => {
        setPackages(response.data.results);
        // console.log(response.data.results);
      })
      .catch((error) => {
        console.error("There was an error fetching the packages!", error);
      });

    // Fetch services from the API
    axios
      .get(`${BaseUrl}/getallservices`)
      .then((response) => {
        setServices(response.data.results);
        // console.log(response.data.results);
      })
      .catch((error) => {
        console.error("There was an error fetching the services!", error);
      });
  }, []);

  const handleSelectPackage = (pkg) => {
    if (selectedPackages.find((p) => p.id === pkg.id)) {
      setSelectedPackages(selectedPackages.filter((p) => p.id !== pkg.id));
      setTotalAmount(totalAmount - pkg.discounted_price);
    } else {
      setSelectedPackages([...selectedPackages, { ...pkg, type: "package" }]);
      setTotalAmount(totalAmount + pkg.discounted_price);
    }
  };

  const handleSelectService = (service) => {
    if (selectedServices.find((s) => s.id === service.id)) {
      setSelectedServices(selectedServices.filter((s) => s.id !== service.id));
      setTotalAmount(totalAmount - service.discounted_price);
    } else {
      setSelectedServices([...selectedServices, { ...service, type: "service" }]);
      setTotalAmount(totalAmount + service.discounted_price);
    }
  };

  const handleContinue = () => {
    if (selectedPackages.length === 0 && selectedServices.length === 0) {
      // Optionally, you can show a message or handle this case differently
      // console.log("Please select at least one package or service.");
      alert("Please select at least one package or service.");
      return; // Prevent further execution
    }
    navigate("/summary", {
      state: {
        selectedPackages,
        selectedServices,
        totalAmount,
      },
    });
  };

  return (
    <>
      <Header />
      <div className="section">
        <div className="container mt80">
          <div className="row align-items-center">
            <div className="col-lg-6">

            </div>
            <div className="col-lg-6 text-lg-end">
             
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="card-body property">
                <ul className="nav nav-tabs" id="myTab">
                  <li className="nav-item">
                    <a
                      href="#packages"
                      className="nav-link active"
                      data-bs-toggle="tab"
                    >
                      Packages
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#services"
                      className="nav-link"
                      data-bs-toggle="tab"
                    >
                      Services
                    </a>
                  </li>
                </ul>
                <div className="tab-content mt-1">
                  <div className="tab-pane fade show active" id="packages">
                    <div className="crdcsrl">
                      <div className="row">
                        {packages.map((pkg, index) => (
                          <div className="col-lg-4" key={index}>
                            <div
                              className="card mt-3"
                              style={{ minHeight: "250px" }}
                            >
                              <div className="card-body">
                                <h6>{pkg.name}</h6>
                                <ul>
                                  {htmlToText(pkg.description)
                                    .split(",")
                                    .map((line, i) => (
                                      <li key={i} style={{ fontSize: "small" }}>
                                        <i className="fa fa-check"></i>{" "}
                                        {line.trim()}
                                      </li>
                                    ))}
                                </ul>
                                <div className="row mt-3">
                                  <div className="col-lg-8 align-items-center">
                                    <p>
                                      <del>&#8377;  {pkg.actual_price}</del> &nbsp;{" "}
                                      <b>&#8377; {pkg.discounted_price}</b>
                                    </p>
                                  </div>
                                  <div className="col-lg-4 text-md-end">
                                    <a
                                      href="#"
                                      className={`btn ${
                                        selectedPackages.find((p) => p.id === pkg.id)
                                          ? "btn-primary"
                                          : "btn_outline"
                                      } mr_remove`}
                                      onClick={() => handleSelectPackage(pkg)}
                                    >
                                      {selectedPackages.find((p) => p.id === pkg.id)
                                        ? "Remove"
                                        : "Select"}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="services">
                    <div className="crdcsrl2">
                      <div className="row">
                        {services.map((service, index) => (
                          <div className="col-lg-4" key={index}>
                            <div
                              className="card mt-3"
                              style={{ minHeight: "180px" }}
                            >
                              <div className="card-body">
                                <h6>{service.name}</h6>
                                <ul>
                                  {htmlToText(service.description)
                                    .split(",")
                                    .map((line, i) => (
                                      <li key={i} style={{ fontSize: "small" }}>
                                        <i className="fa fa-check"></i>{" "}
                                        {line.trim()}
                                      </li>
                                    ))}
                                </ul>
                                <div className="row mt-3">
                                  <div className="col-lg-8 align-items-center">
                                    <p>
                                      <del>&#8377;  {service.actual_price}</del> &nbsp;{" "}
                                      <b>&#8377;  {service.discounted_price}</b>
                                    </p>
                                  </div>
                                  <div className="col-lg-4 text-md-end">
                                    <a
                                      href="#"
                                      className={`btn ${
                                        selectedServices.find((s) => s.id === service.id)
                                          ? "btn-primary"
                                          : "btn_outline"
                                      } mr_remove`}
                                      onClick={() => handleSelectService(service)}
                                    >
                                      {selectedServices.find((s) => s.id === service.id)
                                        ? "Remove"
                                        : "Select"}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-lg-6 valign">
                      <h4 className="font-weight-bold text-primary heading valign">
                      &#8377;  {totalAmount}
                      </h4>
                    </div>
                    <div className="col-lg-6 text-lg-end">
                      <div className="col-lg-12 mt-4">
                        <a
                          href=""
                          className="btn btn-primary btn1 mr_remove col-lg-6"
                          onClick={handleContinue}
                        >
                          Continue
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SelectPackage;
