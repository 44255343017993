import React from 'react';
import bg from "./bg1.jpg";

const CompanyCopyright = () => {
  const style = {
    // backgroundImage: `url(${bg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    padding: '20px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: '8px',
    paddingBottom:'12vh'
  };

  return (
    <div style={style}>
      <p>
        Engineered by <a href="https://www.vyqda.com" target="_blank" rel="noopener noreferrer" style={{ color: '#feb406', textDecoration: 'underline' }}>Vyqda</a> Excellence
      </p>
    </div>
  );
};

export default CompanyCopyright;
