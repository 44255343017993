import axios from "axios";
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BaseUrl } from "./BaseUrl";
import { setUserDetails, clearUserDetails } from "../redux/actions/userActions";
import { Link, useNavigate } from "react-router-dom";
import NoProfileImages from "./userprofile.png";
import { Camera, CameraResultType } from "@capacitor/camera";
import occupations from "./Pages/occupations"

function ProfileContent() {
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const userDetails = useSelector((state) => state.user.userDetails);
  const [countries, setCountries] = useState([]);
  const [selectedCountryIso2, setSelectedCountryIso2] = useState("");
  const [selectedStateIso2, setSelectedStateIso2] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerificationDisabled, setIsVerificationDisabled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [profileImage, setProfileImage] = useState(NoProfileImages);
  const isMobile = window.innerWidth <= 600;
  const [basicInfo, setBasicInfo] = useState({
    city: "",
    state: "",
    country: "",
  });

  const [profileData, setProfileData] = useState({
    name: "",
    mobile: "",
    city: "",
    state: "",
    country: "",
    budget: "",
    gender: "",
    occupation: "",
    userTypes: [],
  });

  const userId = localStorage.getItem("userId");

  // console.log(profileData.mobile, "profile data state");

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Fetch user data and populate the form
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `${BaseUrl}/getUserNameInHeader/${userId}`
        );
        const data = response.data;

        // Update basicInfo and profileData
        setBasicInfo({
          country: data.users.country,
          state: data.users.state,
          city: data.users.city,
        });

        setProfileData({
          name: data.users.name,
          mobile: data.users.mobile,
          city: data.users.city,
          state: data.users.state,
          country: data.users.country,
          budget: data.users.budget,
          gender: data.users.gender,
          occupation: data.users.occupation,
          userTypes: data.users.usertype?.split(",").map(Number) || [],
        });
        const imageResponse = await axios.get(
          `${BaseUrl}/getmyprofileimage/${userId}`
        );
        setProfileImage(imageResponse.data.results || NoProfileImages);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [userId, BaseUrl]);

  useEffect(() => {
    if (basicInfo.country) {
      setBasicInfo((prevInfo) => ({ ...prevInfo, state: "" }));
    }
  }, [basicInfo.country]);

  const handleSignOut = () => {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    dispatch(clearUserDetails());
    navigate("/");
  };

  const deleteAccount = async () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to delete your account?"
    );

    if (!userConfirmed) {
      return;
    }

    try {
      const userId = localStorage.getItem("userId");
      const response = await axios.put(`${BaseUrl}/deleteaccount`, {
        id: userId,
      });
      setMessage("Account deleted successfully.");
      handleSignOut();
    } catch (error) {
      console.error(error);
      setMessage("An error occurred while deleting the account.");
    }
  };

  const [userTypes, setUserTypes] = useState({
    1: false,
    2: false,
    3: false,
    // 4: false,
    // 5: false,
    // 6: false,
    // 7: false,
  });

  useEffect(() => {
    if (userDetails) {
      const userTypesArray = userDetails.usertype?.split(",").map(Number) || [];
      const updatedUserTypes = { ...userTypes };
      userTypesArray.forEach((type) => {
        updatedUserTypes[type] = true;
      });
      setUserTypes(updatedUserTypes);
    }
  }, [userDetails]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileData({ ...profileData, [name]: value });
  };

  useMemo(() => {
    if (countries.length) {
      const result = countries.find(
        (country) => country.name === basicInfo.country
      );
      // console.log(result);
      setSelectedCountryIso2(result.iso2 || "");
    }
  }, [countries, basicInfo.country]);

  useMemo(() => {
    if (states.length) {
      // console.log("states", states);
      // console.log(basicInfo.city);
      const result = states.find((state) => state.name === profileData.state);
      // console.log("rsult", result);
      setSelectedStateIso2(result?.iso2 || "");
    }
  }, [states, basicInfo.state]);

  useEffect(() => {
    const loadCountries = () => {
      axios
        .get("https://api.countrystatecity.in/v1/countries", {
          headers: {
            "X-CSCAPI-KEY":
              "NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==",
          },
        })
        .then((response) => {
          setCountries(response.data);
          // console.log(response.data);
        })
        .catch((error) => {
          console.error("Error loading countries:", error);
        });
    };

    loadCountries();
  }, []);

  const onChangeCountryHandler = (e) => {
    const selectedCountry = e.target.value;
    const country = countries.find(
      (country) => country.name === selectedCountry
    );
    if (country) {
      setProfileData((prev) => ({
        ...prev,
        country: country.name,
        state: "", // Reset state when country changes
        city: "", // Reset city when country changes
      }));
      setSelectedCountryIso2(country.iso2);
      // loadStates(country.iso2);
    }
  };

  useEffect(() => {
    if (selectedCountryIso2) loadStates(selectedCountryIso2);
  }, [selectedCountryIso2]);

  // Fetch states based on selected country
  const loadStates = (iso2) => {
    axios
      .get(`https://api.countrystatecity.in/v1/countries/${iso2}/states`, {
        headers: {
          "X-CSCAPI-KEY":
            "NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==",
        },
      })
      .then((response) => {
        // console.log("response state", response.data);
        setStates(response.data || []);
      })
      .catch((error) => {
        console.error("Error loading states:", error);
      });
  };

  // Handle state selection
  const onChangeStateHandler = (e) => {
    const selectedState = e.target.value;
    const state = states.find((state) => state.name === selectedState);
    if (state) {
      setProfileData((prev) => ({
        ...prev,
        state: state.name,
        city: "",
      }));
      setSelectedStateIso2(state.iso2);
    }
  };

  // Fetch cities based on selected state and country
  const loadCities = (countryIso2, stateIso2) => {
    // console.log(
    //   `Loading cities for country: ${countryIso2}, state: ${stateIso2}`
    // );
    axios
      .get(
        `https://api.countrystatecity.in/v1/countries/${countryIso2}/states/${stateIso2}/cities`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "NHhvOEcyWk50N2Vna3VFTE00bFp3MjFKR0ZEOUhkZlg4RTk1MlJlaA==",
          },
        }
      )
      .then((response) => {
        // console.log("Cities loaded successfully:", response.data);
        setCities(response.data);
      })
      .catch((error) => {
        console.error("Error loading cities:", error);
      });
  };

  useEffect(() => {
    if (selectedCountryIso2 && selectedStateIso2)
      // console.log(
      //   "useEffect triggered with:",
      //   selectedCountryIso2,
      //   selectedStateIso2
      // );
    loadCities(selectedCountryIso2, selectedStateIso2);
  }, [selectedCountryIso2, selectedStateIso2]);

  const onChangeCityHandler = (e) => {
    const selectedCity = e.target.value;
    const city = cities.find((city) => city.name === selectedCity);
    if (city) {
      setProfileData((prev) => ({
        ...prev,
        city: city.name,
      }));
      // setSelectedStateIso2(state.iso2);
    }
  };

  const handleEmailVerification = async () => {
    try {
      const userId = localStorage.getItem("userId");
      const email = userDetails.email;

      if (!userId || !email) {
        throw new Error("User ID or email is missing");
      }
      setIsSubmitting(true);

      const response = await axios.post(`${BaseUrl}/requestverification`, {
        userId: userId,
        email: email,
      });

      alert(response.data.message);
    } catch (error) {
      console.error("Error during email verification request:", error);
      alert("Error to send email verification request");
      setIsVerificationDisabled(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUserTypeChange = (event) => {
    const userType = parseInt(event.target.value);
    const updatedUserTypes = { ...userTypes, [userType]: !userTypes[userType] };

    setUserTypes(updatedUserTypes);

    const userTypesArray = Object.keys(updatedUserTypes)
      .filter((key) => updatedUserTypes[key])
      .map((key) => parseInt(key));

    setProfileData({ ...profileData, userTypes: userTypesArray });
  };

  const handleGenderChange = (event) => {
    const { value } = event.target;
    setProfileData({ ...profileData, gender: value });
  };

  const handleProfessionChange = (event) => {
    setProfileData({
      ...profileData,
      occupation: event.target.value
    });
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    // console.log("profileData to submit:", profileData);
    try {
      const response = await axios.put(
        `${BaseUrl}/editprofile/${userDetails.email}`,
        profileData
      );
      // console.log("Response from server:", response.data);
      alert(response.data.message);
      dispatch(setUserDetails(response.data.user));
    } catch (error) {
      console.error("Error during profile update:", error);
    }
  };

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("profile_image", file);

    try {
      const response = await axios.put(
        `${BaseUrl}/editprofileimage/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      setProfileImage(response.data.profileImage);
      alert(response.data.message);
    } catch (error) {
      console.error("Error uploading image:", error);
      alert(
        error.response?.data?.message ||
          "Error uploading image. Please try again."
      );
    }
  };

  const takePicture = async () => {
    try {
      const photo = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
      });
      const fileUrl = photo.webPath;

      // Fetch the file blob from the URL
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      // Create a FormData object to send the image file
      const formData = new FormData();
      formData.append("profile_image", blob, "profile_image.jpg");

      const uploadResponse = await axios.put(
        `${BaseUrl}/editprofileimage/${userId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setProfileImage(uploadResponse.data.profileImage);
      alert(uploadResponse.data.message); // Display the backend message as an alert
    } catch (error) {
      console.error("Error taking picture:", error);
      alert(error.response?.data?.message || "Please Select Image");
    }
  };

  // console.log(profileData.country, "last profiledata country");
  // console.log(profileData.state, "last profiledata state");
  // console.log(basicInfo.country, "last profiledata state");
  // console.log(basicInfo.state, "last profiledata state");

  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      content.classList.add('slide-in-right');
    }
  }, []);

  return (
    <div className="section content">
      <div className="container mt120">
        <div className="row mb-4 align-items-center">
          <div className="col-lg-6">
            <h4 className="font-weight-bold text-primary heading">
              Manage your Account
            </h4>
          </div>
        </div>
        <div className="row mb-5">
          <div class="col-lg-3 d-none d-lg-block d-md-block">
            <ul className="list-group tabcss">
              <li className="list-group-item active">
                <Link to="/profile">Basic Profile</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/shortlisted-properties">
                  Your Shortlisted Properties
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/your-payments"> Your Payments</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/yourproperties"> Your Properties</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/interested-yourproperties">
                  {" "}
                  Interested in your Properties
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/rewards"> Your Rewards</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/tickets"> Your Support Tickets</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/referral"> Referral</Link>
              </li>
            </ul>
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              <button className="postbtn btn" onClick={deleteAccount}>
                Delete Account
              </button>
            </div>
          </div>

          <div class="mb-4 d-md-none d-lg-none" style={{ textAlign: "right" }}>
            <div class="dropdown-center">
              <button
                class="btn btn-primary btn1 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fa fa-user"></i> Basic Profile
              </button>
              <ul class="dropdown-menu">
                <li>
                  <Link
                    class="dropdown-item"
                    to="/profile/shortlisted-properties"
                  >
                    Your Shortlisted Properties
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/your-payments">
                    Your Payments
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/yourproperties">
                    Your Properties
                  </Link>
                </li>
                <li>
                  <Link
                    class="dropdown-item"
                    to="/profile/interested-yourproperties"
                  >
                    {" "}
                    Interested in your Properties
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/rewards">
                    Your Rewards
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/tickets">
                    Your Support Tickets
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/referral">
                    Referral
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-9">
            <h6 className="font-weight-bold text-primary mb-3">
              Edit Your Profile
            </h6>

            <div className="profile-image-section mb-3">
              <img
                src={profileImage}
                alt="Profile"
                style={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  border: "2px solid #feb406",
                  objectFit: "cover",
                }}
              />
              <div
                className="edit-icon"
                style={{
                  position: "relative",
                  top: "-30px",
                  left: "70px",
                  cursor: "pointer",
                }}
              >
                {isMobile ? (
                  <i
                    className="fa fa-pencil"
                    style={{ fontSize: "24px", color: "#feb406" }}
                    onClick={takePicture}
                  ></i>
                ) : (
                  <>
                    <label htmlFor="file-input">
                      <i
                        className="fa fa-pencil"
                        style={{ fontSize: "24px", color: "#feb406" }}
                      ></i>
                    </label>
                    <input
                      id="file-input"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                  </>
                )}
              </div>
            </div>

            <form onSubmit={handleEdit}>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder={userDetails?.name}
                    value={profileData.name}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label>Email Address</label>
                  <input
                    type="email"
                    className="form-control mb-2"
                    value={userDetails?.email}
                    readOnly
                  />
                  {userDetails?.emailVerified === 0 && (
                    <div
                      style={{
                        display: "inline-block",
                        padding: "2px 5px",
                        backgroundColor: "rgb(54 56 58)",
                        color: "white",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontFamily: "Arial, sans-serif",
                        fontSize: "14px",
                        textAlign: "center",
                        textDecoration: "none",
                        transition: "background-color 0.3s",
                      }}
                      disabled={isVerificationDisabled}
                      onClick={
                        isVerificationDisabled ? null : handleEmailVerification
                      }
                      // onClick={handleEmailVerification}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          "rgb(54 56 58)")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          "rgb(54 56 58)")
                      }
                    >
                      Click here to generate email verification mail.
                    </div>
                  )}
                </div>
                <div className="col-md-6 mb-3">
                  <label>Mobile Phone</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="mobile"
                    placeholder={userDetails?.mobile}
                    value={profileData.mobile}
                    onChange={handleInputChange}
                  />
                  {/* <div className="mt-1 themeclr">
                    <input type="checkbox" className="form-check-input" /> Get
                    Updates on WhatsApp
                  </div> */}
                </div>
                {/* <div className="col-md-6 mb-3">
                  <label>City</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="city"
                    placeholder={userDetails?.city}
                    value={profileData.city}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label>State</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="state"
                    placeholder={userDetails?.state}
                    value={profileData.state}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <label>Country</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="country"
                    placeholder={userDetails?.country}
                    value={profileData.country}
                    onChange={handleInputChange}
                  />
                </div> */}

                <div className="col-md-6 mb-3">
                  <label>Select Country:</label>
                  <select
                    name="country"
                    className="form-select form-control "
                    value={profileData.country}
                    onChange={onChangeCountryHandler}
                    required
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.iso2} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-6 mb-3">
                  <label>Select State:</label>
                  <select
                    name="state"
                    className="form-select form-control"
                    value={profileData.state}
                    onChange={onChangeStateHandler}
                    required
                    disabled={!profileData.country}
                  >
                    <option value="">Select State</option>
                    {states.map((state) => (
                      <option key={state.name} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6 mb-3">
                  <label>Select City:</label>
                  <select
                    name="city"
                    className="form-select form-control"
                    value={profileData.city}
                    // onChange={(e) =>
                    //   setProfileData((prev) => ({
                    //     ...prev,
                    //     city: e.target.value,
                    //   }))
                    // }
                    // required
                    onChange={onChangeCityHandler}
                    disabled={!profileData.state}
                  >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                      <option key={city.name} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="col-md-6 mb-3">
                  <label>Profession</label>
                  <select
                    className="form-control form-select "
                    id="occupation"
                    name="occupation"
                    // placeholder={userDetails?.occupation}
                    onChange={handleProfessionChange}
                    value={profileData.occupation}
                  >
                    <option value="" disabled>
                      Profession
                    </option>
                    {occupations.map((occupation, index) => (
          <option key={index} value={occupation}>
            {occupation}
          </option>
        ))}
                  </select>
                </div>
                {/* <div className="col-md-6 mb-3">
                  <label>Budget</label>
                  <input
                    type="text"
                    className="form-control mb-2"
                    name="budget"
                    placeholder={userDetails?.budget}
                    value={profileData.budget}
                    onChange={handleInputChange}
                  />
                </div> */}
                <div className="col-md-6 mb-3">
                  <label>Gender</label>
                  <select
                    className="form-control form-select"
                    id="gender"
                    name="gender"
                    placeholder={userDetails?.gender}
                    onChange={handleGenderChange}
                    value={profileData.gender}
                  >
                    <option value="" disabled>
                      Gender
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
                <div className="col-12 mb-3">
                  <h6 className="hd">Post Property as : </h6>
                  {Object.entries(userTypes)
                    .filter(([key, value]) => key !== "0" && value !== "0")
                    .map(([key, value]) => (
                      <div className="form-check form-check-inline" key={key}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="usertype"
                          value={key}
                          checked={value}
                          onChange={handleUserTypeChange}
                        />
                        <label className="form-check-label">
                          {key === "1" && "Agent"}
                          {key === "2" && "Owner"}
                          {key === "3" && "Builders or Developers"}
                          {/* {key === "1" && "Seller"}
                          {key === "2" && "Buyer"}
                          {key === "3" && "Owner To Rent"}
                          {key === "4" && "Owner To Lease"}
                          {key === "5" && "Looking For Rental"}
                          {key === "6" && "Looking For Lease"}
                          {key === "7" && "Others"} */}
                        </label>
                      </div>
                    ))}
                </div>

                <div
                  className="col-6 mt-4"
                  style={{
                    marginTop: "1rem",
                    marginBottom: "1rem",
                    width: "70%",
                  }}
                >
                  <Link
                    to="/resetpassword"
                    className="themeclr"
                    // target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: "inline-block",
                      padding: "5px 10px",
                      // backgroundColor: "rgb(33 33 34)",
                      color: "#feb406",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontFamily: "Arial, sans-serif",
                      fontSize: "14px",
                      textAlign: "center",
                      textDecoration: "none",
                      transition: "background-color 0.3s",
                    }}
                    // onMouseOver={(e) =>
                    //   (e.currentTarget.style.backgroundColor = "rgb(33 33 34)")
                    // }
                    // onMouseOut={(e) =>
                    //   (e.currentTarget.style.backgroundColor = "rgb(33 33 34)")
                    // }
                  >
                    Click here to Update Password!
                  </Link>
                </div>

                <div col-12 style={{ textAlign: "center" }}>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{ fontSize:'18px', padding:'10px' }}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </form>

            {screenWidth <= 600 && (
              <div style={{ display: "flex", justifyContent: "space-between", marginTop:'50px' }}>
                <button className="postbtn btn" onClick={deleteAccount}>
                  Delete Account
                </button>
                <button
                  className=" btn"
                  onClick={handleSignOut}
                  style={{ background: "red", color: "white" }}
                >
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileContent;
