import React, { useEffect, useState } from "react";
import axios from "axios";
import { BaseUrl } from "./BaseUrl";

function Footer() {
  const [footerData, setFooterData] = useState({
    contact: {
      address: "",
      phone: "",
      email1: "",
    },
    socialLinks: {
      instagram: "",
      twitter: "",
      facebook: "",
      linkedin: "",
      youtube: "",
    },
  });

  useEffect(() => {
    axios
      .get(`${BaseUrl}/getallsettingdetailsinclient`)
      .then((response) => {
        const { results } = response.data;
        setFooterData({
          contact: {
            address: results.address,
            phone: results.official_phone,
            email1: results.official_email,
          },
          socialLinks: {
            instagram: results.instagram_url,
            twitter: results.twitter_url,
            facebook: results.facebook_url,
            linkedin: results.linkedin_url,
            youtube: results.youtube_url,
          },
        });
      })
      .catch((error) => {
        console.error("Error fetching footer data:", error);
      });
  }, []);

  // Get current year
  const currentYear = new Date().getFullYear();

  return (
    <div>
      <div className="site-footer">
        <div className="container" style={{}}>
          <div className="row">
            <div className="col-lg-4">
              <div className="widget">
                <h3>Contact</h3>
                <address>{footerData.contact.address}</address>
                <ul className="list-unstyled links">
                  <li>
                    <a href={`tel://${footerData.contact.phone}`}>
                      {footerData.contact.phone}
                    </a>
                  </li>
                  <li>
                    <a href={`mailto:${footerData.contact.email1}`}>
                      {footerData.contact.email1}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="widget">
                <h3>Sources</h3>
                <ul className="list-unstyled float-start links">
                  <li>
                    <a href="/about">About us</a>
                  </li>
                  <li>
                    <a href="/legalservices">Services</a>
                  </li>
                  <li>
                    <a href="/terms-condition">Terms</a>
                  </li>
                  <li>
                    <a href="/privacy">Privacy</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="widget">
                <h3>Links</h3>
                <ul className="list-unstyled links">
                  <li>
                    <a href="#">Our Vision</a>
                  </li>
                  <li>
                    <a href="/about">About us</a>
                  </li>
                  <li>
                    <a href="/contact">Contact us</a>
                  </li>
                </ul>
                <ul className="list-unstyled social">
                  {footerData.socialLinks.instagram && (
                    <li>
                      <a
                        href={footerData.socialLinks.instagram}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="icon-instagram" />
                      </a>
                    </li>
                  )}
                  {footerData.socialLinks.twitter && (
                    <li>
                      <a
                        href={footerData.socialLinks.twitter}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="icon-twitter" />
                      </a>
                    </li>
                  )}
                  {footerData.socialLinks.facebook && (
                    <li>
                      <a
                        href={footerData.socialLinks.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="icon-facebook" />
                      </a>
                    </li>
                  )}
                  {footerData.socialLinks.linkedin && (
                    <li>
                      <a
                        href={footerData.socialLinks.linkedin}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="icon-linkedin" />
                      </a>
                    </li>
                  )}
                  {footerData.socialLinks.youtube && (
                    <li>
                      <a
                        href={footerData.socialLinks.youtube}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className="icon-youtube" />
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center" style={{display:'flex', justifyContent:'space-between'}}>
              <p className="mb-0" style={{ marginRight: "55px" }}>
                Copyright &copy; Hemanth Services and Solutions {currentYear}{" "}
                All Rights Reserved.
              </p>
              <p className="mb-0">
              Engineered by <a href="https://vyqda.com/" target="_blank" style={{color:'#feb406'}}>Vyqda
                </a> Excellence
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
