import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { BaseUrl } from "./BaseUrl";

function YourPayments() {
  const [payments, setPayments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    if (userId) {
      axios
        .get(`${BaseUrl}/getpayments/${userId}`)
        .then((response) => {
          if (response.data.results) {
            setPayments(response.data.results);
          }
        })
        .catch((error) => {
          console.error("There was an error fetching the payments!", error);
        });
    } else {
      console.error("No user ID found in local storage.");
    }
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(payments.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentPayments = payments.slice(startIndex, startIndex + itemsPerPage);

  const renderPagination = () => {
    if (totalPages === 0 || totalPages === 1) {
      return null; // Don't render pagination if there's only one page or no payments
    }

    const visiblePages = 2; // Number of visible page links
    let startPage = currentPage - Math.floor(visiblePages / 2);
    startPage = Math.max(startPage, 1);
    const endPage = Math.min(startPage + visiblePages - 1, totalPages);

    const pageNumbers = [];
    if (startPage > 1) {
      pageNumbers.push(1); // Always show the first page
      if (startPage > 2) {
        pageNumbers.push("..."); // Show ellipsis if not adjacent to the first page
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push("..."); // Show ellipsis if not adjacent to the last page
      }
      pageNumbers.push(totalPages); // Always show the last page
    }

    // const isMobile = window.innerWidth <= 600;

    return (
      <ul
        style={{
          display: "flex",
          justifyContent: "center",
          listStyleType: "none",
          padding: 0,
        }}
      >
        <li>
          <button
            style={{ marginRight: "10px" }}
            disabled={currentPage === 1}
            onClick={() => handlePageChange(currentPage - 1)}
            className="btn btn-primary"
          >
            Prev
          </button>
        </li>
        {pageNumbers.map((pageNumber, index) => (
          <li key={index}>
            {pageNumber === "..." ? (
              <span style={{ margin: "0 5px" }}>...</span>
            ) : (
              <button
                style={{
                  margin: "0 5px",
                  padding: "5px 10px",
                  cursor: "pointer",
                  border: "1px solid #ddd",
                  borderRadius: "4px",
                  backgroundColor:
                    pageNumber === currentPage ? "black" : "transparent",
                  color: pageNumber === currentPage ? "white" : "#fff",
                }}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            )}
          </li>
        ))}
        <li>
          <button
            style={{ marginLeft: "10px" }}
            disabled={currentPage === totalPages}
            onClick={() => handlePageChange(currentPage + 1)}
            className="btn btn-primary"
          >
            Next
          </button>
        </li>
      </ul>
    );
  };

  const isMobile = window.innerWidth <= 600;

  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      content.classList.add('slide-in-right');
    }
  }, []);

  return (
    <>
      <Header />
      <div className="section content">
        <div className="container mt120">
          <div className="row mb-4 align-items-center">
            <div className="col-lg-6">
              <h4 className="font-weight-bold text-primary heading">
                Your Payment Details
              </h4>
            </div>
          </div>

          <div className="row mb-5">
            <div
              className="col-lg-3 d-none d-lg-block d-md-block"
              style={{ display: isMobile ? "none" : "block" }}
            >
              <ul className="list-group tabcss">
                <li className="list-group-item">
                  <Link to="/profile">Basic Profile</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/shortlisted-properties">
                    Your Shortlisted Properties
                  </Link>
                </li>
                <li className="list-group-item active">
                  <Link to="/profile/your-payments">Your Payments</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/yourproperties">Your Properties</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/interested-yourproperties">
                    Interested in your Properties
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/rewards">Your Rewards</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/tickets">Your Support Tickets</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/referral">Referral</Link>
                </li>
              </ul>
            </div>

            <div
              className="mb-4 d-md-none d-lg-none"
              style={{ textAlign: "right", display: isMobile ? "block" : "none" }}
            >
              <div className="dropdown-center">
                <button
                  className="btn btn-primary btn1 dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-credit-card"></i> Your Payments
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/profile">
                      Basic Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/profile/shortlisted-properties"
                    >
                      Your Shortlisted Properties
                    </Link>
                  </li>
                  {/* <li>
                    <Link className="dropdown-item" to="/profile/your-payments">
                      Your Payments
                    </Link>
                  </li> */}
                  <li>
                    <Link className="dropdown-item" to="/profile/yourproperties">
                      Your Properties
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item"
                      to="/profile/interested-yourproperties"
                    >
                      Interested in your Properties
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/rewards">
                      Your Rewards
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/tickets">
                      Your Support Tickets
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/referral">
                      Referral
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-9">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h6 className="font-weight-bold text-primary mb-3">
                  Your Payment Details
                </h6>
                <Link
                  to="/profile/refunds"
                  className="btn btn-primary theme_yellow"
                >
                  Refund Details
                </Link>
              </div>
              <div style={{ overflowX: "auto" }}>
                <table
                  className="table"
                  style={{
                    background: "transparent",
                    color: "white",
                    display: isMobile ? "none" : "table",
                  }}
                >
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Payment Date</th>
                      <th scope="col">Payment Amount</th>
                      <th style={{ width: "25%" }}>Payment ID</th>
                      <th style={{ width: "20%" }}>Invoice</th>
                      <th scope="col">Status</th>
                      <th style={{ width: "15%" }}>Service Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPayments.length > 0 ? (
                      currentPayments.map((payment, index) => (
                        <tr key={payment.id}>
                          <th scope="row">{startIndex + index + 1}</th>
                          <td>
                            {new Date(payment.timestamp).toLocaleDateString()}
                          </td>
                          <td>{payment.payment_amount}</td>
                          <td>{payment.payment_id}</td>
                          <td>
                            {payment.invoice_name ? (
                              <a
                                href={payment.invoice_name}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fa fa-download"></i>&nbsp;
                                {payment.invoice_number}
                              </a>
                            ) : (
                              payment.invoice_number
                            )}
                          </td>
                          <td>{payment.status}</td>
                          <td>{payment.service_names}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No payments found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {/* Mobile view table */}
                <div
                  style={{
                    display: isMobile ? "block" : "none",
                    borderBottom: "1px solid #ddd",
                    padding: "10px",
                  }}
                >
                  {currentPayments.length > 0 ? (
                    currentPayments.map((payment, index) => (
                      <div
                        key={payment.id}
                        style={{
                          borderBottom: "1px solid #ddd",
                          padding: "10px",
                        }}
                      >
                        <div style={{ marginBottom: "5px" }}>
                          <strong>#</strong>: {startIndex + index + 1}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <strong>Payment Date</strong>: {new Date(payment.timestamp).toLocaleDateString()}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <strong>Payment Amount</strong>: {payment.payment_amount}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <strong>Payment ID</strong>: {payment.payment_id}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <strong>Status</strong>: {payment.status}
                        </div>
                        <div style={{ marginBottom: "5px" }}>
                          <strong>Invoice</strong>: {payment.invoice_name ? (
                            <a
                              href={payment.invoice_name}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fa fa-download"></i>&nbsp;
                              {payment.invoice_number}
                            </a>
                          ) : (
                            payment.invoice_number
                          )}
                        </div>
                       
                        <div style={{ marginBottom: "5px" }}>
                          <strong>Service Name</strong>: {payment.service_names}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="text-center">No payments found.</div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                {renderPagination()}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default YourPayments;
