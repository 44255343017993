import React from "react";

function LegalServicesBanner() {
  return (
    // <div
    //   className="hero page-inner overlay"
    //   style={{ backgroundImage: 'url("images/hero_bg_3.jpg")' }}
    // >
      <div className="container mt120">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-9 text-center mt-5">
            <h1 className="heading"
            //  data-aos="fade-up"
             >
              Services
            </h1>
            <nav
              aria-label="breadcrumb"
            //   data-aos="fade-up"
              data-aos-delay={200}
            >
              <ol className="breadcrumb text-center justify-content-center">
                <li className="breadcrumb-item">
                  <a href="index.html">Home</a>
                </li>
                <li
                  className="breadcrumb-item active text-white-50"
                  aria-current="page"
                >
                  Services
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    // </div>
  );
}

export default LegalServicesBanner;
