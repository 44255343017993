import React, { useState, useEffect, useRef } from "react";
import plus from "./dashboard.png";
import cross from "./cross.png"; // import the cross image
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clearUserDetails } from "../redux/actions/userActions";
import LoginModal from "./MobileLoginModal";
import { faSignInAlt, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isPlatform } from "@ionic/react";  

const MobileFooter = () => {
  const userDetails = useSelector((state) => state.user.userDetails);
  const dispatch = useDispatch();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showBottomModal, setShowBottomModal] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const navigate = useNavigate();

  const bottomModalRef = useRef(null);
  const propertyModalRef = useRef(null);

  const handleCloseLoginModal = () => setShowLoginModal(false);
  const handleShowLoginModal = () => setShowLoginModal(true);

  const handleSignOut = () => {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    dispatch(clearUserDetails());
    navigate("/");
  };

  const toggleBottomModal = () => {
    setShowPropertyModal(false); // Close property modal if open
    setShowBottomModal((prev) => !prev);
  };

  const handleCloseBottomModal = () => {
    setShowBottomModal(false); // Close bottom modal
  };

  const togglePropertyModal = () => {
    setShowBottomModal(false); // Close bottom modal if open
    setShowPropertyModal((prev) => !prev); // Toggle property modal
  };

  const handleLoginClick = (path) => {
    navigate(`/mobilelogin?redirect=${path}`);
    setShowBottomModal(false);
    setShowPropertyModal(false);
  };

  const handleCloseModalAndNavigate = (path) => {
    const userId = localStorage.getItem("userId");
    setShowBottomModal(false);
    if (userId) {
      navigate(path);
    } else {
      navigate(`/mobilelogin?redirect=${path}`);
    }
  };

  const handleCloseModalAndNavigate1 = (path) => {
    const userId = localStorage.getItem("userId");
    setShowPropertyModal(false);
    if (userId) {
      navigate(path);
    } else {
      navigate(`/mobilelogin?redirect=${path}`);
    }
  };

  const handleReferralClick = (path) => {
    handleCloseModalAndNavigate(path);
    handleCloseModalAndNavigate1(path);
  };

  const handleClickOutside = (event) => {
    if (
      bottomModalRef.current &&
      !bottomModalRef.current.contains(event.target)
    ) {
      handleCloseBottomModal(); // Use handleCloseBottomModal function
    }
    if (
      propertyModalRef.current &&
      !propertyModalRef.current.contains(event.target)
    ) {
      setShowPropertyModal(false);
    }
  };

  useEffect(() => {
    if (showBottomModal || showPropertyModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showBottomModal, showPropertyModal]);

  useEffect(() => {
    if (isPlatform("ios")) {
      setIsIOS(true);
    }
  }, []);

  console.log(isIOS,"plaeform")


  return (
    <div>
      <div className="footerdiv">
        <div className={`row ${isIOS ? "ios-footer" : ""}`} style={{margin:'0px auto'}}>
          <div className="col">
            <Link to="/" className="active">
              <p className="">
                <i className="fa fa-home fa-2x"></i>
              </p>
              <span>Home</span>
            </Link>
          </div>
          <div className="col">
            <Link to="/legalservices" className="active">
              <p className="">
                <i className="fa fa-cart-plus fa-2x"></i>
              </p>
              <span>Service</span>
            </Link>
          </div>
          <div className="col">
            <a className="active" onClick={toggleBottomModal}>
              <p>
                <img
                  src={plus}
                  alt="Plus"
                  className="plus"
                  style={{ display: showBottomModal ? "none" : "" }}
                />
              </p>
              </a>
              <a>
              <p>
                <img
                onClick={handleCloseBottomModal}
                  src={cross}
                  alt="close"
                  className="plus"
                  style={{ display: showBottomModal ? "" : "none" }}
                />
              </p>
            </a>
          </div>
          <div className="col">
            <a
              className="active"
              onClick={togglePropertyModal}
              style={{ cursor: "pointer" }}
            >
              <p className="">
                <i className="fa fa-building fa-2x"></i>
              </p>
              <span>Property</span>
            </a>
          </div>
          <div className="col">
            <a
              className="active"
              onClick={() => handleReferralClick("/profile/referral")}
              style={{ cursor: "pointer" }}
            >
              <p className="">
                <i className="fa fa-user-plus fa-2x"></i>
              </p>
              <span>Referral</span>
            </a>
          </div>
        </div>
      </div>
      
      {(showBottomModal || showPropertyModal) && (
        <div className="backdrop"></div>
      )}

      {showBottomModal && (
        <div
          className="modal modal-bottom fade show"
          style={{ display: "block" }}
          role="dialog"
        >
          <div className="modal-dialog mobilemdl" role="document">
            <div className="modal-content" ref={bottomModalRef}>
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowBottomModal(false)}
                  aria-label="Close"
                  style={{ fontSize: "23px" }}
                ></button>
              </div>
              <div className="modal-body">
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "13vh",
                  }}
                >
                  <div className="col"></div>
                  <div className="" style={{ marginRight: "11vh" }}>
                    <div className="">
                      <div className="row">
                        <div className="col-10">
                          <div
                            className="card-body"
                            style={{ width: "fit-content" }}
                          >
                            {userDetails?.name ? (
                              <li
                                className="nav-item dropdown"
                                style={{
                                  listStyle: "none",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <a
                                  className="nav-link dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{
                                    background: "#feb406",
                                    padding: "16px",
                                    borderRadius: "8px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  <i
                                    className="fa fa-user"
                                    style={{ marginRight: "5px" }}
                                  ></i>{" "}
                                  {userDetails.name}
                                </a>

                                <ul
                                  className="dropdown-menu"
                                  style={{ marginLeft: "30px" }}
                                >
                                  <li style={{ textAlign: "center" }}>
                                    <a
                                      onClick={handleSignOut}
                                      className=""
                                      style={{ color: "#000" }}
                                    >
                                      Sign Out
                                    </a>
                                  </li>
                                </ul>
                              </li>
                            ) : (
                              <>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    className="col"
                                    style={{ flex: 1, textAlign: "center" }}
                                  >
                                    <div
                                      className="card-body"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <a
                                        className="loginbtn"
                                        onClick={() => handleLoginClick("/")}
                                        style={{
                                          cursor: "pointer",
                                          textDecoration: "none",
                                          color: "#007bff",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faSignInAlt}
                                          style={{
                                            fontSize: "1em",
                                            marginRight: "0.5em",
                                          }}
                                        />
                                        Login
                                      </a>
                                    </div>
                                  </div>

                                  <div
                                    className="col"
                                    style={{ flex: 1, textAlign: "center" }}
                                  >
                                    <div
                                      className="card-body"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Link
                                        to="/mobilesignup"
                                        className="loginbtn"
                                        style={{
                                          textDecoration: "none",
                                          color: "#007bff",
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faUserPlus}
                                          style={{
                                            fontSize: "1em",
                                            marginRight: "0.5em",
                                          }}
                                        />
                                        SignUp
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col"></div>
                </div>

                <div className="row mt-4">
                  <div className="col-6">
                    <a
                      onClick={() => handleCloseModalAndNavigate("/profile")}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="proboxdv">
                        <div className="row">
                          <div className="col-2 valign">
                            <i className="fa fa-user"></i>
                          </div>
                          <div className="col-10">
                            <div className="card-body">
                              <a className="mb-0" style={{ color: "#fff" }}>
                                PROFILE
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      onClick={() =>
                        handleCloseModalAndNavigate("/profile/your-payments")
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div className="proboxdv">
                        <div className="row">
                          <div className="col-2 valign">
                            <i className="fa fa-credit-card"></i>
                          </div>
                          <div className="col-10">
                            <div className="card-body">
                              <a className="mb-0" style={{ color: "#fff" }}>
                                PAYMENTS
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className="col-6">
                    <div className="proboxdv">
                      <a
                        onClick={() =>
                          handleCloseModalAndNavigate("/profile/yourproperties")
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="row">
                          <div className="col-2 valign">
                            <i className="fa fa-code"></i>
                          </div>
                          <div className="col-10">
                            <div className="card-body">
                              <a className="mb-0" style={{ color: "#fff" }}>
                                LISTINGS
                              </a>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="proboxdv">
                      <a
                        onClick={() =>
                          handleCloseModalAndNavigate("/profile/tickets")
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div className="row">
                          <div className="col-2 valign">
                            <i className="fa fa-ticket"></i>
                          </div>
                          <div className="col-10">
                            <div className="card-body">
                              <a className="mb-0" style={{ color: "#fff" }}>
                                SUPPORT
                              </a>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showPropertyModal && (
        <div
          className="modal modal-bottom fade show"
          style={{ display: "block" }}
          role="dialog"
        >
          <div
            className="modal-dialog mobilemdl"
            role="document"
            ref={propertyModalRef}
          >
            <div className="modal-content">
              <div className="modal-header">
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowPropertyModal(false)}
                  aria-label="Close"
                  style={{ fontSize: "23px" }}
                ></button>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-3"></div>
                  <div className="col-6">
                    <div className="">
                      <div className="">
                        <div className="col-10">
                          <div className="card-body" style={{ width: "16em" }}>
                            <a
                              // href="#"
                              className=""
                              onClick={() =>
                                handleCloseModalAndNavigate1("/addproperties")
                              }
                              style={{
                                color: "#fff",
                                background: "#feb406",
                                padding: "15px",
                                borderRadius: "10px",
                                cursor: "pointer",
                              }}
                            >
                              Add Properties
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3"></div>
                </div>

                <div className="row mt-4">
                  <div className="col-6">
                    <a
                      onClick={() =>
                        handleCloseModalAndNavigate1(
                          "/profile/interested-yourproperties"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="proboxdv"
                        style={{ background: "#feb406" }}
                      >
                        <div className="row">
                          <div className="col-2 valign">
                            <i className="fa fa-bookmark"></i>
                          </div>
                          <div className="col-10">
                            <div className="card-body">
                              <a
                                // href="#"
                                className=""
                                style={{
                                  color: "#fff",
                                  background: "#feb406",
                                  // padding: "15px",
                                  borderRadius: "10px",
                                }}
                              >
                                Instrested
                              </a>
                              {/* <h6 className="mb-0">Instrested</h6> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div className="col-6">
                    <a
                      onClick={() =>
                        handleCloseModalAndNavigate1(
                          "/profile/shortlisted-properties"
                        )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className="proboxdv"
                        style={{ background: "#feb406" }}
                      >
                        <div className="row">
                          <div className="col-2 valign">
                            <i className="fa fa-heart"></i>
                          </div>
                          <div className="col-10">
                            <div className="card-body">
                              <a
                                // href="#"
                                className=""
                                style={{
                                  color: "#fff",
                                  background: "#feb406",
                                  // padding: "15px",
                                  borderRadius: "10px",
                                }}
                              >
                                Favourite
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <LoginModal show={showLoginModal} handleClose={handleCloseLoginModal} />
    </div>
  );
};

export default MobileFooter;
