import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { FaShareAlt } from 'react-icons/fa'; // Importing share icon
import Header from "../Header";
import Footer from "../Footer";
import { BaseUrl } from "../BaseUrl";
import { Share } from '@capacitor/share';

function Referral() {
  const [referralCode, setReferralCode] = useState("");
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    
    axios.get(`${BaseUrl}/getreferralcodeanduserslist/${userId}`)
      .then((response) => {
        if (response.data && response.data.referralcode && response.data.users) {
          setReferralCode(response.data.referralcode.referral_code);
          setUsers(response.data.users);
        } else {
          console.error("No referral code or users found in response:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching referral code and users:", error);
      });
  }, []);

  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      content.classList.add('slide-in-right');
    }
  }, []);

  const handleShare = async () => {
    const dynamicLink = `https://hsasgroup.com/`;

    try {
      await Share.share({
        title: 'Check out this app!',
        text: `Download this amazing app and use my referral code: ${referralCode}`,
        url: dynamicLink,
        dialogTitle: 'Share with friends'
      });
    } catch (error) {
      console.error('Error sharing:', error);
      alert(`Share this link: ${dynamicLink}`);
    }
  };

  return (
    <>
      <Header />
      <div className="section content">
        <div className="container mt120">
          <div className="row mb-4 align-items-center">
            <div className="col-lg-6">
              <h4 className="font-weight-bold text-primary heading">
                Referral Code and Referred Users
              </h4>
            </div>
            <div className="col-lg-6 text-lg-end">
              <button className="btn btn-primary" onClick={handleShare}>
                <FaShareAlt /> Share App
              </button>
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-lg-3 d-none d-lg-block d-md-block">
              <ul className="list-group tabcss">
                <li className="list-group-item">
                  <Link to="/profile">Basic Profile</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/shortlisted-properties">
                    Your Shortlisted Properties
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/your-payments">Your Payments</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/yourproperties">Your Properties</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/interested-yourproperties">
                    Interested in your Properties
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/rewards">Your Rewards</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/tickets">Your Support Tickets</Link>
                </li>
                <li className="list-group-item active">
                  <Link to="/profile/referral">Referral</Link>
                </li>
              </ul>
            </div>

            <div className="mb-4 d-md-none d-lg-none" style={{ textAlign: "right" }}>
              <div className="dropdown-center">
                <button
                  className="btn btn-primary btn1 dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa fa-user-plus"></i> Referral
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/profile">Basic Profile</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/shortlisted-properties">Your Shortlisted Properties</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/your-payments">Your Payments</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/yourproperties">Your Properties</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/interested-yourproperties">Interested in your Properties</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/rewards">Your Rewards</Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/profile/tickets">Your Support Tickets</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-9">
              <h6 className="font-weight-bold text-primary mb-3">
                Your Referral Code: <span className="theme_yellow">{referralCode}</span>
              </h6>
              <div style={{ overflowX: "auto" }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Referral;
