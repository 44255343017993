import React, { useState } from 'react';

const properties = [
  {
    id: 1,
    imgSrc: 'images/img_1.jpg',
    price: '$1,291,000',
    address: '5232 California Fake, Ave. 21BC',
    city: 'California, USA',
    beds: '2 beds',
    baths: '2 baths'
  },
  {
    id: 2,
    imgSrc: 'images/img_2.jpg',
    price: '$1,291,000',
    address: '5232 California Fake, Ave. 21BC',
    city: 'California, USA',
    beds: '2 beds',
    baths: '2 baths'
  },
  {
    id: 3,
    imgSrc: 'images/img_3.jpg',
    price: '$1,291,000',
    address: '5232 California Fake, Ave. 21BC',
    city: 'California, USA',
    beds: '2 beds',
    baths: '2 baths'
  },
  {
    id: 4,
    imgSrc: 'images/img_4.jpg',
    price: '$1,291,000',
    address: '5232 California Fake, Ave. 21BC',
    city: 'California, USA',
    beds: '2 beds',
    baths: '2 baths'
  },
  {
    id: 5,
    imgSrc: 'images/img_5.jpg',
    price: '$1,291,000',
    address: '5232 California Fake, Ave. 21BC',
    city: 'California, USA',
    beds: '2 beds',
    baths: '2 baths'
  },
  {
    id: 6,
    imgSrc: 'images/img_6.jpg',
    price: '$1,291,000',
    address: '5232 California Fake, Ave. 21BC',
    city: 'California, USA',
    beds: '2 beds',
    baths: '2 baths'
  },
  {
    id: 7,
    imgSrc: 'images/img_7.jpg',
    price: '$1,291,000',
    address: '5232 California Fake, Ave. 21BC',
    city: 'California, USA',
    beds: '2 beds',
    baths: '2 baths'
  },
  {
    id: 8,
    imgSrc: 'images/img_8.jpg',
    price: '$1,291,000',
    address: '5232 California Fake, Ave. 21BC',
    city: 'California, USA',
    beds: '2 beds',
    baths: '2 baths'
  },
];

const PropertySlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevProperty = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? properties.length - 1 : prevIndex - 1));
  };

  const nextProperty = () => {
    setCurrentIndex((prevIndex) => (prevIndex === properties.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="section">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div id="propertyCarousel" className="carousel slide property-slider-wrap" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className="row">
                    {properties.slice(currentIndex, currentIndex + 3).map(property => (
                      <div key={property.id} className="col-md-4 col-lg-4 property-item mb-4">
                        <a href="property-single.html" className="img">
                          <img src={property.imgSrc} alt="Image" className="img-fluid" />
                        </a>
                        <div className="property-content">
                          <div className="price mb-2"><span>{property.price}</span></div>
                          <div>
                            <span className="d-block mb-2">{property.address}</span>
                            <span className="city d-block mb-3">{property.city}</span>
                            <div className="specs d-flex mb-4">
                              <span className="d-block d-flex align-items-center me-3">
                                <span className="icon-bed me-2"></span>
                                <span className="caption">{property.beds}</span>
                              </span>
                              <span className="d-block d-flex align-items-center">
                                <span className="icon-bath me-2"></span>
                                <span className="caption">{property.baths}</span>
                              </span>
                            </div>
                            <a href="property-single.html" className="btn btn-primary py-2 px-3">See details</a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" onClick={prevProperty}>
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" onClick={nextProperty}>
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <button className="btn btn-outline-primary mx-1" onClick={prevProperty}>Prev</button>
            <button className="btn btn-outline-primary mx-1" onClick={nextProperty}>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertySlider;
