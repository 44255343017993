import React from "react";
import Footer from "../Footer";
import ContactUsContent from "../ContactUsContent";
import ContactUsBanner from "../ContactUsBanner";
import Header from "../Header";

function Contact() {
  return (
    <>
      <Header />
      <ContactUsBanner />
      <ContactUsContent />
      <Footer />
    </>
  );
}

export default Contact;
