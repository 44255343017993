import React from "react";
import Footer from "../Footer";
import ProfileContent from "../ProfileContent";
import Header from "../Header";

function Profile() {
  return (
    <>
      <Header/>
      <ProfileContent />
      <Footer />
    </>
  );
}

export default Profile;
