import React, { useEffect, useState } from "react";
import { BaseUrl } from "./BaseUrl";
import axios from "axios";
import MobileSearch from "./MobileSearch";
import NotificationComponent from "./MobileNotification";
import { Link, useNavigate } from "react-router-dom";
import { isPlatform } from "@ionic/react";  

const MobileHeader = () => {
  const [logoUrl, setLogoUrl] = useState("");
  const [logoError, setLogoError] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIOS, setIsIOS] = useState(false); // New state for platform detection
  const navigate = useNavigate();

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const userId = localStorage.getItem("userId");

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const response = await axios.get(`${BaseUrl}/getlogodetails`);
        if (response.data.success) {
          setLogoUrl(response.data.results.logo);
        }
      } catch (error) {
        console.error("Error fetching logo:", error);
      }
    };

    fetchLogo();

    // Detect if the platform is iOS and set the CSS accordingly
    if (isPlatform("ios")) {
      setIsIOS(true);
    }

  }, []);

  // console.log(isIOS,"plaeform")

  const handleWalletClick = () => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      navigate("/profile/rewards");
    } else {
      alert("Login to Show Your Rewards");
    }
  };

  return (
    <nav className='site-nav mobilenav'>
      <div className="container">
        <div className={`menu-bg-wrap ${isIOS ? "ios-nav" : ""}`}>
          <div
            className="site-navigation"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Link to="/" style={{ marginRight: "2px" }}>
              {logoUrl && !logoError ? (
                <img
                  src={logoUrl}
                  alt="Property Icon"
                  onLoad={() => console.log("Logo loaded successfully")}
                  onError={(e) => {
                    console.error("Error loading logo:", e);
                    setLogoError(true);
                  }}
                  style={{
                    maxWidth: "100px",
                    maxHeight: "50px",
                    width: "100px",
                    height: "50px",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100px",
                    height: "50px",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "white",
                  }}
                >
                  HSAS
                </div>
              )}
            </Link>

            <ul
              className="js-clone-nav text-start site-menu float-end"
              style={{ marginTop: "-2px", paddingLeft: "0px" }}
            >
              <li style={{ marginRight: "-23px" }}>
                <a
                  href="#"
                  onClick={openModal}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                  >
                    <input
                      type="search"
                      placeholder="Search property"
                      style={{
                        border: "1px solid #ccc",
                        borderRadius: "40px",
                        padding: "6px 2px 6px 27px",
                        fontSize: "12px",
                        width: "131px",
                        outline: "none",
                        color: "#333",
                      }}
                    />
                    <i
                      className="fa fa-search"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "5px",
                        transform: "translateY(-50%)",
                        fontSize: "18px",
                        color: "#333",
                      }}
                    ></i>
                  </div>
                </a>
              </li>

              <li style={{ marginRight: "-15px" }}>
                <a>
                  <NotificationComponent userId={userId} />
                </a>
              </li>
              <li style={{ marginRight: "-15px" }}>
                <a onClick={handleWalletClick} style={{ cursor: "pointer" }}>
                  <i className="fa fa-trophy" style={{ fontSize: "18px" }}></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <MobileSearch isModalOpen={isModalOpen} closeModal={closeModal} />
    </nav>
  );
};

export default MobileHeader;
