import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import { BaseUrl } from "../BaseUrl";
import LoginModal from "../LoginModal";
import ChatWidget from "../ChatWidget";
import ImageNotFound from "../Image_not_available.png";
import { useSelector } from "react-redux";
import { Browser } from "@capacitor/browser";
import Modal from './Modal';
import { isPlatform } from "@ionic/react";  
import ExpertAgent from "./ExpertAgent";
import CompanyCopyright from "../CompanyCopyright";

const LegalService = () => {
  const { id } = useParams();
  const userDetails = useSelector((state) => state.user.userDetails);
  const email = useSelector((state) => state.user.userDetails.email);
  const city = useSelector((state) => state.user.userDetails.city);
  const [data, setData] = useState([]);
  const [selectedServices, setSelectedServices] = useState({});
  const [expandedCategory, setExpandedCategory] = useState(null);
  const navigate = useNavigate();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  const handleCloseLoginModal = () => setShowLoginModal(false);
  const handleShowLoginModal = () => setShowLoginModal(true);

  // console.log(userDetails, "redux user details");

  // Update isMobile on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };

    
  }, []);

 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BaseUrl}/getservicesandassociatedsubcategory/${id}`
        );
        const result = await response.json();
        if (result.success) {
          setData(result.results);
          initializeSelections(result.results);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    if (isPlatform("ios")) {
      setIsIOS(true);
    }
  }, [id]);

  // console.log(isIOS,"plaeform")s

  useEffect(() => {
    const content = document.querySelector(".content");
    if (content) {
      content.classList.add("slide-in-right");
    }
  }, []);

  useEffect(() => {
    const handleBrowserFinished = () => {
      setIsModalVisible(true);
    };

    Browser.addListener('browserFinished', handleBrowserFinished);

    return () => {
      Browser.removeAllListeners();
    };
  }, [navigate]);

  const handleConfirm = () => {
    setIsModalVisible(false);
    navigate('/profile/tickets');
  };

  const handleClose = () => {
    setIsModalVisible(false);
    navigate('/');
  };

  const initializeSelections = (fetchedData) => {
    const initialSelections = {};
    fetchedData.forEach((group) => {
      initialSelections[group.subcategory_name] = [];
    });
    setSelectedServices(initialSelections);
  };

  const handleServiceSelect = (category, serviceIndex) => {
    const updatedSelection = { ...selectedServices };
    if (
      updatedSelection[category] &&
      updatedSelection[category].includes(serviceIndex)
    ) {
      updatedSelection[category] = updatedSelection[category].filter(
        (idx) => idx !== serviceIndex
      );
    } else {
      updatedSelection[category] = updatedSelection[category]
        ? [...updatedSelection[category], serviceIndex]
        : [serviceIndex];
    }
    setSelectedServices(updatedSelection);
  };

  const handleCardSelect = (category) => {
    const updatedSelection = { ...selectedServices };
    const categoryServices = data.find(
      (item) => item.subcategory_name === category
    ).services;
    const isSelected =
      updatedSelection[category]?.length === categoryServices.length;

    if (isSelected) {
      updatedSelection[category] = [];
    } else {
      updatedSelection[category] = categoryServices.map((_, index) => index);
    }

    setSelectedServices(updatedSelection);
  };

  const calculateTotalPrice = (category) => {
    let total = 0;
    const services =
      selectedServices[category]?.map(
        (index) =>
          data.find((item) => item.subcategory_name === category)?.services[
            index
          ]
      ) || [];
    services.forEach((service) => {
      if (service) {
        total += service.discounted_price;
      }
    });
    return total;
  };

  const handlePopState = () => {
    if (window.Tawk_API && typeof window.Tawk_API.maximize === "function") {
      window.Tawk_API.maximize();
    } else {
      console.warn("Tawk_API or Tawk_API.maximize is not available.");
    }
  };

  window.addEventListener("popstate", handlePopState);

  const handleContinueButtonClick = async () => {
    const selectedItems = Object.keys(selectedServices).flatMap((category) =>
      selectedServices[category]?.map((index) => ({
        ...data.find((item) => item.subcategory_name === category)?.services[
          index
        ],
      }))
    );

    if (selectedItems.length === 0) {
      alert("Please select at least one service.");
      return;
    }

    const userId = localStorage.getItem("userId");
    if (!userId) {
      handleShowLoginModal();
      return;
    }

    if (!city) {
      alert("Please Enter Address [ City, State, Country ]");
      navigate("/profile");
      return;
    }

    const totalAmount = selectedItems.reduce(
      (total, service) => total + service.discounted_price,
      0
    );

    const isMobile = window.innerWidth <= 768;

    const selectedServicesParam = encodeURIComponent(
      JSON.stringify(selectedItems)
    );
    const url = `https://hsasgroup.com/itemsummary?services=${selectedServicesParam}&total=${totalAmount}&city=${city}&userId=${userId}&email=${email}`;

    if (isMobile) {
      await Browser.open({ url });
    } else {
      navigate("/summary", {
        state: {
          selectedServices: selectedItems,
          totalAmount: totalAmount,
        },
      });
    }
  };

  const handleCategoryExpand = (category) => {
    setExpandedCategory(expandedCategory === category ? null : category);
  };

  return (
    <>
      <Header />
      {!isMobile && (
        <div className="mobile-view">
          <ChatWidget />
        </div>
      )}


      <div className="desktop-view" style={{ marginTop: "130px" }}>
        <div className="container mt-5">
          <div className="row">
            {data.map((group, groupIndex) => (
              <div
                key={groupIndex}
                className="col-sm-12 col-md-6 col-lg-6 mb-4"
              >
                <div
                  // className="card"
                  style={{
                    width: "100%",
                    margin: "0 auto",
                    // backgroundColor: "#d7f7f6",
                    border: "1px solid #ccc",
                    borderRadius: "10px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <div
                    // className="card-header"
                    id={`heading${groupIndex}`}
                    style={{
                      // backgroundColor: "#008080",
                      border: "1px solid white",
                      color: "white",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                      paddingTop:
                        expandedCategory === group.subcategory_name
                          ? "10px"
                          : "10px",
                      transition: "padding-top 0.6s ease-in-out",
                    }}
                  >
                    <h2 className="mb-0">
                      <button
                        className="btn btn-link btn-block text-left"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#collapse${groupIndex}`}
                        aria-expanded={
                          expandedCategory === group.subcategory_name
                        }
                        aria-controls={`collapse${groupIndex}`}
                        style={{
                          color: "white",
                          fontFamily: "sans-serif",
                          fontSize: "18px",
                          fontWeight: "600",
                          textDecoration: "none",
                        }}
                        onClick={() =>
                          handleCategoryExpand(group.subcategory_name)
                        }
                      >
                        {expandedCategory !== group.subcategory_name && (
                          <div
                            style={{
                              textAlign: "center",
                              marginBottom: "10px",
                            }}
                          >
                            <img
                              src={group.subcategory_image || ImageNotFound}
                              // alt={
                              //   group.subcategory_name || " "
                              // }
                              style={{
                                maxWidth: "100%",
                                height: "auto",
                                borderRadius: "5px",
                                width: "600px",
                                height: "200px",
                              }}
                            />
                          </div>
                        )}

                        {group.subcategory_name}
                      </button>
                    </h2>
                  </div>
                  <div
                    id={`collapse${groupIndex}`}
                    className={`collapse ${
                      expandedCategory === group.subcategory_name ? "show" : ""
                    }`}
                    aria-labelledby={`heading${groupIndex}`}
                    data-bs-parent="#servicesAccordion"
                  >
                    <div
                      className="card-body"
                      style={{
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        maxHeight: "40vh",
                        overflowY: "auto",
                        padding: "10px 20px 10px 20px",
                      }}
                    >
                      {group.services.map((item, index) => (
                        <div
                          key={index}
                          className="mb-3"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <input
                            type="checkbox"
                            onChange={() =>
                              handleServiceSelect(group.subcategory_name, index)
                            }
                            checked={
                              selectedServices[
                                group.subcategory_name
                              ]?.includes(index) || false
                            }
                            style={{ marginRight: "10px" }}
                          />
                          <div>
                            <h5
                              style={{
                                // margin: "0 0 5px 0",
                                fontSize: "16px",
                                fontWeight: "bold",
                                // color: "#333",
                              }}
                            >
                              {item.name} -
                              {item.actual_price !== item.discounted_price ? (
                                <>
                                  <del>₹{item.actual_price}</del>&nbsp; ₹
                                  {item.discounted_price}
                                </>
                              ) : (
                                `₹${item.discounted_price}`
                              )}
                            </h5>

                            <p
                              style={{
                                margin: "0",
                                fontSize: "14px",
                                // color: "#666",
                              }}
                            >
                              {item.description}
                            </p>
                          </div>
                        </div>
                      ))}
                      <div style={{ textAlign: "right" }}>
                        <h5
                          style={{
                            fontSize: "16px",
                            fontWeight: "bold",
                            // color: "#333",
                          }}
                        >
                          Total Price: ₹
                          {calculateTotalPrice(group.subcategory_name)}
                        </h5>
                        <button
                          className="btn btn-secondary"
                          onClick={() =>
                            handleCardSelect(group.subcategory_name)
                          }
                          style={{
                            // backgroundColor: "#008080",
                            borderColor: "#008080",
                            fontWeight: "bold",
                            // marginTop: "10px",
                          }}
                        >
                          {selectedServices[group.subcategory_name]?.length ===
                          group.services.length
                            ? "Deselect All"
                            : "Select All"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div
            style={{
              textAlign: "center",
              marginTop: "20px",
              position: "relative",
              marginBottom: "145px",
              marginTop: "55px",
            }}
          >
            <button
              className="btn btn-primary"
              onClick={handleContinueButtonClick}
              style={{
                // backgroundColor: "#088395",
                borderColor: "#008080",
                fontWeight: "bold",
                fontSize: "20px",
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-110%)",
                padding: "10px 20px",
                borderRadius: "5px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                // margin:"55px",
                marginBottom: "55px !important",
              }}
            >
              <i
                className="fas fa-arrow-right"
                style={{ marginRight: "10px" }}
              ></i>
              Continue
            </button>
          </div>
          <ExpertAgent id={id} />

        </div>
      </div>

      {isMobile && (

        
        <div className="mobile-view content">
          <div style={{ marginTop: "100px" }} className={`${isIOS ? "ios-service" : ""}`} >
            <div className="container mt-5">
              <div className="row">
                {data.map((group, groupIndex) => (
                  <div
                    key={groupIndex}
                    className="col-sm-12 col-md-6 col-lg-6 mb-4"
                  >
                    <div
                      // className="card"
                      style={{
                        width: "100%",
                        // margin: "0 auto",
                        // backgroundColor: "#d7f7f6",
                        border: "1px solid #ccc",
                        borderRadius: "10px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div
                        // className="card-header"
                        id={`heading${groupIndex}`}
                        style={{
                          // backgroundColor: "#008080",
                          border: "1px solid white",
                          color: "white",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          borderRadius: "10px",
                          // paddingTop:
                          //   expandedCategory === group.subcategory_name
                          //     ? "0px"
                          //     : "0px",
                          // transition: "padding-top 0.6s ease-in-out",
                        }}
                      >
                        <h2 className="mb-0">
                          <button
                            className="btn btn-link btn-block text-left"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#collapse${groupIndex}`}
                            aria-expanded={
                              expandedCategory === group.subcategory_name
                            }
                            aria-controls={`collapse${groupIndex}`}
                            style={{
                              color: "white",
                              fontFamily: "sans-serif",
                              fontSize: "18px",
                              fontWeight: "600",
                              textDecoration: "none",
                            }}
                            onClick={() =>
                              handleCategoryExpand(group.subcategory_name)
                            }
                          >
                            {expandedCategory !== group.subcategory_name && (
                              <div
                                style={{
                                  textAlign: "center",
                                  marginBottom: "10px",
                                }}
                              >
                                <img
                                  src={group.subcategory_image || ImageNotFound}
                                  // alt={
                                  //   group.subcategory_name || " "
                                  // }
                                  style={{
                                    maxWidth: "100%",
                                    height: "auto",
                                    borderRadius: "5px",
                                    width: "600px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            )}

                            {group.subcategory_name}
                          </button>
                        </h2>
                      </div>
                      <div
                        id={`collapse${groupIndex}`}
                        className={`collapse ${
                          expandedCategory === group.subcategory_name
                            ? "show"
                            : ""
                        }`}
                        aria-labelledby={`heading${groupIndex}`}
                        data-bs-parent="#servicesAccordion"
                      >
                        <div
                          className="card-body"
                          style={{
                            borderBottomLeftRadius: "10px",
                            borderBottomRightRadius: "10px",
                            maxHeight: "40vh",
                            overflowY: "auto",
                            padding: "10px 20px 10px 20px",
                            borderRadius: "10px",
                          }}
                        >
                          {group.services.map((item, index) => (
                            <div
                              key={index}
                              className="mb-3"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <input
                                type="checkbox"
                                onChange={() =>
                                  handleServiceSelect(
                                    group.subcategory_name,
                                    index
                                  )
                                }
                                checked={
                                  selectedServices[
                                    group.subcategory_name
                                  ]?.includes(index) || false
                                }
                                style={{ marginRight: "10px" }}
                              />
                              <div>
                                <h5
                                  style={{
                                    // margin: "0 0 5px 0",
                                    fontSize: "16px",
                                    fontWeight: "bold",
                                    // color: "#333",
                                  }}
                                >
                                  {item.name} -
                                  {item.actual_price !==
                                  item.discounted_price ? (
                                    <>
                                      <del>₹{item.actual_price}</del>&nbsp; ₹
                                      {item.discounted_price}
                                    </>
                                  ) : (
                                    `₹${item.discounted_price}`
                                  )}
                                </h5>

                                <p
                                  style={{
                                    margin: "0",
                                    fontSize: "14px",
                                    // color: "#666",
                                  }}
                                >
                                  {item.description}
                                </p>
                              </div>
                            </div>
                          ))}
                          <div style={{ textAlign: "right" }}>
                            <h5
                              style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                // color: "#333",
                              }}
                            >
                              Total Price: ₹
                              {calculateTotalPrice(group.subcategory_name)}
                            </h5>
                            <button
                              className="btn btn-secondary"
                              onClick={() =>
                                handleCardSelect(group.subcategory_name)
                              }
                              style={{
                                // backgroundColor: "#008080",
                                borderColor: "#008080",
                                fontWeight: "bold",
                                // marginTop: "10px",
                              }}
                            >
                              {selectedServices[group.subcategory_name]
                                ?.length === group.services.length
                                ? "Deselect All"
                                : "Select All"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  position: "relative",
                  marginBottom: "145px",
                  marginTop: "55px",
                }}
              >
                <button
                  className="btn btn-primary"
                  onClick={handleContinueButtonClick}
                  style={{
                    // backgroundColor: "#088395",
                    borderColor: "#008080",
                    fontWeight: "bold",
                    fontSize: "20px",
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-110%)",
                    padding: "10px 20px",
                    borderRadius: "5px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                    // margin:"55px",
                    marginBottom: "55px !important",
                  }}
                >
                  <i
                    className="fas fa-arrow-right"
                    style={{ marginRight: "10px" }}
                  ></i>
                  Continue
                </button>
              </div>
              <ExpertAgent id={id} />
            </div>
          </div>
          {/* <div className="mobile-view">
      <CompanyCopyright/>
      </div> */}
        </div>
      )}

      <LoginModal show={showLoginModal} handleClose={handleCloseLoginModal} />
      <Footer />

      {isModalVisible && (
        <Modal
          message="Have you made the payment?"
          onConfirm={handleConfirm}
          onClose={handleClose}
        />
      )}
    </>
  );
};

export default LegalService;
