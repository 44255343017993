import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const Invoice = () => {
  const downloadPDF = () => {
    const input = document.getElementById('invoice');
    html2canvas(input, { scale: 2 }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save("invoice.pdf");
    });
  };

  return (
    <div>
      <style>{`
        table, td, th {
          line-height: 1.7;
          padding: 8px 4px;
          border: 1px solid #c7c6c6;
        }
        table {
          border-collapse: collapse;
          width: 100%;
          font-weight: 400;
          color: #000;
          font-size: 13px;
          font-family: Arial;
        }
        th {
          text-align: left;
        }
        .container {
          width: 210mm; /* A4 width */
          height: 297mm; /* A4 height */
          padding: 10mm;
          background-color: rgb(238,238,238);
          font-family: Arial;
        }
      `}</style>
      <div id="invoice" className="container">
        <table align="center" cellspacing="0">
          <tbody>
            <tr>
              <td align="center" style={{ background: '#f8f9fa', border: '1px solid #e4e4e4', padding: '25px' }}>
                <table style={{ border: '1px solid #c7c6c6' }} className="center table">
                  <thead>
                    <tr style={{ borderTop: '1px solid #c7c6c6', textAlign: 'center' }}>
                      <td colSpan="12"><img src="https://www.strunkmedia.com/wp-content/uploads/2018/05/bigstock-Print-163213010.png" className="img-rounded" alt="iwcn" style={{ width: '150px' }} /></td>
                    </tr>
                    <tr>
                      <th colSpan="12" style={{ textAlign: 'center', border: '0px solid #c7c6c6' }}>Company Name : XYZ Sample Name <br />GSTIN : 09AENPG4716L1Z4<br /> Invoice Number: INV0001122<br />Date: 26/05/2023</th>
                    </tr>
                    <tr style={{ borderTop: '1px solid #c7c6c6' }}>
                      <td colSpan="6" style={{ textAlign: 'left', border: '0px solid #c7c6c6' }}><b><u>Details of Receiver (Billed to)</u></b><br />Name  : Sample<br />Address :  H- Block, New Delhi- 110045, New Delhi, Delhi<br />Phone Number : xxxxxxxxxx<br />Contact Person :</td>
                      <td colSpan="6" style={{ textAlign: 'right' }}><b><u>Details of Consignee (Shipped to)</u></b><br />Name  :  xyz Bala<br />Address : H- Block, New Delhi- 110045, New Delhi, Delhi<br />Phone Number : xxxxxxxxxx<br />Contact Person :</td>
                    </tr>
                  </thead>
                </table>
                <table>
                  <tbody>
                    <tr>
                      <td rowSpan="2" style={{ width: '5%', border: '1px solid #c7c6c6' }}><b>S.No.</b></td>
                      <td rowSpan="2" style={{ width: '30%', border: '1px solid #c7c6c6' }}><b>Description of Goods</b></td>
                      <td rowSpan="2" style={{ width: '5%', border: '1px solid #c7c6c6' }}><b>Qty</b></td>
                      <td rowSpan="2" style={{ width: '10%', border: '1px solid #c7c6c6' }}><b>Unit</b></td>
                      <td rowSpan="2" style={{ width: '5%', border: '1px solid #c7c6c6' }}><b>Rate</b></td>
                      <td rowSpan="2" style={{ width: '10%', border: '1px solid #c7c6c6' }}><b>Discount</b></td>
                      <td rowSpan="2" style={{ width: '10%', border: '1px solid #c7c6c6' }}><b>Taxable Value</b></td>
                      <td colSpan="2" style={{ width: '20%', border: '1px solid #c7c6c6' }}><b>IGST</b></td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid #c7c6c6' }}><b>Rate</b></td>
                      <td style={{ border: '1px solid #c7c6c6' }}><b>Amount</b></td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid #c7c6c6' }}>1</td>
                      <td style={{ border: '1px solid #c7c6c6' }}>COLOR</td>
                      <td style={{ border: '1px solid #c7c6c6' }}>400</td>
                      <td style={{ border: '1px solid #c7c6c6' }}>Kg</td>
                      <td style={{ border: '1px solid #c7c6c6' }}>254</td>
                      <td style={{ border: '1px solid #c7c6c6' }}>0.00</td>
                      <td style={{ border: '1px solid #c7c6c6' }}>10,000</td>
                      <td style={{ border: '1px solid #c7c6c6' }}>18.00%</td>
                      <td style={{ border: '1px solid #c7c6c6' }}>18,000</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid #c7c6c6', textAlign: 'right', fontWeight: 'bold' }} colSpan="6">Basic Value Total &nbsp;&nbsp;</td>
                      <td style={{ fontWeight: 'bold', border: '1px solid #c7c6c6' }}>101,696</td>
                      <td style={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid #c7c6c6' }}>GST Total</td>
                      <td style={{ fontWeight: 'bold', border: '1px solid #c7c6c6' }}>18,305</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid #c7c6c6' }} colSpan="7"></td>
                      <td colSpan="1" style={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid #c7c6c6' }}>TOTAL</td>
                      <td style={{ fontWeight: 'bold', border: '1px solid #c7c6c6' }}>120,001</td>
                    </tr>
                    <tr>
                      <td colSpan="7" rowSpan="2" style={{ border: '1px solid #c7c6c6' }}><u>Invoice Total (In Words):</u> <b>Rupees One Lakh Twenty Thousands One Only</b></td>
                      <td colSpan="1" style={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid #c7c6c6' }}>Round Off</td>
                      <td style={{ fontWeight: 'bold', border: '1px solid #c7c6c6' }}>120,001</td>
                    </tr>
                    <tr>
                      <td colSpan="1" style={{ textAlign: 'center', fontWeight: 'bold', border: '1px solid #c7c6c6' }}>Invoice Total</td>
                      <td colSpan="2" style={{ fontWeight: 'bold', border: '1px solid #c7c6c6' }}>120,001</td>
                    </tr>
                    <tr>
                      <td colSpan="5" style={{ border: '1px solid #c7c6c6' }}>TERMS OF SALE<br />
                        <p>1) Goods once sold will not be taken back or exchanged<br />2) Seller is not responsible for any loss or damaged of goods in transit<br />3) Payment Must Release within 7 Days<br />4) Actual Freight charges will be extra to be paid by client<br />5) All Disputes are subject to Agra Jurisdiction.</p>
                      </td>
                      <td colSpan="7" style={{ textAlign: 'center', verticalAlign: 'text-top', fontWeight: 'bold', border: '1px solid #c7c6c6' }}>For Company Name<br /><br /><br /><br /><br />Authorized Signatory<br /><small>This is System Generated Invoice</small></td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <button onClick={downloadPDF}>Download PDF</button>
    </div>
  );
};

export default Invoice;
