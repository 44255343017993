import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import { BaseUrl } from "./BaseUrl";
import { useDropzone } from "react-dropzone";

function TicketChat() {
  const [tickets, setTickets] = useState([]);
  const [newTicketSubject, setNewTicketSubject] = useState("");
  const [newTicketMessage, setNewTicketMessage] = useState("");
  const [newTicketFiles, setNewTicketFiles] = useState([]);
  const [currentTicket, setCurrentTicket] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [newMessageFiles, setNewMessageFiles] = useState([]);
  const [ip, setIp] = useState("");
  const [openTicketId, setOpenTicketId] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(5); // Number of items per page
  const [totalPages, setTotalPages] = useState(1);
  const [officialEmail, setOfficialEmail] = useState("");
  const [officialPhone, setOfficialPhone] = useState("");

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (userId) {
      fetchTickets(userId);
    }
    fetchIp();
    fetchOfficialDetails();
  }, []);

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    if (!userId) return;

    const intervalId = setInterval(() => {
      fetchTickets(userId);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      content.classList.add('slide-in-right');
    }
  }, []);

  useEffect(() => {
    const total = Math.ceil(tickets.length / pageSize);
    setTotalPages(total);
  }, [tickets, pageSize]);

  const fetchIp = () => {
    axios
      .get("https://api.ipify.org?format=json")
      .then((response) => {
        setIp(response.data.ip);
      })
      .catch((error) => {
        console.error("Error fetching IP address:", error);
      });
  };

  const fetchTickets = (userId) => {
    axios
      .get(`${BaseUrl}/tickets/messages/${userId}`)
      .then((response) => {
        if (response.data && response.data.length > 0) {
          setTickets(response.data);
        } else {
          setTickets([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching tickets:", error);
        setTickets([]);
      });
  };

  const fetchOfficialDetails = () => {
    axios
      .get(`${BaseUrl}/getallsettingdetailsinclient`)
      .then((response) => {
        if (response.data && response.data.success) {
          const { official_email, official_phone } = response.data.results;
          // console.log(official_email, "official email");

          setOfficialEmail(official_email);
          setOfficialPhone(official_phone);
        }
      })
      .catch((error) => {
        console.error("Error fetching official details:", error);
      });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const createTicket = () => {
    const userId = localStorage.getItem("userId");
    if (!newTicketSubject.trim()) {
      alert("Please fill Ticket subject.");
      return;
    }
    if (!newTicketMessage.trim()) {
      alert("Please fill Ticket query/message.");
      return;
    }
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("subject", newTicketSubject);
    formData.append("client_ip", ip);
    formData.append("message", newTicketMessage);
    newTicketFiles.forEach((file) => {
      formData.append("documents", file);
    });
    setIsSubmitting(true);
    axios
      .post(`${BaseUrl}/createticket`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        fetchTickets(userId);
        setNewTicketSubject("");
        setNewTicketMessage("");
        setNewTicketFiles([]);
        alert("Ticket Raised Successfully");
      })
      .catch((error) => {
        console.error("Error creating ticket:", error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const sendMessage = (ticketId) => {
    if (!newMessage.trim()) {
      console.error("Error: Message cannot be blank");
      alert("Type your message");
      return;
    }
    const formData = new FormData();
    formData.append("message", newMessage);
    formData.append("isAdminReply", false);
    newMessageFiles.forEach((file) => {
      formData.append("documents", file);
    });

    axios
      .post(`${BaseUrl}/tickets/${ticketId}/messages`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        const userId = localStorage.getItem("userId");
        fetchTickets(userId);
        setNewMessage("");
        setNewMessageFiles([]);
      })
      .catch((error) => {
        console.error("Error sending message:", error);
      });
  };

  const deleteTicket = (ticketId) => {
    axios
      .delete(`${BaseUrl}/tickets/${ticketId}`)
      .then((response) => {
        const userId = localStorage.getItem("userId");
        fetchTickets(userId);
        alert("Ticket Deleted Successfully");
      })
      .catch((error) => {
        console.error("Error deleting ticket:", error);
      });
  };

  const formatDate = (timestamp) => {
    const options = {
      day: "numeric",
      month: "short",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return new Date(timestamp).toLocaleDateString("en-GB", options);
  };

  const memoizedTicket = useMemo(() => {
    if (currentTicket?.id && tickets.length > 0) {
      const updatedTicket = tickets.find(
        (ticket) => ticket.id === currentTicket.id
      );
      if (updatedTicket) {
        return updatedTicket;
      }
    }
    return currentTicket;
  }, [currentTicket, tickets]);

  useEffect(() => {
    setCurrentTicket(memoizedTicket);
  }, [memoizedTicket]);

  useEffect(() => {
    if (currentTicket?.id && tickets.length > 0)
      setCurrentTicket((pre) => {
        const Ticket = tickets.find((ticket) => ticket.id === pre.id);
        return Ticket;
      });
  }, [tickets]);

  const handleTicketClick = (ticket) => {
    setOpenTicketId((prevOpenTicketId) =>
      prevOpenTicketId === ticket.id ? null : ticket.id
    );
    setCurrentTicket(ticket);
  };

  const handleNewTicketFiles = (acceptedFiles) => {
    setNewTicketFiles([...newTicketFiles, ...acceptedFiles]);
  };

  const handleNewMessageFiles = (acceptedFiles) => {
    setNewMessageFiles([...newMessageFiles, ...acceptedFiles]);
  };

  const {
    getRootProps: getRootPropsNewTicket,
    getInputProps: getInputPropsNewTicket,
  } = useDropzone({
    onDrop: handleNewTicketFiles,
  });

  const {
    getRootProps: getRootPropsNewMessage,
    getInputProps: getInputPropsNewMessage,
  } = useDropzone({
    onDrop: handleNewMessageFiles,
  });

  const startIndex = (page - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const paginatedTickets = tickets.slice(startIndex, endIndex);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <>
      <Header />
      <div className="section content">
        <div className="container mt120">
          <div className="row mb-4 align-items-center">
            <div className="col-lg-6">
              <h4 className="font-weight-bold text-primary heading">
                Help & Support
              </h4>
              <div
                className="mobile-view"
                style={{
                  // border: "0.5px solid #fff",
                  padding: "10px",
                  borderRadius: "10px",
                  boxShadow:
                    "0 4px 8px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(255, 255, 255, 0.19)",
                   
                }}
              >
                <h5>
                  Phone:{" "}
                  <a
                    href={`tel:${officialPhone}`}
                    className="theme_yellow"
                    style={{ fontSize: "14px" }}
                  >
                    {officialPhone}
                  </a>
                </h5>
                <h5>
                  Email:{" "}
                  <a
                    href={`mailto:${officialEmail}`}
                    className="theme_yellow"
                    style={{ fontSize: "14px" }}
                  >
                    {officialEmail}
                  </a>
                </h5>
              </div>
            </div>
            <div className="col-lg-6 text-lg-end"></div>
          </div>

          <div className="row mb-5">
            <div class="col-lg-3 d-none d-lg-block d-md-block">
              <ul className="list-group tabcss">
                <li className="list-group-item ">
                  <Link to="/profile">Basic Profile</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/shortlisted-properties">
                    Your Shortlisted Properties
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/your-payments"> Your Payments</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/yourproperties"> Your Properties</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/interested-yourproperties">
                    {" "}
                    Interested in your Properties
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/rewards"> Your Rewards</Link>
                </li>
                <li className="list-group-item active">
                  <Link to="/profile/tickets"> Your Support Tickets</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/referral"> Referral</Link>
                </li>
              </ul>
            </div>

            <div
              class="mb-4 d-md-none d-lg-none"
              style={{ textAlign: "right" }}
            >
              <div class="dropdown-center">
                <button
                  class="btn btn-primary btn1 dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="fa fa-ticket"></i> Your Support Tickets
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <Link class="dropdown-item" to="/profile">
                      Basic Profile
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="dropdown-item"
                      to="/profile/shortlisted-properties"
                    >
                      Your Shortlisted Properties
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/profile/your-payments">
                      Your Payments
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/profile/yourproperties">
                      Your Properties
                    </Link>
                  </li>
                  <li>
                    <Link
                      class="dropdown-item"
                      to="/profile/interested-yourproperties"
                    >
                      {" "}
                      Interested in your Properties
                    </Link>
                  </li>
                  <li>
                    <Link class="dropdown-item" to="/profile/rewards">
                      Your Rewards
                    </Link>
                  </li>
                  {/* <li>
                  <a class="dropdown-item" href="/profile/tickets">
                  Your Support Tickets
                  </a>
                </li> */}
                  <li>
                    <Link class="dropdown-item" to="/profile/referral">
                      Referral
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-9">
              <h6 className="font-weight-bold text-primary mb-3">
                Create Tickets
              </h6>
              <div className="mb-3">
                <input
                  type="text"
                  value={newTicketSubject}
                  onChange={(e) => setNewTicketSubject(e.target.value)}
                  placeholder="Ticket Subject"
                  className="form-control"
                  required
                />
                <textarea
                  value={newTicketMessage}
                  onChange={(e) => setNewTicketMessage(e.target.value)}
                  placeholder="Describe your query"
                  className="form-control mt-2"
                  required
                />
                <div
                  {...getRootPropsNewTicket()}
                  className="dropzone mt-2 small-dropzone"
                >
                  <input {...getInputPropsNewTicket()} />
                  <p>Upload files as supporting documents to help us understand your query.</p>
                </div>
                <div className="mt-2">
                  {newTicketFiles.map((file, index) => (
                    <div key={index} className="file-item">
                      {file.name}
                    </div>
                  ))}
                </div>
                <button
                  onClick={createTicket}
                  className="btn btn-primary mt-2"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Create Ticket"}
                </button>
              </div>
              {tickets.length === 0 ? (
                <p>No tickets found.</p>
              ) : (
                <ul className="list-group">
                <h6 className="font-weight-bold text-primary mb-3">
                Your Support Tickets
              </h6>
                  {paginatedTickets.map((ticket) => (
                    <li
                      key={ticket.id}
                      className="list-group-item"
                      style={{ background: "transparent", color: "#fff" }}
                    >
                    
                      <div className="d-flex justify-content-between align-items-center">
                        <div
                          onClick={() => handleTicketClick(ticket)}
                          style={{ cursor: "pointer" }}
                        >
                          <strong>Ticket #{ticket.id}</strong> -{" "}
                          {ticket.subject}
                        </div>
                        <button
                          onClick={() => deleteTicket(ticket.id)}
                          className="btn btn-danger btn-sm"
                        >
                          Delete
                        </button>
                      </div>
                      {openTicketId === ticket.id && (
                        <div className="mt-3 ticket-message-container">
                          <div>
                            <strong>Created Date:</strong>{" "}
                            {formatDate(ticket.timestamp)}
                          </div>
                          <div className="messages">
                            {currentTicket.messages.map((message, index) => (
                              <div
                                key={index}
                                className={`mb-2 ${
                                  message.is_admin_reply
                                    ? "text-end"
                                    : "text-start"
                                }`}
                              >
                                <strong>
                                  {message.assignedStaff
                                    ? message.assignedStaff
                                    : "You"}
                                  :
                                </strong>{" "}
                                {message.message} <br />
                                <small>{formatDate(message.timestamp)}</small>
                                {message.documents.length > 0 && (
                                  <div className="documents mt-2">
                                    {message.documents.map((doc, idx) => (
                                      <div key={idx}>
                                        <a
                                          href={doc.file_path}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          {doc.file_name}
                                        </a>
                                      </div>
                                    ))}
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                          <input
                            type="text"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            placeholder="Type your message"
                            className="form-control"
                            required
                          />
                          <div
                            {...getRootPropsNewMessage()}
                            className="dropzone mt-2 small-dropzone"
                          >
                            <input {...getInputPropsNewMessage()} />
                            <p>Upload files</p>
                          </div>
                          <div className="mt-2">
                            {newMessageFiles.map((file, index) => (
                              <div key={index} className="file-item">
                                {file.name}
                              </div>
                            ))}
                          </div>
                          <button
                            onClick={() => sendMessage(currentTicket.id)}
                            className="btn btn-primary mt-2"
                          >
                            Send Message
                          </button>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              )}

              <div className="pagination mt-3">
                <button
                  onClick={() => handlePageChange(page - 1)}
                  className="btn btn-secondary"
                  disabled={page === 1}
                >
                  Previous
                </button>

                <span className="mx-2">
                  Page {page} of {totalPages}
                </span>

                <button
                  onClick={() => handlePageChange(page + 1)}
                  className="btn btn-secondary"
                  disabled={page === totalPages}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
      <style>
        {`
          .ticket-message-container {
            max-height: 300px;
            overflow-y: auto;
          }
          .messages {
            max-height: 200px;
            overflow-y: auto;
            border: 1px solid #ddd;
            padding: 10px;
            margin-bottom: 10px;
          }
          .dropzone {
            border: 2px dashed #dee2e6;
            padding: 20px;
            text-align: center;
            cursor: pointer;
          }
          .small-dropzone {
            padding: 10px;
            font-size: 0.875rem;
          }
          .file-item {
            margin-bottom: 5px;
            font-size: 0.875rem;
            color: #fff;
          }
          .documents a {
            text-decoration: none;
            color: #007bff;
          }
           .pagination {

            display: flex;

            justify-content: center;

            align-items: center;

          }

          .pagination .btn {

            margin: 0 5px;

          }
        `}
      </style>
    </>
  );
}

export default TicketChat;
