import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from 'axios';
import { BaseUrl } from "./BaseUrl";


function OwnersYouContacted() {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    // Fetch data from API using userId from local storage
    const userId = localStorage.getItem("userId");
    // console.log("Fetching properties for userId:", userId);
    
    axios.get(`${BaseUrl}/viewfavorites/${userId}`)
      .then((response) => {
        // console.log("Data fetched successfully:", response.data);
        if (response.data && response.data.properties) {
          setProperties(response.data.properties);
        } else {
          console.error("No properties found in response:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching properties:", error);
      });
  }, []);

  return(
    <>
      <Header />
      <div className="section">
      <div className="container mt120">
        <div className="row mb-4 align-items-center">
          <div className="col-lg-6">
            <h4 className="font-weight-bold text-primary heading">
              Manage your Account
            </h4>
          </div>
          <div className="col-lg-6 text-lg-end"></div>
        </div>
        <div className="row mb-5">
          <div className="col-lg-3">
            <ul className="list-group tabcss">
            <li className="list-group-item">
                  <Link to="/profile">Basic Profile</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/shortlisted-properties">Your Shortlisted Properties</Link>
                </li>
                <li className="list-group-item active">
                  <Link to="/profile/owner-you-contact"> Owners you Contacted</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/your-payments"> Your Payments</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/yourproperties"> Your Properties</Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/interested-yourproperties">  
                    {" "}
                    Interested in your Properties
                  </Link>
                </li>
                <li className="list-group-item">
                  <Link to="/profile/rewards"> Your Rewards</Link>
                </li>
            </ul>
          </div>

          <div className="col-lg-9">
            <h6 className="font-weight-bold text-primary mb-3">
              Shortlisted Properties
            </h6>
            <ul className="nav nav-tabs" id="myTab">
              <li className="nav-item">
                <a href="#buy" className="nav-link active" data-bs-toggle="tab">
                  Buy
                </a>
              </li>
              <li className="nav-item">
                <a href="#rent" className="nav-link" data-bs-toggle="tab">
                  Rent
                </a>
              </li>
              <li className="nav-item">
                <a href="#sell" className="nav-link" data-bs-toggle="tab">
                  Sell
                </a>
              </li>
              <li className="nav-item">
                <a href="#launch" className="nav-link" data-bs-toggle="tab">
                  New Launch
                </a>
              </li>
              <li className="nav-item">
                <a href="#commercial" className="nav-link" data-bs-toggle="tab">
                  Commercial
                </a>
              </li>
              <li className="nav-item">
                <a href="#pg" className="nav-link" data-bs-toggle="tab">
                  PG / Co-living
                </a>
              </li>
              <li className="nav-item">
                <a href="#land" className="nav-link" data-bs-toggle="tab">
                  Plots/Lands
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane fade show active" id="buy">
                <div className="mt-4">
                  {properties.map((property) => (
                    <div className="row mt-3" key={property.property_id}>
                      <div className="col-lg-3">
                        <Link to={`/property-details/${property.property_id}`} className="img">
                          <img src={property.bannerimage_name} alt="Image" className="img-fluid" />
                        </Link>
                      </div>
                      <div className="col-lg-9 valign">
                        <div className="shrt_property">
                          <h6 className="font-weight-bold text-primary">{property.title}</h6>
                          <span>{property.address}, {property.city}, {property.state}, {property.country}, {property.zipcode}</span>
                          <div className="row mt-3">
                            <div className="col-lg-4 prdtl">
                              <span>
                                <b>₹{property.price}</b>
                                <br />
                                ₹{property.price_per_sqft} per sq.ft.
                              </span>
                            </div>
                            {/* Render other property details similarly */}
                          </div>
                          <Link to={`/property-details/${property.property_id}`}>
                            <button className="btn btn-primary mt-3">View Details</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                  {/* End of rendering fetched properties */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      <Footer />
    </>
  );
}

export default OwnersYouContacted;
