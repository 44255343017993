import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BaseUrl } from './BaseUrl';

const FastReliablePropertyServices = () => {
  const [packages, setPackages] = useState([]);
  const [services, setServices] = useState([]);

  useEffect(() => {
    // Fetch packages
    axios.get(`${BaseUrl}/getallpackages`)
      .then(response => {
        setPackages(response.data.results); // Assuming 'results' contains the array of packages
      })
      .catch(error => {
        console.error('Error fetching packages:', error);
      });

    // Fetch services
    axios.get(`${BaseUrl}/getallservices`)
      .then(response => {
        setServices(response.data.results); // Assuming 'results' contains the array of services
      })
      .catch(error => {
        console.error('Error fetching services:', error);
      });
  }, []);

  return (
    <div className="container mt120">
      <div className="row mb-4">
        <div className="col-lg-8">
          <h4 className="font-weight-bold text-primary heading">
            Fast & Reliable Online Property Transfer Services
          </h4>
          <p>Benefits of choosing Property Legal Services</p>

          {/* Display packages */}
          <div>
            {packages.map((pkg, index) => (
              <div key={index} className="card mb-3 mt-4 cardprt">
                <div className="card-body">
                  <h6>{pkg.name}</h6>
                  <p>{pkg.description}</p>
                  <p>Price: {pkg.price}</p>
                </div>
              </div>
            ))}
          </div>

          {/* Display services */}
          <div>
            {services.map((service, index) => (
              <div key={index} className="card mb-3 mt-4 cardprt">
                <div className="card-body">
                  <h6>{service.name}</h6>
                  <p>{service.description}</p>
                  <p>Price: {service.price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Right side content */}
        <div className="col-lg-4">
          <div className="card pcktab">
            <div className="card-body property">
              <ul className="nav nav-tabs" id="myTab">
                <li className="nav-item">
                  <a href="#packages" className="nav-link active" data-bs-toggle="tab">
                    Packages
                  </a>
                </li>
                <li className="nav-item">
                  <a href="#services" className="nav-link" data-bs-toggle="tab">
                    Services
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-1">
                {/* Tab content for Packages and Services */}
              </div>
              <div className="col-lg-12 mt-4">
                <a href="select_package.html" className="btn btn-primary btn1 mr_remove col-lg-12 ">
                  Select Legal Services
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FastReliablePropertyServices;
