import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Testimonials from "../Testimonials";
import Photoes from "../Photoes";
import LeftImage from "../LeftImage";
import RightImage from "../RightImage";
import AboutUsContent from "../AboutUsContent";
import AboutUsBanner from "../AboutUsBanner";
import Team from "../Team";
import TeamImages from "../TeamImages";
import OfficeImages from "../OfficeImages";
import ExpertiseImages from "../ExpertiseImages";
import ImageGallery from "../ImageGallery";
import Agents from "../Agents";

function About() {
  return (
    <>
      <Header />
      <AboutUsBanner />
      <AboutUsContent />
      <RightImage />
      <LeftImage />
      {/* <Photoes /> */}
      <Team/>
      <TeamImages/>
      <OfficeImages/>
      <ExpertiseImages/>
      <ImageGallery/>
      <Agents/>
      <Testimonials />
      <Footer />
    </>
  );
}

export default About;
