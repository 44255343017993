// src/components/Logout.js
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { clearUserDetails } from '../redux/actions/userActions';


const Logout = () => {
    const dispatch=useDispatch();
  useEffect(() => {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("role");
    localStorage.removeItem("userId");
    localStorage.removeItem("email");
    // localStorage.removeItem("detailstoken");
    
    dispatch(clearUserDetails());
    window.location.href = '/login'; // Adjust the path as needed
  }, []);

  return (
    <div>
      Logging out...
    </div>
  );
};

export default Logout;
