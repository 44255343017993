import { SET_USER_DETAILS, CLEAR_USER_DETAILS } from "../types/userTypes";

const initialState = {
  userDetails: {
    id: null,
    name: "",
    mobile: "",
    email:"",
    usertype:"",
    occupation:"",
    budget:"",
    gender:"",
    city:"",
    state:"",
    country:"",
    emailVerified:"",
    usertype:""
  },
};

const userReducer = (state = initialState, action) => {
  if (!action) {
    return state;
  }
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case CLEAR_USER_DETAILS:
      return {
        ...state,
        userDetails: null,
      };
    default:
      return state;
  }
};

export default userReducer;
