// src/hooks/useAutoLogout.js
import { useEffect, useRef } from 'react';

const useAutoLogout = (timeoutPeriod = 60 * 60 * 1000) => { // Default to 5 minutes
  const inactivityTimeout = useRef(null);

  const resetInactivityTimeout = () => {
    clearTimeout(inactivityTimeout.current);
    inactivityTimeout.current = setTimeout(logoutUser, timeoutPeriod);
  };

  const logoutUser = () => {
    // alert('Session expired due to inactivity.');
    window.location.href = '/logout'; // Adjust the URL as needed
  };

  useEffect(() => {
    const events = ['load', 'mousemove', 'keypress', 'scroll', 'click'];
    events.forEach(event => window.addEventListener(event, resetInactivityTimeout));

    resetInactivityTimeout(); // Initialize timeout

    return () => {
      clearTimeout(inactivityTimeout.current);
      events.forEach(event => window.removeEventListener(event, resetInactivityTimeout));
    };
  }, []);

  return null;
};

export default useAutoLogout;
