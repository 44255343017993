import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { BaseUrl } from '../BaseUrl';

const Privacy = () => {
  const [privacyContent, setPrivacyContent] = useState('');

  useEffect(() => {
    const fetchPrivacyDetails = async () => {
      try {
        const response = await fetch(`${BaseUrl}/getprivacydetails`);
        const data = await response.json();
        if (data.success && data.results.length > 0) {
          setPrivacyContent(data.results[0].privacy_policy);
        } else {
          console.error('No privacy policy data found');
        }
      } catch (error) {
        console.error('Error fetching privacy policy data:', error);
      }
    };

    fetchPrivacyDetails();
  }, []);

  return (
    <>
      <Header />
      <div className="section">
        <div className="container mt120">
          <div className="text-center mb70">
            <h1 className="font-weight-bold text-primary heading mb-5">
              Privacy Policy
            </h1>
          </div>
          <div
            className="modal-body"
            dangerouslySetInnerHTML={{ __html: privacyContent || 'Loading...' }}
          ></div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Privacy;
