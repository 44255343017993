import React from "react";

function Services() {
  return (
    <div>
      <section className="features-1">
        <div className="container">
          <div className="row">
            <div
              className="col-6 col-lg-3"
              // data-aos="fade-up"
              data-aos-delay={300}
            >
              <div className="box-feature">
                <span className="flaticon-house" />
                <h3 className="mb-3">Our Properties</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Voluptates, accusamus.
                </p>
                <p>
                  <a href="#" className="learn-more">
                    Learn More
                  </a>
                </p>
              </div>
            </div>
            <div
              className="col-6 col-lg-3"
              // data-aos="fade-up"
              data-aos-delay={500}
            >
              <div className="box-feature">
                <span className="flaticon-building" />
                <h3 className="mb-3">Property for Sale</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Voluptates, accusamus.
                </p>
                <p>
                  <a href="#" className="learn-more">
                    Learn More
                  </a>
                </p>
              </div>
            </div>
            <div
              className="col-6 col-lg-3"
              // data-aos="fade-up"
              data-aos-delay={400}
            >
              <div className="box-feature">
                <span className="flaticon-house-3" />
                <h3 className="mb-3">Real Estate Agent</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Voluptates, accusamus.
                </p>
                <p>
                  <a href="#" className="learn-more">
                    Learn More
                  </a>
                </p>
              </div>
            </div>
            <div
              className="col-6 col-lg-3"
              // data-aos="fade-up"
              data-aos-delay={600}
            >
              <div className="box-feature">
                <span className="flaticon-house-1" />
                <h3 className="mb-3">House for Sale</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Voluptates, accusamus.
                </p>
                <p>
                  <a href="#" className="learn-more">
                    Learn More
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Services;
