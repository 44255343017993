import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { BaseUrl } from './BaseUrl';

const NotificationComponent = ({ userId }) => {
    const [notifications, setNotifications] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const response = await axios.get(`${BaseUrl}/getnotifications/${userId}`);
                setNotifications(response.data);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        fetchNotifications();
    }, [userId]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleResponse = async (notificationId, response) => {
        try {
            await axios.post(`${BaseUrl}/sendnotificationresponse/${notificationId}`, { response });
            slideOutAndRemove(notificationId);
        } catch (error) {
            console.error('Error responding to notification:', error);
        }
    };

    const handleDelete = async (notificationId) => {
        try {
            await axios.delete(`${BaseUrl}/deletenotification/${notificationId}`);
            slideOutAndRemove(notificationId);
        } catch (error) {
            console.error('Error deleting notification:', error);
        }
    };

    const slideOutAndRemove = (notificationId) => {
        const newNotifications = notifications.map(notification =>
            notification.id === notificationId ? { ...notification, slideOut: true } : notification
        );
        setNotifications(newNotifications);

        setTimeout(() => {
            setNotifications(newNotifications.filter(notification => notification.id !== notificationId));
        }, 300); // Adjust timing as needed
    };

    const unreadCount = notifications.filter(notification => notification.response === null).length;

    return (
        <div className="notification-component" style={{ position: 'relative', display: 'inline-block' }}>
            <div onClick={() => setShowDropdown(!showDropdown)} className="notification-icon" style={{ cursor: 'pointer' }}>
                <i className='fas fa-bell' style={{
                    marginRight: "8px",
                    fontSize: "24px",
                    color: "white"
                }}></i>
                {unreadCount > 0 && <span className="notification-count" style={{
                    backgroundColor: 'red',
                    borderRadius: '50%',
                    color: 'white',
                    padding: '2px 8px',
                    position: 'absolute',
                    top: '-5px',
                    right: '-10px',
                    fontSize: '12px'
                }}>{unreadCount}</span>}
            </div>
            {showDropdown && (
                <div ref={dropdownRef} className="notification-dropdown" style={{
                    position: 'absolute',
                    left: '-110px',
                    top: '100%',
                    backgroundColor: 'white',
                    border: '1px solid #ccc',
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    zIndex: 1000,
                    width: '270px',
                    maxHeight: '400px',
                    overflowY: 'auto',
                    padding: '10px',
                    borderRadius: '10px',
                    color: 'black',
                }}>
                    {notifications.length > 0 ? (
                        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                            <thead>
                                <tr>
                                    <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Message</th>
                                    <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {notifications.map(notification => (
                                    <tr key={notification.id} className={notification.slideOut ? 'slide-out' : ''} style={{
                                        transition: 'transform 0.3s ease',
                                        transform: notification.slideOut ? 'translateX(100%)' : 'none'
                                    }}>
                                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{notification.message}</td>
                                        <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                                            {notification.response === null ? (
                                                <div style={{display:"flex"}}>
                                                    <button onClick={() => handleResponse(notification.id, "Yes")} style={{ marginRight: '5px' }}>Yes</button>
                                                    <button onClick={() => handleResponse(notification.id, "No")} style={{ marginRight: '5px' }}>No</button>
                                                    {/* <button onClick={() => handleDelete(notification.id)}>Delete</button> */}
                                                </div>
                                            ) : (
                                                <span>Response: {notification.response}</span>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <p style={{ textAlign: 'center', color: 'black' }}>No notifications</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default NotificationComponent;
