import React from "react";

function Clinics() {
  return (
    <div className="pt-0 mt-5">
      <div className="container">
        <div className="row justify-content-between mb-5">
          <div className="col-lg-6 mb-lg-0 order-lg-2">
            <div className="d-flex feature-h">
              <span className="wrap-icon me-3">
                <span className="icon-person" />
                
              </span>
              <div className="feature-text">
                <h3 className="heading">Packing and Moving Services</h3>
                <p>
                  Offering professional packing and moving services, including
                  packing, loading, transportation, unloading, and unpacking to
                  ensure a hassle-free move.
                </p>
              </div>
            </div>
            <div className="d-flex feature-h">
              <span className="wrap-icon me-3">
                <span className="icon-truck" />
              </span>
              <div className="feature-text">
                <h3 className="heading">Transportation and Logistics</h3>
                <p>
                  Providing secure and reliable transportation services for
                  your belongings, ensuring timely delivery to your new
                  location.
                </p>
              </div>
            </div>
            <div className="d-flex feature-h">
              <span className="wrap-icon me-3">
                <span className="icon-storage" />
              </span>
              <div className="feature-text">
                <h3 className="heading">Storage Solutions</h3>
                <p>
                  Offering short-term and long-term storage options to keep your
                  possessions safe and secure during the moving process.
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="d-flex feature-h">
              <span className="wrap-icon me-3">
                <span className="icon-gavel" />
              </span>
              <div className="feature-text">
                <h3 className="heading">Property Legal Services</h3>
                <p>
                  Providing legal assistance for property-related matters,
                  including property disputes, title issues, and real estate
                  transactions.
                </p>
              </div>
            </div>
            <div className="d-flex feature-h">
              <span className="wrap-icon me-3">
                <span className="icon-security" />
              </span>
              <div className="feature-text">
                <h3 className="heading">Document Preparation</h3>
                <p>
                  Assisting in the preparation of legal documents related to
                  property transactions, such as sale agreements, lease
                  agreements, and title deeds.
                </p>
              </div>
            </div>
            <div className="d-flex feature-h">
              <span className="wrap-icon me-3">
                <span className="icon-home" />
              </span>
              <div className="feature-text">
                <h3 className="heading">Estate Planning</h3>
                <p>
                  Helping individuals with the drafting of wills, trusts, and
                  other estate planning documents to manage their property
                  effectively.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Clinics;
