// PropertyList.jsx
import React, { useState, useEffect } from 'react';
import { BaseUrl } from "../BaseUrl";

function PropertyList({ location }) {
    const [properties, setProperties] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BaseUrl}/gethomebycityorzipcode?location=${location}`);
                const data = await response.json();
                setProperties(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        // Clean up function to cancel any pending requests if the component unmounts
        return () => {
            // Cleanup logic if needed
        };
    }, [location]);

    return (
        <div>
            <h2>Properties in {location}</h2>
            <ul>
                {properties.map((property, index) => (
                    <li key={index}>{property}</li>
                ))}
            </ul>
        </div>
    );
}

export default PropertyList;
