import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BaseUrl } from "./BaseUrl";
// import 'bootstrap/dist/css/bootstrap.min.css';

function Search() {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [properties, setProperties] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [propertyType, setPropertyType] = useState('');
    const [city, setCity] = useState('');
    const navigate = useNavigate();

    const handleSearch = async (e) => {
        const value = e.target.value;
        setQuery(value);

        if (value.length === 0) {
            setResults([]);
            setShowDropdown(false);
            return;
        }

        try {
            const response = await fetch(`${BaseUrl}/search?location=${value}&city=${city}&property_type=${propertyType}`);
            const data = await response.json();
            setResults(data.results || []);
            setShowDropdown(true);
        } catch (error) {
            console.error('Error fetching data:', error);
            setResults([]);
            setShowDropdown(false);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        navigate(`/results?location=${query}&city=${city}&property_type=${propertyType}`);
    };

    const handleSelectResult = (result) => {
        setQuery(result.locality);
        setResults([]);
        setShowDropdown(false);
    };

    return (
        <div className="App">
            <form
                onSubmit={handleSubmit}
                className="narrow-w form-search d-flex align-items-stretch mb-3"
                // data-aos="fade-up"
                // data-aos-delay="200"
            >
                <div>
                    <select
                        className="form-select form-control formslt col-12"
                        name="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                    >
                        <option value="select">Select Cities</option>
                        <option value="mumbai">Mumbai</option>
                        <option value="bangalore">Bangalore</option>
                        <option value="pune">Pune</option>
                        <option value="chennai">Chennai</option>
                        <option value="delhi">Delhi</option>
                        <option value="gurgaon">Gurgaon</option>
                        <option value="agra">Agra</option>
                        <option value="hydrabad">Hyderabad</option>
                    </select>
                </div>
                <div>
                    <select
                        className="form-select form-control formslt col-12"
                        name="property_type"
                        value={propertyType}
                        onChange={(e) => setPropertyType(e.target.value)}
                        required
                    >
                        <option value="-1">Property Type</option>
                        <option value="0">Residential</option>
                        <option value="1">Commercial</option>
                        <option value="2">Plot</option>
                    </select>
                </div>
                <input
                    className="form-control px-4"
                    placeholder="Your Near Locality Area"
                    type="text"
                    value={query}
                    onChange={handleSearch}
                />
                <button className="btn btn-primary" type="submit">
                    Search
                </button>
            </form>
            {showDropdown && results.length > 0 && (
                <div className="dropdown-menu show" style={{ display: 'block', width: '25%', marginLeft: '330px' }}>
                    {results.map((result, index) => (
                        <button
                            key={index}
                            className="dropdown-item"
                            onClick={() => handleSelectResult(result)}
                        >
                            {result.locality}
                        </button>
                    ))}
                </div>
            )}
            <div>
                {properties.map((property, index) => (
                    <div key={index}>
                        {/* Render your property details here */}
                        <h3>{property.name}</h3>
                        <p>{property.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Search;
