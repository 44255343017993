export const isValidMobile = (mobile) => {
  const mobileRegex = /^\d{10}$/;
  return mobileRegex.test(mobile) || mobile === "";
};

export const isValidPrice = (price) => {
  // Convert the price to a number
  const numericPrice = parseFloat(price);

  // console.log(numericPrice, "numericPrice");

  // Check if the price is a valid number and greater than 5000
  return !isNaN(numericPrice) && numericPrice > 5000;
};

export const isValidOccupation = (occupation) => {
  if (occupation === "") {
    return false;
  }
  return true;
};

export const isValidEmail = (email) => {
    // Improved regex to ensure there is a valid domain suffix
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailRegex.test(email);
  };
  