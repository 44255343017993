import React, { useEffect, useState } from 'react';
import { BaseUrl } from '../BaseUrl';

const CommonFields = () => {
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');

  useEffect(() => {
    // Fetch cities from the API
    fetch(`${BaseUrl}/listcities`)
      .then(response => response.json())
      .then(data => setCities(data.results))
      .catch(error => console.error('Error fetching cities:', error));
  }, []);

  return (
    <div className="d-lg-flex align-items-stretch">
      <div>
        <select 
          className="form-select form-control formslt col-12" 
          name="city" 
          value={selectedCity} 
          onChange={(e) => setSelectedCity(e.target.value)} 
          required
        >
          <option value="">Select City</option>
          {cities.map((city, index) => (
            <option key={index} value={city.city}>{city.city}</option>
          ))}
        </select>
      </div>
      <div className="row rngdiv">
        <div className="col-lg-2 mt-3 valign">
          <div>
            <label>Price</label>
          </div>
        </div>
        <div className="col-lg-4">
          <input type="text" className="form-control ranginp" defaultValue="5000" placeholder="min" name="min_price" />
        </div>
        <div className="col-lg-1 mt-3">
          <div>
            <label>To</label>
          </div>
        </div>
        <div className="col-lg-5">
          <input type="text" className="form-control ranginp" defaultValue="10000000" placeholder="max" name="max_price" />
        </div>
      </div>
      <input type="text" className="form-control px-4 brrght" placeholder="Enter ZIP code" name="zipcode" />
      <button type="submit" className="btn btn-primary">
        <i className="fa fa-search"></i> Search
      </button>
    </div>
  );
};

export default CommonFields;
