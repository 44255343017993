import React from 'react';
import ReactDOM from 'react-dom';

const modalOverlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  background: 'rgba(0, 0, 0, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backdropFilter: 'blur(5px)', // Apply blur effect to the background
};

const modalContentStyle = {
  background: 'white',
  padding: '20px',
  borderRadius: '8px',
  textAlign: 'center',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
};

const modalActionsStyle = {
  marginTop: '20px',
};

const buttonStyle = {
  padding: '10px 20px',
  margin: '0 10px',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '16px',
  transition: 'background-color 0.3s, color 0.3s',
};

const confirmButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#28a745', // Green for Yes
  color: 'white',
};

const closeButtonStyle = {
  ...buttonStyle,
  backgroundColor: '#dc3545', // Red for No
  color: 'white',
};

const Modal = ({ message, onClose, onConfirm }) => {
  return ReactDOM.createPortal(
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <p style={{color:'#000'}}>{message}</p>
        <div style={modalActionsStyle}>
          <button style={confirmButtonStyle} onClick={onConfirm}>Yes</button>
          <button style={closeButtonStyle} onClick={onClose}>No</button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
