import React from "react";

function RightImage() {
  return (
    <div>
      <div className="section pt-0 mt-4">
        <div className="container mt-4">
          <div className="row justify-content-between mb-5">
            <div className="col-lg-7 mb-5 mb-lg-0 order-lg-2">
              <div className="img-about dots">
                <img
                  src="images/insurence.jpg"
                  alt="Image"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex feature-h">
                <span className="wrap-icon me-3">
                  <span className="icon-heart" />
                </span>
                <div className="feature-text">
                  <h3 className="heading">Life Insurance</h3>
                  <p className=" ">
                  The Life Insurance Corporation (LIC) has lifted the veil on what promises to be “the mother of all capital issues” in India.
                  </p>
                </div>
              </div>
              <div className="d-flex feature-h">
                <span className="wrap-icon me-3">
                  <span className="icon-person" />
                </span>
                <div className="feature-text">
                  <h3 className="heading">Health Insurance</h3>
                  <p className=" ">
                  Since Covid, health insurance premiums have been the main driver of the non-life industry.
                  </p>
                </div>
              </div>
              <div className="d-flex feature-h">
                <span className="wrap-icon me-3">
                  <span className="icon-umbrella" />
                </span>
                <div className="feature-text">
                  <h3 className="heading">General Insurance</h3>
                  <p className=" ">
                  Life is very uncertain and has a way of throwing surprises at you when you least expect it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RightImage;
