import React from 'react';
import NoInternet from './nointernet.png';

const NoInternetConnection = () => {
    const handleRefresh = () => {
        window.location.reload();
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            textAlign: 'center',
            padding: '20px',
            backgroundColor: 'black',
            color: '#feb406'
        }}>
            <img 
                src={NoInternet} 
                alt="No Internet" 
                style={{ 
                    width: '200px', 
                    marginBottom: '20px', 
                    filter: 'invert(100%)' 
                }} 
            />
            <h1 style={{ fontSize: '24px', marginBottom: '10px' }}>No Internet Connection</h1>
            <p style={{ fontSize: '16px', marginBottom: '20px' }}>Please check your internet connection and try again.</p>
            <button 
                onClick={handleRefresh} 
                style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    color: 'black',
                    backgroundColor: '#feb406',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                }}>
                Try Again
            </button>
        </div>
    );
};

export default NoInternetConnection;
