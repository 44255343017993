import React, { useState, useEffect } from 'react';
import FeatureProperties from './Featureproperties';
import { BaseUrl } from '../BaseUrl';
import axios from 'axios';
import Header from '../Header';
import Footer from '../Footer';
import PropertiesBanner from '../PropertiesBanner';

const Properties1 = () => {
  const [activeTab, setActiveTab] = useState('buy');
  const [topCities, setTopCities] = useState([]);
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [propertyType, setPropertyType] = useState('-1');
  const [propertySubType, setPropertySubType] = useState('');
  const [propertySubType1, setPropertySubType1] = useState('');
  const [bhkOptions, setBhkOptions] = useState([]);
  const [selectedBhk, setSelectedBhk] = useState('');
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(10000000);
  const [postedBy, setPostedBy] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const propertiesPerPage = 6;

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [citiesResponse, propertiesResponse] = await Promise.all([
          axios.get(`${BaseUrl}/togettopcities`),
          axios.get(`${BaseUrl}/viewallproperties`)
        ]);

        setTopCities(citiesResponse.data.cities);
        setProperties(propertiesResponse.data.properties);
        setFilteredProperties(propertiesResponse.data.properties);

        // console.log(citiesResponse);
        // console.log(propertiesResponse);
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, []);

  useEffect(() => {
    if (propertyType === '-1') {
      setBhkOptions([]);
    } else if (propertyType === '0') {
      setBhkOptions([1, 2, 3, 4, 5, '+5']);
    } else {
      setBhkOptions([]);
    }
  }, [propertyType]);

  useEffect(() => {
    filterProperties();
  }, [selectedCity, propertyType, propertySubType, propertySubType1, selectedBhk, minPrice, maxPrice, postedBy]);

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page whenever filters change
  }, [selectedCity, propertyType, propertySubType, propertySubType1, selectedBhk, minPrice, maxPrice, postedBy]);

  const filterProperties = () => {
    let filtered = properties;

    if (selectedCity) {
      filtered = filtered.filter(property => property.city === selectedCity);
    }
    if (propertyType !== '-1') {
      filtered = filtered.filter(property => property.property_type.toString() === propertyType);
    }
    if (propertySubType) {
      filtered = filtered.filter(property => property.residential_type.toString() === propertySubType);
    }
    if (propertySubType1) {
      filtered = filtered.filter(property => property.commercial_type.toString() === propertySubType1);
    }
    if (selectedBhk) {
      filtered = filtered.filter(property => property.bhk.toString() === selectedBhk);
    }
    filtered = filtered.filter(property => property.price >= minPrice && property.price <= maxPrice);
    if (postedBy) {
      filtered = filtered.filter(property => property.postedBy === postedBy);
    }

    setFilteredProperties(filtered);
  };

  const handleCitySelection = (city) => {
    setSelectedCity(city);
  };

  const handlePropertyTypeChange = (type) => {
    setPropertyType(type);
    setSelectedBhk(''); // Reset selected BHK when property type changes
  };

  const handleSubTypeSelection = (subType) => {
    setPropertySubType(subType);
  };
  const handleSubTypeSelection1 = (subType1) => {
    setPropertySubType(subType1);
  };

  const handleBhkSelection = (bhk) => {
    setSelectedBhk(bhk);
  };

  const handleMinPriceChange = (e) => {
    setMinPrice(parseInt(e.target.value, 10));
  };

  const handleMaxPriceChange = (e) => {
    setMaxPrice(parseInt(e.target.value, 10));
  };

  const handlePostedBySelection = (postedBy) => {
    setPostedBy(postedBy);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Calculate the properties to display based on the current page
  const indexOfLastProperty = currentPage * propertiesPerPage;
  const indexOfFirstProperty = indexOfLastProperty - propertiesPerPage;
  const currentProperties = filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);

  const totalPages = Math.ceil(filteredProperties.length / propertiesPerPage);

  return (
    <>
      <Header />
      <PropertiesBanner />

      <div className="section">
        <div className="container property">
          <div className="tab-content">
            {activeTab === 'buy' && (
              <div className="tab-pane fade show active" id="buy">
                <div className="row">
                  <div className="col-lg-3 filter">
                    <h4 className="font-weight-bold heading mt-3">Filter Property By</h4>
                    <div>
                      <div className="mt-4">
                        <h6>Top Cities</h6>
                        <div className="mb-3">
                          {topCities.length > 0 ? (
                            topCities.map((city, index) => (
                              <button
                                key={index}
                                type="button"
                                className={`btn btn-primary ${selectedCity === city.city ? 'active' : ''}`}
                                onClick={() => handleCitySelection(city.city)}
                              >
                                {city.city}
                              </button>
                            ))
                          ) : (
                            <p>No cities available</p>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="mt-4">
                        <h6>Property Type</h6>
                        <div className="mb-3">
                          <select
                            className="form-select"
                            onChange={(e) => handlePropertyTypeChange(e.target.value)}
                            value={propertyType}
                          >
                            <option value="-1">Select</option>
                            <option value="0">Residential</option>
                            <option value="1">Commercial</option>
                            <option value="2">Plot</option>
                          </select>
                          {propertyType === '0' && (

                            <div className="mt-2">
                              <button 
                                type="button" 
                                className={`btn btn-primary ${propertySubType === '0' ? 'active' : ''}`}
                                onClick={() => handleSubTypeSelection('0')}
                              >
                                Apartment
                              </button>
                              <button 
                                type="button" 
                                className={`btn btn-primary ${propertySubType === '1' ? 'active' : ''}`}
                                onClick={() => handleSubTypeSelection('1')}
                              >
                                House/Villa
                              </button>
                              <button 
                                type="button" 
                                className={`btn btn-primary ${propertySubType === '2' ? 'active' : ''}`}
                                onClick={() => handleSubTypeSelection('2')}
                              >
                                Greater Community
                              </button>
                            </div>
                          )}
                          {propertyType === '1' && (
                            <div className="mt-2">
                              <button 
                                value="0" 
                                type="button" 
                                className={`btn btn-primary ${propertySubType1 === '0' ? 'active' : ''}`}
                                onClick={() => handleSubTypeSelection1('0')}
                              >
                                Office Space
                              </button>
                              <button 
                                value="1"
                                type="button" 
                                className={`btn btn-primary ${propertySubType1 === '1' ? 'active' : ''}`}
                                onClick={() => handleSubTypeSelection1('1')}
                              >
                                Shop/Showroom
                              </button>
                              <button 
                                value="2" 
                                type="button" 
                                className={`btn btn-primary ${propertySubType1 === '2' ? 'active' : ''}`}
                                onClick={() => handleSubTypeSelection1('2')}
                              >
                                Warehouse/Godown
                              </button>
                              <button 
                                value="3" 
                                type="button" 
                                className={`btn btn-primary ${propertySubType1 === '3' ? 'active' : ''}`}
                                onClick={() => handleSubTypeSelection1('3')}
                              >
                                Industrial Building
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <hr />
                      {/* {propertyType === '0' && (
                        <div className="mt-4">
                          <h6>BHK</h6>
                          <div className="mb-3">
                            {bhkOptions.map((bhk, index) => (
                              <button
                                key={index}
                                type="button"
                                className={`btn btn-primary ${selectedBhk === bhk.toString() ? 'active' : ''}`}
                                onClick={() => handleBhkSelection(bhk.toString())}
                              >
                                {bhk} BHK
                              </button>
                            ))}
                          </div>
                        </div>
                      )} */}
                      {/* <hr /> */}
                      <div className="mt-4">
                        <h6>Price</h6>
                        <div className="mb-3">
                          <div className="row">
                            <div className="col-lg-5 form-control-sm">
                              <input
                                type="text"
                                className="form-control"
                                value={minPrice}
                                onChange={handleMinPriceChange}
                              />
                            </div>
                            <div className="col-lg-2">
                              <div className="mt-2">
                                <label>To</label>
                              </div>
                            </div>
                            <div className="col-lg-5 form-control-sm">
                              <input
                                type="text"
                                className="form-control"
                                value={maxPrice}
                                onChange={handleMaxPriceChange}
                              />
                            </div>
                          </div>
                          <input
                            type="range"
                            className="form-range"
                            min="0"
                            max="1000000"
                            step="10000"
                            value={minPrice}
                            onChange={handleMinPriceChange}
                          />
                          <input
                            type="range"
                            className="form-range"
                            min="0"
                            max="1000000"
                            step="10000"
                            value={maxPrice}
                            onChange={handleMaxPriceChange}
                          />
                        </div>
                      </div>
                      <hr />
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="container">
                   
                      <div className="row mb-2 align-items-center">
                        <div className="col-lg-6 text-center mx-auto">
                          <h2 className="font-weight-bold heading">Filtered Properties</h2>
                        </div>
                      </div>
                      <div className="container">
                        <div className="row">
                          {currentProperties.map((property, idx) => (
                            <div key={idx} className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
                              <div className="property-item mb-30">
                                <a href={`/property-details/${property.id}`} className="img">
                                  <img 
                                    src={property.bannerimage_name}
                                    alt="Property Image" 
                                    className="img-fluid" 
                                    style={{ width: '300px', height: '200px' }} 
                                  />
                                </a>
                                <div className="property-content">
                                  <div className="price mb-2">
                                    <span>&#8377; {property.price.toLocaleString()}</span>
                                  </div>
                                  <div>
                                    <span className="d-block mb-2">{property.address}</span>
                                    <span className="city d-block mb-3">{property.city}</span>
                                    <div className="specs d-flex mb-4">
                                      {/* <span className="d-block d-flex align-items-center me-3">
                                        <span className="icon-bed me-2"></span>
                                        <span className="caption">{property.beds} beds</span>
                                      </span>
                                      <span className="d-block d-flex align-items-center">
                                        <span className="icon-bath me-2"></span>
                                        <span className="caption">{property.baths} baths</span>
                                      </span> */}
                                    </div>
                                    <a href={`/property-details/${property.id}`} className="btn btn-primary py-2 px-3">
                                      See details
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="row align-items-center py-5">
                          <div className="col-lg-3">Page ({currentPage} of {totalPages})</div>
                          <div className="col-lg-6 text-center">
                            <div className="custom-pagination">
                              {[...Array(totalPages)].map((_, pageIndex) => (
                                <a
                                  key={pageIndex}
                                  href="#"
                                  className={currentPage === pageIndex + 1 ? 'active' : ''}
                                  onClick={() => handlePageChange(pageIndex + 1)}
                                >
                                  {pageIndex + 1}
                                </a>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Properties1;
