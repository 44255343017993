import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { BaseUrl } from "./BaseUrl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "./Header";
import Footer from "./Footer";
import Properties from "./Pages/Properties";
import FeatureProperties from "./Pages/Featureproperties";
import Testimonials from "./Testimonials";
import nopropertiesfound from "./nopropertyfound.png";
import CompanyCopyright from "./CompanyCopyright";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ResultsPage() {
  const query = useQuery();
  const navigate = useNavigate();
  const [properties, setProperties] = useState([]);
  const sliderRef = useRef(null);

  const city = query.get("city") || "";
  const tab = query.get("tab") || "";
  const min_price = query.get("min_price") || "";
  const max_price = query.get("max_price") || "";
  const zipcode = query.get("zipcode") || "";
  const property_type = query.get("property_type") || "";
  const bhk_type = query.get("bhk_type") || "";
  const tenant_type = query.get("tenant_type") || "";
  const property_status = query.get("property_status") || "";
  const property_for = query.get("property_for") || "";
  const commercial_property_type = query.get("commertial_type") || "";
  const residential_type = query.get("residential_type") || "";

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await fetch(
          `${BaseUrl}/searchproperty?tab=${tab}&city=${city}&min_price=${min_price}&max_price=${max_price}&zipcode=${zipcode}&property_type=${property_type}&bhk_type=${bhk_type}&tenant_type=${tenant_type}&property_status=${property_status}&property_for=${property_for}&residential_type=${residential_type}&commercial_property_type=${commercial_property_type}`
        );

        const data = await response.json();
        setProperties(data.properties || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        setProperties([]);
      }
    };

    fetchProperties();
  }, [
    city,
    tab,
    min_price,
    max_price,
    zipcode,
    property_type,
    bhk_type,
    tenant_type,
    property_status,
    property_for,
    commercial_property_type,
    residential_type,
  ]); // Only re-fetch when any of these specific parameters change

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const imageSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // autoplay: true,
    autoplaySpeed: 2000,
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleSeeDetails = (id) => {
    navigate(`/property-details/${id}`);
  };

  return (
    <>
      <Header />
      <div className="App">
        <div className="section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                {/* <h2 className="font-weight-bold heading">Popular Properties</h2> */}
              </div>
              <div className="col-lg-6 text-lg-end">
                <p>
                  {/* <a
                    href="/allproperties"
                    target="_blank"
                    className="btn btn-primary text-white py-3 px-4"
                  >
                    View all properties
                  </a> */}
                </p>
              </div>
            </div>
            <div style={{ display: "flex", padding: "20px" }}>
              <div>
                <h3 style={{marginTop:'20px'}}> Search Results</h3>
              </div>
              {/* <div>
                <button>
                  Back
                </button>
              </div> */}
            </div>
            <div className="row">
              <div className="col-12">
                <Slider ref={sliderRef} {...settings}>
                  {properties.length > 0 ? (
                    properties.map((property, index) => (
                      <div key={index} className="property-item">
                        <a
                          onClick={() => handleSeeDetails(property.id)}
                          className="img"
                          style={{ textDecoration: "none", cursor: "pointer" }}
                        >
                          {/* <img
                            src={property.bannerimage_name
                            }
                            alt="Property"
                            className="img-fluid"
                            style={{ width: "100%", height: "350px" }}
                          /> */}
                          <div className="img-slider">
                            {property.image_names.split(",").length > 1 ? (
                              <Slider {...imageSliderSettings}>
                                {property.image_names
                                  .split(",")
                                  .map((image, i) => (
                                    <div key={i}>
                                      <img
                                        src={image}
                                        alt={`Property ${i}`}
                                        className="img-fluid"
                                        style={{
                                          width: "100%",
                                          height: "250px",
                                        }}
                                      />
                                    </div>
                                  ))}
                              </Slider>
                            ) : (
                              <div>
                                {[...Array(1)].map((_, i) => (
                                  <div key={i}>
                                    <img
                                      src={property.image_names}
                                      alt="Property"
                                      className="img-fluid"
                                      style={{ width: "100%", height: "350px" }}
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </a>
                        <div className="property-content">
                          <div className="price mb-2">
                            <span>&#8377; {property.price}</span>
                          </div>
                          <div>
                            <span
                              className="d-block mb-2 "
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                maxWidth: "100%",
                                display: "block",
                              }}
                            >
                              {property.address}
                            </span>
                            <span className="city d-block mb-3">
                              {property.city}, {property.state},{" "}
                              {property.country}
                            </span>
                            <div className="specs d-flex mb-4">
                              {/* <span className="d-block d-flex align-items-center me-3">
                                <span className="icon-bed me-2" />
                                <span className="caption">
                                  {property.bedrooms} beds
                                </span>
                              </span> */}
                              {/* <span className="d-block d-flex align-items-center">
                                <span className="icon-bath me-2" />
                                <span className="caption">
                                  {property.bathrooms} baths
                                </span>
                              </span> */}
                            </div>
                            <button
                              onClick={() => handleSeeDetails(property.id)}
                              className="btn btn-primary py-2 px-3"
                              style={{ textDecoration: "none" }}
                            >
                              See details
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div>
                      {/* <p>No properties available</p> */}
                      <img
                        src={nopropertiesfound}
                        alt="Property"
                        className="img-fluid"
                        style={{ width: "100%", height: "350px" }}
                      />
                    </div>
                  )}
                </Slider>
                <div className="slider-controls mt-4 text-center">
                  {properties.length > 1 && (
                    <>
                      <div id="testimonial-nav">
                        <span
                          onClick={handlePrev}
                          className="prev"
                          style={{ marginRight: "10px" }}
                        >
                          Prev
                        </span>
                        <span onClick={handleNext} className="next">
                          Next
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FeatureProperties />
      <Testimonials />
      {/* <div className="mobile-view">
      <CompanyCopyright/>
      </div> */}
      <Footer />
    </>
  );
}

export default ResultsPage;
