import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from 'axios';
import { BaseUrl } from "./BaseUrl";

function ShortlistContent() {
  const [properties, setProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  useEffect(() => {
    const userId = localStorage.getItem("userId");

    axios.get(`${BaseUrl}/viewfavorites/${userId}`)
      .then((response) => {
        if (response.data && response.data.properties) {
          setProperties(response.data.properties);
        } else {
          console.error("No properties found in response:", response.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching properties:", error);
      });
  }, []);

  useEffect(() => {
    const content = document.querySelector('.content');
    if (content) {
      content.classList.add('slide-in-right');
    }
  }, []);


  const indexOfLastProperty = currentPage * itemsPerPage;
  const indexOfFirstProperty = indexOfLastProperty - itemsPerPage;
  const currentProperties = properties.slice(indexOfFirstProperty, indexOfLastProperty);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(properties.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <>
      <Header />
      <div className="section content">
        <div className="container mt120">
          <div className="row mb-4 align-items-center">
            <div className="col-lg-6">
              <h4 className="font-weight-bold text-primary heading">
                Manage shortlisted Properties
              </h4>
            </div>
            <div className="col-lg-6 text-lg-end"></div>
          </div>
          <div className="row mb-5">
          <div class="col-lg-3 d-none d-lg-block d-md-block">
            <ul className="list-group tabcss">
              <li className="list-group-item ">
                <Link to="/profile">Basic Profile</Link>
              </li>
              <li className="list-group-item active">
                <Link to="/profile/shortlisted-properties">
                  Your Shortlisted Properties
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/your-payments"> Your Payments</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/yourproperties"> Your Properties</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/interested-yourproperties">
                  {" "}
                  Interested in your Properties
                </Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/rewards"> Your Rewards</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/tickets"> Your Support Tickets</Link>
              </li>
              <li className="list-group-item">
                <Link to="/profile/referral"> Referral</Link>
              </li>
            </ul>
            
          </div>

          <div class="mb-4 d-md-none d-lg-none" style={{textAlign:"right"}}>
            <div class="dropdown-center">
              <button
                class="btn btn-primary btn1 dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="fa fa-heart"></i> Your Shortlisted Properties
              </button>
              <ul class="dropdown-menu">
                <li>
                  <Link class="dropdown-item" to="/profile">
                    Basic Profile
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/your-payments">
                  
                    Your Payments
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/yourproperties">
                   
                  Your Properties
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/interested-yourproperties">
                 
                  {" "}
                  Interested in your Properties
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/rewards">
                  Your Rewards
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/tickets">
                  Your Support Tickets
                  </Link>
                </li>
                <li>
                  <Link class="dropdown-item" to="/profile/referral">
                  Referral
                  </Link>
                </li>
              </ul>
            </div>
          </div>

            <div className="col-lg-9">
              <h6 className="font-weight-bold text-primary mb-3">
                Shortlisted Properties
              </h6>
              <div className="tab-content">
                <div className="tab-pane fade show active" id="buy">
                  <div className="mt-4">
                    {currentProperties.map((property) => (
                      <div className="row mt-3" key={property.property_id}>
                        <div className="col-lg-3">
                          <Link to={`/property-details/${property.property_id}`} className="img">
                            <img src={property.bannerimage_name} alt="Image" className="img-fluid" style={{width:"100%"}} />
                          </Link>
                        </div>
                        <div className="col-lg-9 valign">
                          <div className="shrt_property">
                            <h6 className="font-weight-bold text-primary">{property.title}</h6>
                            <span>{property.address}, {property.city}, {property.state}, {property.country}, {property.zipcode}</span>
                            <div className="row mt-3">
                              <div className="col-lg-4 ">
                                <span>
                                  <b>₹{property.price}</b>
                                </span>
                              </div>
                            </div>
                            <Link to={`/property-details/${property.property_id}`}>
                              <button className="btn btn-primary mt-3">View Details</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="pagination" style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                <button 
                  className="btn btn-secondary mr-2" 
                  onClick={handlePrevPage} 
                  disabled={currentPage === 1}
                  style={{marginRight:"10px"}}
                >
                  Previous
                </button>
                <button 
                  className="btn btn-secondary" 
                  onClick={handleNextPage} 
                  disabled={currentPage === Math.ceil(properties.length / itemsPerPage)}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ShortlistContent;
