import React, { useEffect } from "react";

const ChatWidget = () => {
  useEffect(() => {
    // Define Tawk_API globally
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    const s0 = document.getElementsByTagName("script")[0];
    const s1 = document.createElement("script");
    s1.async = true;
    s1.src = 'https://embed.tawk.to/668fb8297a36f5aaec9715ac/1i2gmepc6';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);

    s1.onload = function () {
      window.Tawk_API.customStyle = {
        visibility: {
          // for desktop only
          desktop: {
            position: 'br', // bottom-right
            xOffset: 15, // 15px away from right
            yOffset: 50 // 40px up from bottom
          },
          // for mobile only
          mobile: {
            position: 'br', // bottom-left
            xOffset: 5, // 5px away from left
            yOffset: 50 // 50px up from bottom
          },
          // change settings of bubble if necessary
          bubble: {
            rotate: '0deg',
            xOffset: -20,
            yOffset: 0
          }
        }
      };
    };

    return () => {
      // Cleanup: Remove the script when component unmounts
      s1.remove();
    };
  }, []);

  return null;
};

export default ChatWidget;
